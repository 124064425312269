import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/betbalr.png'

function SendOTP() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleChange = (event, index) => {
    const value = event.target.value;
    const newOtp = [...otp];

    if (/[0-9]/.test(value) || event.keyCode === 8) {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp__digit_${index + 1}`).focus();
      } else if (event.keyCode === 8 && index > 0) {
        document.getElementById(`otp__digit_${index - 1}`).focus();
      }

      const isOtpComplete = newOtp.every((digit) => digit !== "");
    }
  };
  return (
    <div className="authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-5">
            <div className="card mb-0">
              <div className="card-body p-4">
                <div className="text-center w-75 mx-auto auth-logo mb-4">
                  <a className="logo-dark">
                    <span>
                      <img src={logo} alt="" height={45} />
                    </span>
                  </a>
                  <a className="logo-light">
                    <span>
                      <img src={logo} alt="" height={45} />
                    </span>
                  </a>
                </div>
                <form>
                  <div className="form-group mb-3">
                    <label className="form-label fs-3 ps-6 ">
                      OTP Verification
                    </label>
                    <div>
                      <label
                        className="form-label "
                        style={{ color: "#118a44" }}
                      >
                        An otp has been sent to ********k876@gmail.com
                      </label>
                    </div>
                    <div>
                      <label className="form-label ">
                        Please enter OTP to verify
                      </label>
                    </div>
                  </div>

                  <div className="otp-container my-4">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp__digit_${index}`}
                        type="text"
                        className={`otp__digit otp__digit_${index}`}
                        maxLength="1"
                        value={digit}
                        onChange={(event) => handleChange(event, index)}
                        onKeyUp={(event) => handleChange(event, index)}
                      />
                    ))}
                  </div>

                  <div className="form-group mb-0 text-center w-100 form-button">
                    <Link
                      to="/changepassword"
                      className="text-white"
                    >
                      Confirm
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 text-center">
                <Link className="text-white font-weight-medium ms-1" to="/dashboard">
                  <p className="text-white">Change mind ?</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendOTP;
