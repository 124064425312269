import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import _constantUtil from "../../../utils/Constant";
import config from "../../../config/config";
import { MdCreate } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { BsClipboardCheck } from "react-icons/bs";
import axios from "axios";

function UpdateMatch() {
    const navigate = useNavigate()
    const token = localStorage.getItem("token");
    const storedTheme = sessionStorage.getItem("themeMode");
    const location = useLocation();

    const matchData = location.state;
    const matchID = matchData?.match_ID ?? '';

    const Mstate = matchData?.Mcount ?? 0

    const [profileImage, setProfileImage] = useState("");
    const [profileImage2, setProfileImage2] = useState("");
    const [profileImage3, setProfileImage3] = useState("");
    const [confirmation, setConfirmation] = useState(_constantUtil.FALSE);
    const [matchTitle, setMatchTitle] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [leagueName, setLeagueName] = useState("");
    const [leagueKnownName, setLeagueKnownName] = useState("");
    const [format, setFormat] = useState("");
    const [notifymsg, setNotifymsg] = useState("");
    const [notifyTitle, setNotifyTitle] = useState("");
    const [announcementmsg, setAnnouncementmsg] = useState("");
    const [teamA, setTeamA] = useState("");
    const [teamB, setTeamB] = useState("");
    const [matchId, setMatchId] = useState("");

    const handleImageChange = (event, setter) => {
        const file = event.target.files[_constantUtil.ZERO];
        const reader = new FileReader();
        reader.onload = (event) => {
            setter(event.target.result);
        };
        reader.readAsDataURL(file);
    };

    const viewMatch = async () => {
        try {
            const response = await axios.get(
                `${config.appBaseUrl + _constantUtil.VIEW_MATCH_END_POINT}?match_id=${matchID}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            const result = response.data.data;
            setMatchId(result.match_id);
            setProfileImage(result.contestant[_constantUtil.ZERO].profile);
            setProfileImage2(result.contestant[1].profile);
            setTeamA(result.contestant[_constantUtil.ZERO].short_name);
            setTeamB(result.contestant[1].short_name);
            setMatchTitle(result.short_title);
            setDate(result.local_date);
            setTime(result.local_time);
            setLeagueName(result.competition.name);
            setLeagueKnownName(result.competition.known_name);
            setFormat(result.competition.format);
            setNotifymsg(result.notify_msg);
            setNotifyTitle(result.notify_title);
            setAnnouncementmsg(result.announcement_msg);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const confirmationRef = useRef(_constantUtil.NULL);
    useEffect(() => {
        viewMatch();

        const handleClickOutside = (event) => {
            if (
                confirmationRef.current &&
                !confirmationRef.current.contains(event.target)
            ) {
                setConfirmation(_constantUtil.FALSE);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const updateMatch = async (event) => {
        event.preventDefault();

        const data = new FormData();
        const teama = document.getElementById("upload-match-img");
        const teamb = document.getElementById("upload-match-img-2");

        data.append("teama_profile", teama.files[_constantUtil.ZERO]);
        data.append("teamb_profile", teamb.files[_constantUtil.ZERO]);
        data.append("teama_short_name", teamA);
        data.append("teamb_short_name", teamB);
        data.append("short_title", matchTitle);
        data.append("name", leagueName);
        data.append("known_name", leagueKnownName);
        data.append("format", format);
        data.append("announcement_msg", announcementmsg);
        data.append("notify_title", notifyTitle);
        data.append("notify_msg", notifymsg);
        data.append("match_id", matchId);

        try {
            const response = await axios.post(
                `${config.appBaseUrl + _constantUtil.UPDATE_MATCH_END_POINT}`,
                data,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;
            result.status
                ? toast.success(result.message)
                : toast.error(result.message);
        } catch (error) {
            toast.error("Match not update");
        }
    };

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center" />
            {confirmation && (
                <div>
                    <div className="team-confirmation-overlay"></div>
                    <div
                        ref={confirmationRef}
                        className={`${
                            storedTheme === "dark"
                                ? "match-dark-confirmation-dialog"
                                : "match-light-confirmation-dialog"
                        }`}
                    >
                        <div className="d-flex justify-content-between">
                            <h4>Edit Player Info</h4>
                        </div>

                        <div className=" text-center">
                            <img
                                src={profileImage3}
                                alt="Profile"
                                className="img-thumbnail img-fluid mt-4"
                                style={{
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                    minHeight: "170px",
                                    height: "170px",
                                    maxWidth: "170px",
                                    width: "170px",
                                    backgroundColor: "transparent",
                                }}
                            />
                            <div className="button-wrapper pt-2">
                                <span className="label blr-bg-dull-sky rounded">
                                    <p className="mb-0 px-1">Upload File</p>
                                </span>
                                <div className="d-flex justify-content-center">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleImageChange(
                                                event,
                                                setProfileImage3
                                            )
                                        }
                                        className=" upload-box form-control"
                                        name="upload"
                                        id="upload-modal"
                                        placeholder="Upload File"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label
                                htmlFor="name"
                                className="form-label fw-semibold"
                            >
                                Player Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                style={{ backgroundColor: "transparent" }}
                                id="player_name"
                                name="player_name"
                                placeholder="name"
                                autoComplete="off"
                            />
                        </div>
                        <div className="mb-2">
                            <label
                                htmlFor="type"
                                className="form-label fw-semibold"
                            >
                                Player Type
                            </label>
                            <select
                                className="form-select me-2"
                                style={{ backgroundColor: "transparent" }}
                            >
                                <option value="goalkeeper">Goalkeeper</option>
                                <option value="defender">Defender</option>
                                <option value="midfielder">Midfielder</option>
                                <option value="attacker">Attacker</option>
                            </select>
                        </div>
                        <div className="mb-2">
                            <label
                                htmlFor="credit"
                                className="form-label fw-semibold"
                            >
                                Player Credit
                            </label>
                            <select
                                className="form-control"
                                style={{ backgroundColor: "transparent" }}
                            >
                                <option value="1">1</option>
                                <option value="1.5">1.5</option>
                                <option value="2">2</option>
                                <option value="2.5">2.5</option>
                                <option value="3">3</option>
                                <option value="3.5">3.5</option>
                                <option value="4">4</option>
                                <option value="4.5">4.5</option>
                                <option value="5">5</option>
                                <option value="5.5">5.5</option>
                                <option value="6">6</option>
                                <option value="6.5">6.5</option>
                                <option value="7">7</option>
                                <option value="7.5">7.5</option>
                                <option value="8">8</option>
                                <option value="8.5">8.5</option>
                                <option value="9">9</option>
                                <option value="9.5">9.5</option>
                                <option value="10">10</option>
                                <option value="10.5">10.5</option>
                                <option value="11">11</option>
                            </select>
                        </div>

                        <div className="d-flex justify-content-end mt-3">
                            <button
                                onClick={() => setConfirmation(false)}
                                className="border-0 rounded px-3 bg-danger text-white me-2 p-2"
                            >
                                Cancel
                            </button>
                            <button className="border-0 rounded px-3 bg-success text-white p-2">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="">
                <div className="row justify-content-center mx-4">
                    <div className="d-flex align-items-center justify-content-between p-0">
                        <h4 className="header-title mb-0 text-muted mt-4">
                            <Link
                                onClick={() => navigate(-2)}
                                className="text-muted"
                            >
                                All League
                            </Link>
                            <span className="blr-player-view">
                                <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2 " />
                            </span>{" "}
                            <Link
                                onClick={() => navigate(-1)}
                                className="text-muted"
                            >
                                {Mstate === 1 ? 'Upcoming Match' : 'Launch Match'}
                            </Link>
                            <span className="blr-player-view">
                                <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2 " />
                            </span>{" "}
                            Update Match
                        </h4>
                        <div className="d-flex align-items-center  mt-4">
                            <BsClipboardCheck className="fs-5 me-2 b-1 import-match-icon-checkboard" />
                            <MdOutlineFileDownload className="fs-4 me-2 b-1 import-match-icon" />
                            <button
                                type="button"
                                className="btn text-white d-flex"
                                style={{ float: "right" }}
                                onClick={() => navigate(-1)}
                            >
                                <MdKeyboardArrowLeft className=" fs-4" />
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="card px-4 mt-4">
                        <div className="card-body">
                            <form onSubmit={updateMatch}>
                                <div className="row flex-column align-items-center flex-md-row p-0  mt-2">
                                    <div className="d-flex align-items-center justify-content-around col-12 col-md-6 col-lg-5 mb-1 img-responsive padding-responsive">
                                        <div className="card ">
                                            <div className="card-body d-flex   align-items-center">
                                                <div className=" d-flex flex-column align-items-center order-md-0 mb-md-0">
                                                    <span className="label rounded img-label">
                                                        <p
                                                            className="mb-0 d-flex align-items-center justify-content-center"
                                                            style={{
                                                                background:
                                                                    "#8080804f",
                                                                borderRadius:
                                                                    "50px",
                                                                padding: "4px",
                                                            }}
                                                        >
                                                            <MdCreate />
                                                        </p>
                                                    </span>
                                                    <img
                                                        src={profileImage}
                                                        alt="Profile"
                                                        className="img-thumbnail img-fluid mb-2"
                                                        style={{
                                                            borderRadius: "50%",
                                                            cursor: "pointer",
                                                            objectFit: "cover",
                                                            minHeight: "100px",
                                                            height: "100px",
                                                            maxWidth: "100px",
                                                            width: "100px",
                                                            border: "none",
                                                        }}
                                                    />
                                                    <div className="d-flex justify-content-center">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(event) =>
                                                                handleImageChange(
                                                                    event,
                                                                    setProfileImage
                                                                )
                                                            }
                                                            className="upload-box form-control"
                                                            name="upload file"
                                                            id="upload-match-img"
                                                            placeholder="Upload File"
                                                        />
                                                    </div>
                                                    <h4>{teamA}</h4>
                                                </div>
                                                <div>
                                                    <h3 className="text-muted">
                                                        VS
                                                    </h3>
                                                </div>
                                                <div className="d-flex flex-column align-items-center order-md-0 mb-md-0">
                                                    <span className="label rounded img-label">
                                                        <p
                                                            className="mb-0 d-flex align-items-center justify-content-center"
                                                            style={{
                                                                background:
                                                                    "#8080804f",
                                                                borderRadius:
                                                                    "50px",
                                                                padding: "4px",
                                                            }}
                                                        >
                                                            <MdCreate />
                                                        </p>
                                                    </span>
                                                    <img
                                                        src={profileImage2}
                                                        alt="Profile"
                                                        className="img-thumbnail img-fluid mb-2 "
                                                        style={{
                                                            borderRadius: "50%",
                                                            cursor: "pointer",
                                                            objectFit: "cover",
                                                            minHeight: "100px",
                                                            height: "100px",
                                                            maxWidth: "100px",
                                                            width: "100px",
                                                            border: "none",
                                                        }}
                                                    />
                                                    <div className="d-flex justify-content-center">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(event) =>
                                                                handleImageChange(
                                                                    event,
                                                                    setProfileImage2
                                                                )
                                                            }
                                                            className="upload-box form-control"
                                                            name="upload file"
                                                            id="upload-match-img-2"
                                                            placeholder="Upload File"
                                                        />
                                                    </div>
                                                    <h4>{teamB}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-7 col-md-6 col-12 mb-1  padding-responsive">
                                        <div className="card ">
                                            <div className="card-body">
                                                <div className="row py-1">
                                                    <div className="table-responsive px-0">
                                                        <table className="table m-0 ">
                                                            <tbody className="blr-line-height-table ">
                                                                <tr className="py-1">
                                                                    <td className="fw-bold text-nowra">
                                                                        Match
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={
                                                                                matchTitle
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setMatchTitle(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            className="text-center"
                                                                            style={{
                                                                                border: "none",
                                                                                background:
                                                                                    "transparent",
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold" style={{padding:'11px 0px'}}>
                                                                        Date &
                                                                        Time
                                                                    </td>
                                                                    <td style={{padding:'11px 0px'}}>
                                                                        {date}{" "}
                                                                        {time}
                                                                    </td>
                                                                </tr>
                                                                <tr
                                                                    style={{
                                                                        borderBottom:
                                                                            "hidden",
                                                                    }}
                                                                >
                                                                    <td className="fw-bold">
                                                                        Delay
                                                                        Time
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="me-2 mt-1">
                                                                                <select
                                                                                    className="form-select text-center"
                                                                                    style={{
                                                                                        width: "100%",  
                                                                                    }}
                                                                                >
                                                                                    <option value="0">
                                                                                        0
                                                                                    </option>
                                                                                    <option value="5">
                                                                                        5
                                                                                    </option>
                                                                                    <option value="-5">
                                                                                        -5
                                                                                    </option>
                                                                                    <option value="15">
                                                                                        15
                                                                                    </option>
                                                                                    <option value="-15">
                                                                                        -15
                                                                                    </option>
                                                                                    <option value="30">
                                                                                        30
                                                                                    </option>
                                                                                    <option value="-30">
                                                                                        -30
                                                                                    </option>
                                                                                    <option value="45">
                                                                                        45
                                                                                    </option>
                                                                                    <option value="-45">
                                                                                        -45
                                                                                    </option>
                                                                                    <option value="60">
                                                                                        60
                                                                                    </option>
                                                                                    <option value="-60">
                                                                                        -60
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn text-white"
                                                                                >
                                                                                    Save
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="mb-1 col-12 col-md-12 col-lg-5">
                                        <div className="mt-1">
                                            <label
                                                htmlFor="league_title"
                                                className="form-label"
                                            >
                                                League Title
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="league_title"
                                                name="league_title"
                                                value={leagueName}
                                                onChange={(e) =>
                                                    setLeagueName(
                                                        e.target.value
                                                    )
                                                }
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="notification_message"
                                                className="form-label"
                                            >
                                                Notification Message
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="notification_message"
                                                name="notification_message"
                                                value={notifymsg}
                                                onChange={(e) =>
                                                    setNotifymsg(e.target.value)
                                                }
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-12 mb-3">
                                        <div className="mt-1">
                                            <label
                                                htmlFor="sub_title"
                                                className="form-label"
                                            >
                                                Known Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="sub_title"
                                                name="sub_title"
                                                value={leagueKnownName}
                                                onChange={(e) =>
                                                    setLeagueKnownName(
                                                        e.target.value
                                                    )
                                                }
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="notification_title"
                                                className="form-label"
                                            >
                                                Notification Title
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="notification_title"
                                                name="notification_title"
                                                value={notifyTitle}
                                                onChange={(e) =>
                                                    setNotifyTitle(
                                                        e.target.value
                                                    )
                                                }
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-1">
                                            <label
                                                htmlFor="match_name"
                                                className="form-label"
                                            >
                                                Format
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="format"
                                                name="format"
                                                value={format}
                                                onChange={(e) =>
                                                    setFormat(e.target.value)
                                                }
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="announcement"
                                                className="form-label"
                                            >
                                                Announcement
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="notification_message"
                                                name="notification_message"
                                                value={announcementmsg}
                                                onChange={(e) =>
                                                    setAnnouncementmsg(
                                                        e.target.value
                                                    )
                                                }
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn text-white mb-2"
                                    style={{ float: "right" }}
                                >
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>

                    {/* Tabbing */}
                    {/* <div className="card p-3 border-0 blr-team-cards">
            <nav>
              <div
                className="nav nav-tabs mb-3 border-0 blr-team-cards d-flex "
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active w-50 py-1"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Team A
                </button>
                <button
                  className="nav-link w-50 py-1 blr-team-bg"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Team B
                </button>
              </div>
            </nav>
            <div className="tab-content p-3 border radius-20" id="nav-tabContent">
              <div
                className="tab-pane fade active show"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="table-responsive">
                  <table
                    id="scroll-horizontal-datatable"
                    className="table w-100 nowrap"
                  >
                    <thead>
                      <tr>
                        <th>Player Id</th>
                        <th>Player Name</th>
                        <th>Player Profile</th>
                        <th>Player Position</th>
                        <th>Player Credit</th>
                        <th>Action</th>
                        <th>In Playing 11</th>
                        <th>Unavailable</th>
                        <th>Subtitute</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>jayshil</td>
                        <td>
                          <img src={admin} className="user-img" alt="" />
                        </td>
                        <td>Defender</td>
                        <td>6</td>
                        <td>
                        <MdCreate className=' me-2 fs-3 pe-2' style={{cursor: 'pointer', color:'#b1b12e'}}
                          onClick={()=>{setConfirmation(true)}}/>
                        </td>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="table-responsive">
                  <table id="scroll-horizontal-datatable" className="table w-100 nowrap" >
                    <thead>
                      <tr>
                        <th>Player Id</th>
                        <th>Player Name</th>
                        <th>Player Profile</th>
                        <th>Player Position</th>
                        <th>Player Credit</th>
                        <th>Action</th>
                        <th>In Playing 11</th>
                        <th>Unavailable</th>
                        <th>Subtitute</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>smit</td>
                        <td>
                          <img src={admin} className="user-img" alt="" />
                        </td>
                        <td>Attacker</td>
                        <td>26</td>
                        <td>
                        <MdCreate className=' me-2 fs-3 pe-2' style={{cursor: 'pointer', color:'#b1b12e'}}
                          onClick={()=>{setConfirmation(true)}}/>
                        </td>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
                </div>
            </div>
        </>
    );
}

export default UpdateMatch;
