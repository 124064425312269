import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardArrowRight } from "react-icons/md";
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import _constantUtil from '../../../utils/Constant';
import configFile from '../../../config/config';

function ViewBets() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate()
    const location = useLocation();
    const BetsData = location.state;

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(_constantUtil.ONE);

    useEffect(() => {
        const viewUserBets = async () => {
            const data = {
                "match_id": BetsData?.Betmatchid ?? "",
                "bet_id": BetsData?.betID ?? "",
                "option_id": BetsData?.bet_ID ?? '',
            };

            try {
                const response = await axios.post(
                    `${configFile.appBaseUrl + _constantUtil.PLACEMENT_BET_END_POINT}?page=${page}`, data,
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                );

                const { records, total_page } = response.data.data;
                setData(records);
                setPage(page);
                setTotalPages(total_page);
            } catch (error) {
                toast.error(error.message);
            }
        };
        if (token && BetsData) {
            viewUserBets();
        }
    }, [page, BetsData, token]);

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected + _constantUtil.ONE);
    };

    return (
        <div className="px-4">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="header-title pb-3 text-muted mt-4">
                            <Link onClick={() => navigate(-3)} 
                                className="text-muted">
                                All League
                            </Link>
                            <span className="blr-player-view">
                                <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2" />
                            </span>
                            <Link onClick={() => navigate(-2)} 
                                className="text-muted">
                                Launched Match
                            </Link>
                            <span className="blr-player-view">
                                <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2" />
                            </span>
                            <Link onClick={() => navigate(-1)} className="text-muted">
                                All Bets
                            </Link>
                            <span className="blr-player-view">
                                <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2" />
                            </span>
                            View Bets
                        </h4>
                        <div className="d-flex align-items-center my-4">
                            <button
                                type="button"
                                className="btn text-white d-flex"
                                style={{ float: "right" }}
                                onClick={() => navigate(-1)}
                            >
                                <MdKeyboardArrowLeft className="fs-4" />
                                Back
                            </button>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card w-100 bets-responsive">
                            <div className="card-body pb-0">
                                <div className="ps-2 d-flex justify-content-center">
                                    <div className='mb-3'>
                                        <b>Question :</b> <span>{BetsData?.Betquestion ?? ''}</span>
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-around"
                                    style={{
                                        borderTop: "1px solid var(--bs-border-color)",
                                        borderBottom: "0px",
                                        overflowX: "auto",
                                        whiteSpace: "nowrap"
                                    }}>
                                    <h6
                                        className="w-50 ms-2 mb-0 py-3 text-center"
                                        style={{ borderRight: "1px solid var(--bs-border-color)" }} >
                                        Label: &nbsp;
                                        <span className="badge badge-primary-lighten fs-6 me-2">
                                            {BetsData?.Betlabel ?? ""}
                                        </span>
                                    </h6>
                                    <h6
                                        className="w-50 ms-2 mb-0 py-3 text-center"
                                        style={{ borderRight: "1px solid var(--bs-border-color)" }} >  State: &nbsp;
                                        {(BetsData?.Bettype === 1 ) && <span className="badge badge-primary-lighten">{BetsData?.Betstate ?? ''}</span> }
                                        {(BetsData?.Bettype === 2 ) && <span className="badge badge-success-lighten">{BetsData?.Betstate ?? ''}</span> }
                                        {(BetsData?.Bettype === 3 ) && <span className="badge badge-info-lighten">{BetsData?.Betstate ?? ''}</span> }
                                        {(BetsData?.Bettype === 4 ) && <span className="badge badge-success-lighten">{BetsData?.Betstate ?? ''}</span> }
                                        {(BetsData?.Bettype === 5 ) && <span className="badge badge-secodary-lighten">{BetsData?.Betstate ?? ''}</span> }
                                        {(BetsData?.Bettype === 6 ) && <span className="badge badge-danger-lighten">{BetsData?.Betstate ?? ''}</span> }
                                    </h6>
                                    <h6 className="w-50 ms-2 mb-0 py-3 text-center">
                                        Result: &nbsp;
                                        <span className="badge badge-primary-lighten fs-6 me-2">
                                            {BetsData?.Betresult ?? ""}
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="scroll-horizontal-datatable" className="table w-100">
                                        <thead>
                                            <tr>
                                                <th>SN</th>
                                                <th>Username</th>
                                                <th className='text-nowrap'>Bet Amount</th>
                                                <th>Rate</th>
                                                <th>Payout</th>
                                                <th className='text-nowrap'>Selected Option</th>
                                                <th>Result</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((bets, index) => (
                                                <tr key={index}>
                                                    <td>{((page - _constantUtil.ONE) * 20) + (index + _constantUtil.ONE)}</td>
                                                    <td className='text-nowrap'>{bets.username} <br />( {bets.email} )</td>
                                                    <td>
                                                        <span className="badge badge-info-lighten">{bets.selected_option.bet_amount}</span>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-danger-lighten">{bets.selected_option.rate}</span>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-warning-lighten">{bets.selected_option.payout}</span>
                                                    </td>
                                                    <td>{bets.selected_option.text}</td>
                                                    <td>
                                                        {bets.status === 1 && <span className="badge badge-warning-lighten">{bets.status_str}</span>}
                                                        {bets.status === 2 && <span className="badge badge-success-lighten">{bets.status_str}</span>}
                                                        {bets.status === 3 && <span className="badge badge-danger-lighten">{bets.status_str}</span>}
                                                        {bets.status === 4 && <span className="badge badge-secondary-lighten">{bets.status_str}</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    className='pagination'
                                    onPageChange={handlePageChange}
                                    pageCount={totalPages}
                                    previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                                    nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                                    breakLabel={'...'}
                                    containerClassName={'pagination-container'}
                                    activeClassName={'pagination-active-page'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewBets;
