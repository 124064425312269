import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate'; 
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _constantUtil from '../../utils/Constant';
import config from '../../config/config';
import Loader from '../partials/Loader';
import { MdOutlineDesktopMac } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import { MdOutlineCircle } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Tooltip } from 'react-tooltip';


function AllUser() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  
  const [data, setData] = useState([]);
  const [page, setPage] = useState(_constantUtil.ONE);
  const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
  const [userID, setUserID] = useState();
  const [userStatus, setUserStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);

  const confirmationRef = useRef(_constantUtil.NULL);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.appBaseUrl}${_constantUtil.ALL_USER_END_POINT}?page=${page}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setData(response.data.data.records);
        setTotalPages(response.data.data.total_page);
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [page, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const visibleUser = async () => {
    setLoading(true);
    setDisableButton(true);
    try {
      const response = await axios.patch(
        `${config.appBaseUrl}${_constantUtil.VISIBLE_USER_END_POINT}?user_id=${userID}&status=${userStatus}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const result = response.data;
      if (result.status) {
        const updatedData = data.map(user => {
          if (user._id === userID) {
            return { ...user, status: userStatus };
          }
          return user;
        });
        setData(updatedData);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setDisableButton(false);
    } 
    setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };

  const showConfirmationModel = (id, status) => {
    setUserID(id);
    setUserStatus(!status);
    setShowConfirmation(_constantUtil.BOOLEAN_TRUE);
  };

  return (
    <div className='px-4 mt-4'>
      {showConfirmation && (
        <div>
          <div className='confirmation-overlay'></div>
          <div ref={confirmationRef} className='confirmation-dialog'>
            <p>Are you sure to update user status?</p>
            <div className='d-flex justify-content-end'>
              <button onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)} 
                className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
              <button onClick={() => visibleUser()}  className='border-0 rounded px-3 bg-success text-white p-2' 
                disabled={disableButton}>{!loading ? 'Confirm' : <Loader />}</button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='container-fluid p-0'>
        <div className='row'>
          <h4 className='header-title pb-3 text-muted'>All Users</h4>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table id='scroll-horizontal-datatable' className='table w-100 nowrap'>
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Profile</th>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Balance</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((user, index) => (
                        <tr key={user._id}>
                          <td>{(page - _constantUtil.ONE) * 20 + (index + _constantUtil.ONE)}</td>
                          <td><img src={user.profile} alt="img" className='user-img'/></td>
                          <td>{user.username}</td>
                          <td className="text-nowrap">{user.first_name + ' ' + user.last_name}</td>
                          <td>{user.email}</td>
                          <td>
                          {user.phone ? (
                              <span className='badge badge-success-lighten' style={{fontSize:'13px'}}>{user.phone}</span>
                            ) : (
                              <span className='badge badge-info-lighten' style={{fontSize:'13px'}}>NA</span>
                            )}
                          </td>
                          <td>{user.balance}</td>
                          <td>
                            {user.status ? (
                              <span className='badge badge-success-lighten' style={{fontSize:'13px'}}>ACTIVE</span>
                            ) : (
                              <span className='badge badge-danger-lighten' style={{fontSize:'13px'}}>INACTIVE</span>
                            )}
                          </td>
                          <td>
                            <div className='d-flex align-items-center justify-content-center'>
                              <MdOutlineDesktopMac 
                                data-tooltip-id='viewuser' data-tooltip-content="View User"
                                className='blr-icon mdi mdi-desktop-mac me-2 fs-5 text-secondary'
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/user/view-user?user_id=${user._id}`)}
                              />
                              <Tooltip id='viewuser' place="bottom" effect="solid"/>
                              {user.status ? 
                              <>
                                <MdBlock 
                                  data-tooltip-id='inctive' data-tooltip-content="Inactive User"
                                  className='blr-icon me-2 pointer user-status me-2 fs-5' style={{cursor: 'pointer', color:'red'}}
                                  onClick={() => showConfirmationModel(user._id, user.status)}/> 
                                  <Tooltip id='inctive' place="bottom" effect="solid"/>
                                </>
                                : 
                                <>
                                <MdOutlineCircle 
                                  data-tooltip-id='active' data-tooltip-content="Active User"
                                  className='blr-icon pointer user-status me-2 fs-5' style={{cursor: 'pointer', color:'green'}}
                                  onClick={() => showConfirmationModel(user._id, user.status)}/>
                                  <Tooltip id='active' place="bottom" effect="solid"/>
                                </>
                              }
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>                  
                </div>
                <ReactPaginate
                  className='pagination'
                  onPageChange={page => { setPage(page.selected + _constantUtil.ONE); }}
                  pageCount={totalPages}
                  previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                  nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                  marginPagesDisplayed={2}
                  breakLabel={'...'}
                  containerClassName={'pagination-container'}
                  activeClassName={'pagination-active-page'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllUser;