import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../partials/Loader';
import { useNavigate } from 'react-router-dom';
import _constantUtil from '../../utils/Constant';
import configFile from '../../config/config';
import { MdOutlineFileDownload } from "react-icons/md";
import { MdCreate } from "react-icons/md";
import { MdOutlineDesktopMac } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import { MdOutlineCircle } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Tooltip } from 'react-tooltip';

function AllTeam() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const [data, setData] = useState([]);
  const [page, setPage] = useState(_constantUtil.ONE);
  const [totalPages, setTotalPages] = useState(_constantUtil.ZERO);

  const [teamID, setTeamID] = useState();
  const [teamStatus, setTeamStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);

  const [countryID, setCountryID] = useState();
  const [showcountryconfirmation, setShowcountryconfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  const [allCountry, setCountry] = useState([]);
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [search, setSearch] = useState('');
  const [option, setOption] = useState('name');

  const [loading2, setLoading2] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton2, setDisableButton2] = useState(_constantUtil.BOOLEAN_FALSE);
  
  const fetchCountryData = async () => {
    try {
      // const response = await axios.get(
      //   `${configFile.appBaseUrl + _constantUtil.TEAM_ALL_COUNTRY_END_POINT}`,
      //   {
      //     headers: {
      //       Authorization: token,
      //     },
      //   }
      // );
      setCountry(_constantUtil.COUNTRY_LIST);
    } 
    catch (error) {
      toast.error(error);
    }
  };
  
  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${configFile.appBaseUrl + _constantUtil.ALL_TEAM_END_POINT}?page=${page}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setData(response.data.data.records);
      setPage(page);
      setTotalPages(response.data.data.total_page);
    } 
    catch (error) {
      toast.error(error);
    }
  };  
 
  const searchTeam = async function (value, page) {
    if (value.length > 2) { 
      const response = await axios.get(
        `${configFile.appBaseUrl + _constantUtil.SEARCH_TEAM_END_POINT}?page=${page}&key=${option}&value=${value}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setData(response.data.data.records);
      setPage(page);
      setTotalPages(response.data.data.total_page);
    } else {
      fetchCountryData();
      fetchTeamData();
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 2) {
        searchTeam(search, page);
      } else {
        fetchCountryData();
        fetchTeamData();
      }
    }, 500); 

    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);

  const visibleTeam = async () => {
    setLoading2(true);
    setDisableButton2(true);
    try {
      const config = {
        method: _constantUtil.PATCH_REQUEST,
        url: `${configFile.appBaseUrl}${_constantUtil.VISIBLE_TEAM_END_POINT}?team_id=${teamID}&status=${teamStatus}`,
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.request(config);
      const result = response.data;

      if (result.status) {
        const updatedData = data.map(team => {
          if (team.team_id === teamID) {
            return { ...team, status: teamStatus };
          }
          return team;
        });
        setData(updatedData);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } 
    catch (error) {
      toast.error(error);
    } finally {
      setLoading2(false);
      setDisableButton2(false);
    } 
    setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + _constantUtil.ONE);
  };

  const confirmationRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
        setShowcountryconfirmation(_constantUtil.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function showConfirmationModel(params) {
    const { id, status } = params;
    setTeamID(id);
    setTeamStatus(!status);
    setShowConfirmation(true);
    setLoading(_constantUtil.BOOLEAN_TRUE);
    setDisableButton(_constantUtil.BOOLEAN_TRUE);
  }

  const importTeamData = async () => {
    if (!countryID) {
      toast.error('Please select a country before importing.');
      setShowcountryconfirmation(_constantUtil.BOOLEAN_FALSE)
      return;
    }
    setLoading(true);
    setDisableButton(true);
    try {
      const config = {
        method: 'GET',
        url: `${configFile.appBaseUrl + _constantUtil.IMPORT_TEAM_END_POINT}?country_id=${countryID}`,
        headers: {
          Authorization: token,
        },
      };
      
      const response = await axios.request(config);
      setShowcountryconfirmation(_constantUtil.BOOLEAN_FALSE);
      setLoading(_constantUtil.BOOLEAN_FALSE);
      setDisableButton(_constantUtil.BOOLEAN_FALSE);

      if (response.data.status) {
        fetchTeamData(); 
        fetchCountryData(); 
        toast.success(response.data.message);
      } 
      else toast.error(response.data.message);
    } 
    catch (error) {
      toast.error(error);
      setShowcountryconfirmation(_constantUtil.BOOLEAN_FALSE);
      setLoading(_constantUtil.BOOLEAN_FALSE);
    }
  };

  return (
    <>
      <div className='px-4 mt-4'>
        <ToastContainer autoClose={2000} position='top-center' />

        {showConfirmation && (
          <div>
            <div className='confirmation-overlay'></div>
            <div ref={confirmationRef} className='confirmation-dialog'>
              <p>Are you sure to update team status?</p>
              <div className='d-flex justify-content-end'>
                <button onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                <button onClick={() => visibleTeam()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton2}>{ !loading2 ? 'Confirm' : <Loader />}</button>
              </div>
            </div>
          </div>
        )}
        {showcountryconfirmation && (
          <div>
            <div className='confirmation-overlay'></div>
            <div ref={confirmationRef} className='confirmation-dialog'>
              <p>Are you sure to import team?</p>
              <div className='d-flex justify-content-end'>
                <button onClick={() => setShowcountryconfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                <button onClick={() => importTeamData()} disabled={disableButton} className='border-0 rounded px-3 bg-success text-white p-2'>
                  {!loading ? 'Confirm' : <Loader />}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className='container-fluid p-0'>
          <div className='row'>
            <h4 className='header-title pb-3 text-muted'>All Team</h4>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>

                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className='d-flex'>
                      <select
                        className='form-select search-width me-2'
                        style={{ width: '50%' }}
                        value={option}
                        onChange={(e) => setOption(e.target.value)}
                      >
                        <option value='name'>Name</option>
                        <option value='code'>Code</option>
                      </select>
                      <input
                        type='text'
                        className='form-control  me-2'
                        id='search'
                        name='search'
                        placeholder='Search Team'
                        value={search}
                        onChange={(e) => {
                          setPage(1);
                          setSearch(e.target.value);
                          searchTeam(e.target.value, 1);
                        }}
                        autoComplete='off'
                      />
                    </div>

                    <div className='d-flex'>
                      <div className='me-2'>
                        <select
                          id='inputState'
                          className='form-select'
                          style={{ width: '100%' }}
                          onChange={(e) => setCountryID(e.target.value)}
                        >
                          <option value=''>Select Country</option>
                          {allCountry.map((item, index) => (
                            <option key={index} value={item._id}>{item.country}</option>
                          ))}
                        </select>
                      </div>
                      <div className=''>
                        <button
                          type='submit'
                          className='btn text-white d-flex'
                          onClick={() => setShowcountryconfirmation(true)}>
                          <MdOutlineFileDownload className='fs-4 me-1' />
                          Import
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <table id='scroll-horizontal-datatable' className='table w-100 nowrap' >
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Short Name</th>
                          <th>Code</th>
                          <th>Type</th>
                          <th>Country</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((team, index) => (
                          <tr key={index}>
                            <th scope='row'>{((page - 1) * 20) + (index + 1)}</th>
                            <td ><img src={team.profile} alt='img' className='user-img'/> </td>
                            <td>{team.name}</td>
                            <td>
                            {team.short_name ? (
                                <span className='badge badge-info-lighten fs-6'>
                                  { team.short_name}
                                </span>
                                ) : (
                                <span className='badge badge-secondary-lighten fs-6'>
                                  NA
                                </span>
                              )}
                            </td>
                            <td>{team.code}</td>
                            <td>{team.type}</td>
                            <td>{team.country}</td>
                            <td>
                              {team.status ? (
                                <span className='badge badge-success-lighten ' style={{fontSize:'13px'}}>
                                   ACTIVE
                                </span>
                              ) : (
                                <span className='badge badge-danger-lighten ' style={{fontSize:'13px'}}>
                                  INACTIVE
                                </span>
                              )}
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-center'>
                                <MdCreate 
                                  data-tooltip-id='updateteam' data-tooltip-content="Update Team"
                                  className='blr-icon  me-2 fs-5' style={{cursor: 'pointer', color:'gray'}}
                                  onClick={() => navigate(`/team/edit-team`,{state:{team_ID : team.team_id}})}
                                />
                                <Tooltip id='updateteam' place="bottom" effect="solid"/>
                                <MdOutlineDesktopMac
                                  data-tooltip-id='viewteam' data-tooltip-content="View Team"
                                  className='blr-icon  me-2 fs-5'
                                  style={{ cursor: 'pointer', color:'gray' }}
                                  onClick={() => navigate(`/team/view-team`,{state:{team_ID: team.team_id}})}
                                />
                                <Tooltip id='viewteam' place="bottom" effect="solid"/>
                                {team.status ? 
                                <>
                                  <MdBlock
                                    data-tooltip-id='inctive' data-tooltip-content="Inactive Team"
                                    className='blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'red'}}   
                                    onClick={() => showConfirmationModel({ id: team.team_id, status: team.status })}/>
                                    <Tooltip id='inctive' place="bottom" effect="solid"/>
                                </>
                                : 
                                <>
                                  <MdOutlineCircle
                                    data-tooltip-id='active' data-tooltip-content="Active Team"
                                    className='blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'green'}}   
                                    onClick={() => showConfirmationModel({ id: team.team_id, status: team.status })}/>
                                    <Tooltip id='active' place="bottom" effect="solid"/>
                                </>
                                }
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>    
                  </div>
                  <ReactPaginate
                      className='pagination'
                      onPageChange={handlePageChange}
                      pageCount={totalPages}
                      previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                      nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      breakLabel={'..'}
                      containerClassName={'pagination-container'}
                      activeClassName={'pagination-active-page'}
                    /> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTeam;
