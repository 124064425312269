import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/betbalr.png'

function ChangePassword() {
  return (
    <div className="authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 mx-auto auth-logo mb-4">
                  <a className="logo-dark"  >
                    <span>
                    <img
                      src={logo}
                      alt=""
                      height={45}
                    />
                    </span>
                  </a>
                  <a className="logo-light" >
                    <span>
                    <img
                      src={logo}
                      alt=""
                      height={45}
                    />
                    </span>
                    {/* <h3 className="text-muted form-label mt-2">Change Password</h3> */}
                  </a>
                </div>
                <form >
                  <div className="form-group mb-3">
                    <label className="form-label" htmlFor="emailaddress">
                      Old New Password
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="old-password"
                      required=""
                      placeholder="Old new password"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label" htmlFor="emailaddress">
                      Enter New Password
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="password"
                      required=""
                      placeholder="Enter new password"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <a
                      className="text-muted float-end"
                      href="pages-recoverpw.html"
                    >
                      <small />
                    </a>
                    <label className="form-label" htmlFor="password">
                      Confirm New Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      required=""
                      id="changer-password"
                      placeholder="Confirm new password"
                    />
                  </div>
                  <div className="form-group mb-0 text-center w-100 form-button ">
                    <Link to="/dashboard" className="text-white" >
                    Change Account Password  </Link>
                  </div>
                </form>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
