import React from 'react';
import { Route, Routes } from "react-router-dom";
import AllPlayer from '../player/AllPlayer';
import ViewPlayer from '../player/ViewPlayer';
import UpdatePlayer from '../player/UpdatePlayer';

function PlayerNestRoutes() {
  return (
    <Routes>
      <Route path="/all-player" element={<AllPlayer />} />
      <Route path="/view-player" element={<ViewPlayer />} />
      <Route path="/edit-player" element={<UpdatePlayer />} />
    </Routes>
  );
}

export default PlayerNestRoutes;
