import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdCreate,
    MdBlock,
    MdOutlineFileDownload,
    MdKeyboardDoubleArrowRight,
    MdLockReset,
    MdOutlineCircle,
} from "react-icons/md";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _constantUtil from "../../../utils/Constant";
import config from "../../../config/config";
import Loader from "../../partials/Loader";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "react-tooltip";
import logo from "../../../assets/images/logo.png";


function UpcomingMatch() {
    const token = localStorage.getItem("token");
    const location = useLocation();
    const ID = location.state;
    const cid = ID?.leagueID || "";
    const isLaunch = ID?.isLaunch ?? _constantUtil.BOOLEAN_FALSE;
    const storedTheme = sessionStorage.getItem("themeMode");
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
    const [showLeague, setShowLeague] = useState(_constantUtil.BOOLEAN_FALSE);
    const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
    const [disableButton, setDisableButton] = useState(
        _constantUtil.BOOLEAN_FALSE
    );
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment().add(1, "days").toDate());
    const [searchTerm, setSearchTerm] = useState("");
    const [showLaunchMatch, setShowLaunchMatch] = useState(
        _constantUtil.BOOLEAN_FALSE
    );
    const [showispin, setShowispin] = useState(_constantUtil.BOOLEAN_FALSE);
    const [isPin, setIsPin] = useState(_constantUtil.BOOLEAN_FALSE);
    const [isStatus, setIsStatus] = useState(_constantUtil.BOOLEAN_FALSE);
    const [showMatch, setShowMatch] = useState(_constantUtil.BOOLEAN_FALSE);
    const [matchID, setMatchID] = useState("");
    const [mID, setMID] = useState("");
    const [isiD, setIsiD] = useState("");
    const [currentPage, setCurrentPage] = useState(_constantUtil.ZERO);
    const rowsPerPage = 20;

    const handleStartDateChange = (date) => {
        setStartDate(date);
        const oneDayFromStartDate = moment(date).add(1, "days").toDate();
        setEndDate(oneDayFromStartDate);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            e.preventDefault();
        }
    };

    const confirmationRef = useRef(_constantUtil.NULL);
    const allMatch = async () => {
        try {
            const response = await axios.post(
                `${config.appBaseUrl + _constantUtil.UPCOMING_MATCH_END_POINT}`,
                {
                    competition_id: cid,
                    is_launch: isLaunch,
                    status: 1,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const records = response.data.data;
            setData(records);
            setMatchID(records.match_id);
            setTotalPages(Math.ceil(records.length / rowsPerPage));
        } catch (error) {
            toast.error(error);
        }
    };

    useEffect(() => {
        allMatch();

        const handleClickOutside = (event) => {
            if (
                confirmationRef.current &&
                !confirmationRef.current.contains(event.target)
            ) {
                setShowLeague(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [currentPage, rowsPerPage]);

    const importMatch = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);

        const formattedStartDate = startDate.toISOString().slice(0, 19) + "Z";
        const formattedEndDate = endDate.toISOString().slice(0, 19) + "Z";

        try {
            const response = await axios.get(
                `${
                    config.appBaseUrl + _constantUtil.IMPORT_MATCH_END_POINT
                }?competition_id=${cid}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;
            if (result.status) {
                toast.success(result.message);
                allMatch();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowLeague(_constantUtil.BOOLEAN_FALSE);
    };

    const launchMatch = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);
        try {
            const response = await axios.patch(
                `${config.appBaseUrl + _constantUtil.LAUNCH_MATCH_END_POINT}?match_id=${matchID}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;
            if (result.status) {
                toast.success(result.message);
                allMatch();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowLaunchMatch(_constantUtil.BOOLEAN_FALSE);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const filteredData = data.filter((match) =>
        match.short_title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const visibleLock = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);
        try {
            const response = await axios.patch(
                `${
                    config.appBaseUrl + _constantUtil.AVAILABLE_MATCH_END_POINT
                }?match_id=${mID}&status=${isPin}`,
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;

            if (result.status) {
                const updatedData = data.map((match) => {
                    if (match.match_id === matchID) {
                        return { ...match, status: isPin };
                    }
                    return match;
                });
                setData(updatedData);
                toast.success(result.message);
                allMatch();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowispin(_constantUtil.BOOLEAN_FALSE);
    };

    const visibleMatch = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);
        try {
            const response = await axios.patch(
                `${
                    config.appBaseUrl + _constantUtil.VISIBLE_MATCH_END_POINT
                }?match_id=${isiD}&status=${isStatus}`,
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;

            if (result.status) {
                const updatedData = data.map((visiblematch) => {
                    if (visiblematch.match_id === matchID) {
                        return { ...visiblematch, status: isStatus };
                    }
                    return visiblematch;
                });
                setData(updatedData);
                toast.success(result.message);
                allMatch();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowMatch(_constantUtil.BOOLEAN_FALSE);
    };

    const showConfirmationModel = (params) => {
        const { id, status } = params;
        setMID(id);
        setIsPin(!status);
        setShowispin(_constantUtil.BOOLEAN_TRUE);
    };

    const showMatchConfirmation = (params) => {
        const { id, status } = params;
        setIsiD(id);
        setIsStatus(!status);
        setShowMatch(_constantUtil.BOOLEAN_TRUE);
    };

 
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center" />
            {showLaunchMatch && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to launch this match ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() => {
                                    setShowLaunchMatch(false);
                                    setMatchID("");
                                }}
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => launchMatch()}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showispin && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to update match status ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() => {
                                    setShowispin(false);
                                    setMatchID("");
                                }}
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => visibleLock()}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showMatch && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to update match status ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() => {
                                    setShowMatch(false);
                                    setMatchID("");
                                }}
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => visibleMatch()}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showLeague && (
                <div>
                    <div className="team-confirmation-overlay"></div>
                    <div
                        ref={confirmationRef}
                        className={`${
                            storedTheme === "dark"
                                ? "player-confirmation-dialog"
                                : "team-confirmation-dialog w-50"
                        }`}
                    >
                        <div className="text-center">
                            <h4>Import Match</h4>
                        </div>
                        <hr />
                        <div className="row mt-4">
                            <div className="d-flex justify-content-center">
                                <span>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        minDate={new Date()}
                                        onKeyDown={handleKeyDown}
                                        dateFormat="yyyy/MM/dd"
                                        className="form-control bg-transparent text-center"
                                        showDisabledMonthNavigation
                                    />
                                </span>
                                <span>
                                    <label className="ps-3 pe-2 mt-2">
                                        to &nbsp;
                                    </label>
                                </span>
                                <span>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        minDate={moment(startDate)
                                            .add(1, "days")
                                            .toDate()}
                                        onKeyDown={handleKeyDown}
                                        dateFormat="yyyy/MM/dd"
                                        className="form-control bg-transparent text-center"
                                        showDisabledMonthNavigation
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <button
                                onClick={() => setShowLeague(false)}
                                className="border-0 rounded px-3 bg-danger text-white me-2 p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={importMatch}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {" "}
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="px-4 ">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between ">
                            <h4 className="header-title pb-3 text-muted mt-4">
                                <Link
                                    onClick={() => navigate(-1)}
                                    className="text-muted"
                                >
                                    All League
                                </Link>
                                <span className="blr-player-view">
                                    <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2 " />
                                </span>{" "}
                                Launched Match
                            </h4>
                            <div className="d-flex align-items-center  my-4">
                                {!isLaunch && (
                                    <>
                                        <MdOutlineFileDownload
                                            data-tooltip-id="filedownload"
                                            data-tooltip-content="Import Match"
                                            className="fs-4 me-2 b-1 blr-icon import-match-icon"
                                            onClick={() => setShowLeague(_constantUtil.BOOLEAN_TRUE)}
                                        />
                                        <Tooltip
                                            border={false}
                                            id="filedownload"
                                            place="bottom"
                                            effect="solid"
                                            style={{ zIndex: "1000000" }}
                                        />
                                    </>
                                )}

                                <button
                                    type="button"
                                    className="btn text-white d-flex"
                                    style={{ float: "right" }}
                                    onClick={() => navigate(-1)}
                                >
                                    <MdKeyboardArrowLeft className=" fs-4" />
                                    Back
                                </button>
                            </div>
                        </div>
                        <div className="col-12  ">
                            <div className="card">
                                <div className="card-body">
                                    <input
                                        type="text"
                                        className="form-control search-width me-2 mb-2"
                                        id="search"
                                        name="search"
                                        placeholder="Search Match"
                                        style={{ width: "15%" }}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        autoComplete="off"
                                    />
                                    <div className="table-responsive">
                                        <table id="scroll-horizontal-datatable" className="table w-100 nowrap" >
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Match Title</th>
                                                    <th className="text-nowrap">League Name</th>
                                                    <th>Country</th>
                                                    <th className="text-nowrap">Date & Time</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map(
                                                    (match, index) => (
                                                        <tr key={match._id}>
                                                            <th scope="row">{currentPage * rowsPerPage + (index + 1)}</th>
                                                            <td className="text-nowrap"> {match.short_title} <br />( {match.description} )</td>
                                                            <td className="text-nowrap">{match.competition.known_name}<br /> ( {match.competition.code} )</td>
                                                            <td>{match.competition.country.name}</td>
                                                            <td className="text-nowrap">{match.local_date}<br />{match.local_time}</td>
                                                            <td>
                                                                {match.status ? (
                                                                    <span className="badge badge-success-lighten">
                                                                        ACTIVE
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-danger-lighten">
                                                                        INACTIVE
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                
                                                                    {match.is_pin && (
                                                                        <>
                                                                            <img src={logo}
                                                                                alt="logo"
                                                                                className="p-1"
                                                                                data-tooltip-id="betsquestion"
                                                                                data-tooltip-content="Bets"
                                                                                style={{width: "35px",height: "35px",cursor: "pointer",}}
                                                                                onClick={() =>
                                                                                    navigate(`/match/launch-bets`,
                                                                                        {
                                                                                            state: {
                                                                                                match_ID:match.match_id,
                                                                                                isLaunch,
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Tooltip border={false}id="betsquestion"place="bottom"effect="solid"
                                                                            />
                                                                        </>
                                                                    )}

                                                                    {!isLaunch &&
                                                                        (match.is_pin ? (
                                                                            <>
                                                                                <MdLockReset
                                                                                    data-tooltip-id="bell"
                                                                                    data-tooltip-content="Unavailable Match"
                                                                                    className="blr-icon fs-4 me-2"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "green",
                                                                                    }}
                                                                                    onClick={() =>showConfirmationModel(
                                                                                            {
                                                                                                id: match.match_id,
                                                                                                status: match.is_pin,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <Tooltip border={false} id="bell" place="bottom" effect="solid" />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <MdLockReset
                                                                                    data-tooltip-id="launch"
                                                                                    data-tooltip-content="Available Match"
                                                                                    className="blr-icon fs-4 me-2 blr-text-dull-sky"
                                                                                    style={{ cursor: "pointer", color: "gray", }}
                                                                                    onClick={() => showConfirmationModel(
                                                                                            {
                                                                                                id: match.match_id,
                                                                                                status: match.is_pin,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <Tooltip border={ false } id="launch" place="bottom" effect="solid" />
                                                                            </>
                                                                        ))}

                                                                    {/* {!isLaunch && ( */}
                                                                        <>
                                                                            <MdCreate
                                                                                data-tooltip-id="updatematch"
                                                                                data-tooltip-content="Update Match"
                                                                                className="blr-icon fs-5 me-2"
                                                                                style={{ cursor: "pointer", color: "gray",  }}
                                                                                onClick={() => navigate( `/match/update-match`,
                                                                                        {
                                                                                            state: {
                                                                                                match_ID:match.match_id,
                                                                                                Mcount: 2
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Tooltip border={ false } id="updatematch" place="bottom" effect="solid" />
                                                                        </>
                                                                     {/* )} */}

                                                                    {match.status ? (
                                                                        <>
                                                                            <MdBlock
                                                                                data-tooltip-id="inactive"
                                                                                data-tooltip-content="Inactive Match"
                                                                                className="blr-icon fs-5 pointer user-status me-2"
                                                                                style={{ cursor: "pointer", color: "red", }}
                                                                                onClick={() => showMatchConfirmation(
                                                                                        {
                                                                                            id: match.match_id,
                                                                                            status: match.status,
                                                                                        }
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Tooltip border={ false } id="inactive" place="bottom" effect="solid" />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <MdOutlineCircle
                                                                                data-tooltip-id="active-match"
                                                                                data-tooltip-content="Active Match"
                                                                                className="blr-icon pointer user-status fs-5 me-2"
                                                                                style={{ cursor: "pointer", color: "green", }}
                                                                                onClick={() => showMatchConfirmation(
                                                                                        {
                                                                                            id: match.match_id,
                                                                                            status: match.status,
                                                                                        }
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Tooltip id="active-match" place="bottom"  effect="solid" />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        previousLabel={<MdKeyboardArrowLeft className="fs-4" /> }
                                        nextLabel={ <MdKeyboardArrowRight className="fs-4" /> }
                                        className="pagination"
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination"}
                                        subContainerClassName={ "pages pagination" }
                                        activeClassName={"pagination-active-page" }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpcomingMatch;
