import React, { useEffect, useRef, useState } from 'react';
import admin_img from '../../assets/images/admin img.png';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/betbalr.png'
import small_logo from '../../assets/images/betbalr - Copy.png'
import _constantUtils from '../../utils/Constant'
import { MdDehaze } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { MdLightMode } from "react-icons/md";



function Navbar({buttonRef}) {

  const [themeMode, setThemeMode] = useState('light');
  const [showConfirmation, setShowConfirmation] = useState(_constantUtils.BOOLEAN_FALSE);
  const navigate = useNavigate()


  useEffect(() => {
    const handleThemeToggle = () => {
      const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
      setThemeMode(newThemeMode);
      sessionStorage.setItem('themeMode', newThemeMode);
    };

    const storedThemeMode = sessionStorage.getItem('themeMode');

    if (storedThemeMode) {
      setThemeMode(storedThemeMode);
    } else {
      setThemeMode('light');
    }

    const themeToggleElement = document.getElementById('theme-mode');
    if (themeToggleElement) {
      themeToggleElement.addEventListener('click', handleThemeToggle);
    }

    return () => {
      if (themeToggleElement) {
        themeToggleElement.removeEventListener('click', handleThemeToggle);
      }
    };
  }, [themeMode]);

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', themeMode);
  }, [themeMode]); 
  
  const [menuOpen, setMenuOpen] = useState(_constantUtils.BOOLEAN_FALSE);
  const dropdownRef1 = useRef(_constantUtils.NULL);

  const toggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 
  const confirmationRef = useRef(_constantUtils.NULL);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtils.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <>
      {showConfirmation && (
        <div>
          <div className='confirmation-overlay'></div>
          <div ref={confirmationRef} className='confirmation-dialog'>
            <p>Are you sure to logout from portal ?</p>
            <div className='d-flex justify-content-end'>
              <button onClick={() => setShowConfirmation(false)} className='rounded bg-danger px-3 p-2 text-white border-0'>Cancel</button>
              <button onClick={() => navigate('/logout')} className='rounded bg-success px-3 p-2 text-white border-0'>Confirm</button>
            </div>
          </div>
        </div>
      )}
      <div className='navbar-custom'>
        <div className='topbar'>
          <div className='topbar-menu d-flex align-items-center gap-lg-2 gap-1'>
            <div className='logo-box'>
              <a className='logo-light' >
                <img
                  src={logo}
                  alt='logo'
                  className='logo-lg'
                  height={45}
                />
                <img
                  src={small_logo}
                  alt='small logo'
                  className='logo-sm'
                  height={45}
                />
              </a>
              <a className='logo-dark' >
                <img
                  src={logo}
                  alt='dark logo'
                  className='logo-lg'
                  height={45}
                />
                <img
                src={small_logo}
                  alt='small logo'
                  className='logo-sm'
                  height={45}
                />
              </a>
            </div>
            <button className='button-toggle-menu' ref={buttonRef}>
              <MdDehaze />
            </button>
          </div>
          <ul className='topbar-menu d-flex align-items-center gap-4'>
            <li className='nav-link' id='theme-mode'>
              {/* <i className={`bx ${ themeMode === 'light' ? 'bx-moon' : 'bx-sun' } font-size-24`} /> */}
              {themeMode === 'light' ? <MdDarkMode /> : <MdLightMode /> }
            </li>
            <li className='dropdown' ref={dropdownRef1}>
              <a className='nav-link dropdown-toggle nav-user me-0 waves-effect waves-light'
                role='button'
                aria-haspopup='false'
                aria-expanded={menuOpen}
                onClick={toggleDropdown} >
                <img
                  src={admin_img}
                  alt='user-image'
                  className='rounded-circle'
                />
                <span className='ms-1 d-none d-md-inline-block'>
                  Admin 
                </span>
              </a>
              <div className={`dropdown-menu dropdown-menu-end profile-dropdown ${ menuOpen ? 'show' : '' }`}>
                <Link to='/sendemail' className='dropdown-item notify-item'  style={{cursor:'pointer'}}>
                  <i className='fe-log-out' />
                  <span>Password</span>
                </Link>
                <a onClick={()=>{setShowConfirmation(true)}} className='dropdown-item notify-item'  style={{cursor:'pointer'}}>
                  <i className='fe-log-out' />
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
