import { useState, useRef, useMemo, useEffect } from 'react';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import config from '../../config/config';
import _constantUtil from '../../utils/Constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';

function UpdateNews() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  const location = useLocation();
  const matchData = location.state;

  let matchID = matchData?.UpdatematchId ?? '';
  let ID = matchData?.Updateid ?? '';

  const [profileImage, setProfileImage] = useState( matchData?.Updateprofile ?? '' );
  const [text, setText] = useState(matchData?.Updatemessage ?? '');
  const [wordCount, setWordCount] = useState(_constantUtil.ZERO);
  const [videoError, setVideoError] = useState('');
  const [videoURL, setVideoURL] = useState(matchData?.Updateprofile ?? '');
  const editor = useRef(_constantUtil.NULL);
  const [content, setContent] = useState(matchData?.Updatedescription ?? '');
  const [uploadType, setUploadType] = useState( matchData?.UpdateuploadType ?? _constantUtil.ZERO );
  const [title, setTitle] = useState(matchData?.Updatetitle ?? '');
  

  const editorConfig = useMemo(
    () => ({
      readonly: _constantUtil.BOOLEAN_FALSE,
    }),
    []
  );

  useEffect(() => {
    const initialWords = text.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(initialWords.length);
  }, [text]);

  const handleChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    if (words.length <= 120) {
      setText(inputText);
      setWordCount(words.length);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerChange = (type) => {
    setUploadType((prevType) => (prevType === type ? 0 : type));
    setProfileImage('');
    setVideoError('');
    setVideoURL('');
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 50 * 1024 * 1024; // 50MB max size

    if (file && file.type.startsWith('video/')) {
      if (file.size <= maxSize) {
        setVideoURL(URL.createObjectURL(file));
        setVideoError('');
      } else {
        setVideoError('File size exceeds the maximum limit of 50MB.');
        setVideoURL('');
      }
    } else {
      setVideoError('Invalid file type. Please upload a video file.');
      setVideoURL('');
    }
  };

  const updateNews = async (event) => {
    event.preventDefault();

    const data = new FormData();
    const newsImage = document.getElementById('news-upload');
    const newsVideo = document.getElementById('video-upload');

    switch (uploadType) {
      case 0: 
          data.append('upload_type', 0 ); 
          data.append('is_remove', true);  
        break;
      case 1: 
          data.append('news_profile', newsImage.files[_constantUtil.ZERO]); 
          data.append('upload_type', uploadType);  
          data.append('is_remove', false); 
        break;
      case 2: 
          data.append('news_profile', newsVideo.files[_constantUtil.ZERO]); 
          data.append('upload_type', uploadType); 
          data.append('is_remove', false);  
        break;
      default:
        break;
    }

    if(profileImage){
      data.append('upload_type', 0 ); 
      data.append('is_remove', false);
    }
    
    data.append('title', title);
    data.append('message', text);
    data.append('description', content);
    data.append('match_id', matchID);
    data.append('_id', ID);

    try {
      const response = await axios.patch(
        `${config.appBaseUrl + _constantUtil.UPDATE_NEWS_DETAILS_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data;
      result.status
        ? toast.success(result.message)
        : toast.error(result.message);
    } catch (error) {
      toast.error('News not update');
    }
  };

  return (
    <>
      <div className=''>
        <ToastContainer autoClose={2000} position='top-center' />
        <div className='row mx-4'>
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className='header-title mb-0 text-muted mt-4'>
              <Link onClick={() => navigate(-1)} className='text-muted'>
               All News
              </Link>
              <span className='blr-player-view'>
                <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 ' />
              </span>{' '}
              Update News
            </h4>
            <button
              type='button'
              className='btn text-white float-right mt-4 d-flex'
              style={{ float: 'right' }}
              onClick={() => navigate(-1)}
            >
              <MdKeyboardArrowLeft className='fs-4' />
              Back
            </button>
          </div>

          <div className='card mt-4'>
            <div className='card-body'>
              <form onSubmit={updateNews}>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-3'>
                      <label htmlFor='tags' className='form-label'>
                        Match Name :
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='tags'
                        name='tags'
                        placeholder={matchData?.UpdatematchName ?? ''}
                        disabled
                        autoComplete='off'
                      />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor='title' className='form-label'>
                        Title :
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='title'
                        name='title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        autoComplete='off'
                      />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor='description' className='form-label'>
                        Message :
                      </label>
                      <textarea
                        value={text}
                        onChange={handleChange}
                        style={{ background: "transparent",maxHeight:'320px', overflowY:'scroll', scrollbarWidth:'none' }}
                        rows='3'
                        className='form-control'
                      />
                      <span className='float-end mt-1'>
                        ( {wordCount} / 120 words )
                      </span>
                    </div>

                    <div className='mb-3 pt-2'>
                      <div className='flex-column'>
                        <div className='d-flex'>
                          <label className='form-label'>Upload Media :</label>
                          <div className='form-check mb-2 blr-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id='imageCheckbox'
                              checked={uploadType === 1}
                              onChange={() => handleBannerChange(1)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='imageCheckbox'
                            >
                              Image
                            </label>
                          </div>
                          <div className='form-check mb-2 blr-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id='videoCheckbox'
                              checked={uploadType === 2}
                              onChange={() => handleBannerChange(2)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='videoCheckbox'
                            >
                              Video
                            </label>
                          </div>
                        </div>
                        
                        <div className='pt-2'>
                          {uploadType === 1 && (
                            <>
                              <div className='mb-3'>
                                <input
                                  type='file'
                                  accept='image/jpeg, image/png'
                                  onChange={handleImageChange}
                                  className='form-control'
                                  name='upload'
                                  id='news-upload'
                                />
                              </div>
                              {profileImage && (
                                <img
                                  src={profileImage}
                                  alt='Profile'
                                  className='img-thumbnail img-fluid my-2 me-2 news-image'
                                  style={{
                                    cursor: 'pointer',
                                    objectFit: 'cover',
                                    minHeight: '200px',
                                    height: '200px',
                                  }}
                                />
                              )}
                            </>
                          )}
                          {uploadType === 2 && (
                            <>
                              <div className='mb-3'>
                                <input
                                  type='file'
                                  accept='video/*'
                                  onChange={handleVideoChange}
                                  className='form-control'
                                  name='videoUpload'
                                  id='video-upload'
                                />
                                {videoError && (
                                  <div className='text-danger'>
                                    {videoError}
                                  </div>
                                )}
                              </div>
                              {videoURL && (
                                <div className='mt-3'>
                                  <video className='news-image' controls>
                                    <source src={videoURL} type='video/mp4' />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-3'>
                      <label htmlFor='article' className='form-label'>
                        Description :
                      </label>
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={editorConfig}
                        tabIndex={1}
                        onBlur={(newContent) => setContent(newContent)}
                        onChange={(newContent) => {}}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary float-end text-white'
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateNews;
