import { Route, Routes } from 'react-router-dom';
import Appmain from './components/partials/AppMain';
import Login from '../src/authentication/Login';
import ForgotPassword from './authentication/ForgotPassword';
import EmailVerification from './authentication/EmailVerification';
import NewPassword from './authentication/NewPassword';
import SendEmail from './authentication/SendEmail';
import SendOTP from './authentication/SendOTP';
import ChangePassword from './authentication/ChangePassword';
import Logout from './authentication/Logout';
import PublicRoute from './authentication/PublicRoutes';
import PrivateRoute from './authentication/PrivatRoutes';
import OTPVerification from './authentication/OTPVerification';
 
function App() {
  return (
    <Routes>
      <Route path='/' element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      } />

      <Route path='/emailverification' element={
        <PublicRoute>
          <EmailVerification />
        </PublicRoute>
      } />
      
      <Route path='/logout' element={<Logout />} />
      <Route path='/forgotPassword' element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      } />

      <Route path='/otp' element={
        <PublicRoute>
          <OTPVerification />
        </PublicRoute>
      } />

      <Route path='/newpassword' element={
        <PublicRoute>
          <NewPassword />
        </PublicRoute>
      } />

      <Route path='/sendemail' element={<SendEmail /> } />
      <Route path='/sendotp' element={<SendOTP /> } />
      <Route path='/changepassword' element={<ChangePassword /> } />

      <Route path='/dashboard/*' element={
        <PrivateRoute>
          <Appmain /> 
        </PrivateRoute>
      } />
      
      <Route path='/user/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } />

      <Route path='/team/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } />

      <Route path='/player/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } />

      <Route path='/tshirt/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } /> 

      <Route path='/match/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } /> 
      
      <Route path='/bets/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } />
      <Route path='/news/*' element={
        <PrivateRoute>
          <Appmain />
        </PrivateRoute>
      } />
    </Routes>
  );
}

export default App;