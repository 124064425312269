import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/betbalr.png'

function SendEmail() {
  return (
    <div className="authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 mx-auto auth-logo mb-4">
                  <a className="logo-dark" >
                    <span>
                      <img
                        src={logo}
                        alt=""
                        height={45}
                      />
                    </span>
                  </a>
                  <a className="logo-light" >
                    <span>
                    <img
                        src={logo}
                        alt=""
                        height={45}
                      />
                    </span>
                  </a>
                  <h3 className="text-muted form-label mt-2">Send Email</h3>
                </div>
                <form >
                  <div className="form-group mb-3">
                    <label className="form-label" htmlFor="emailaddress">
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="emailaddress"
                      required=""
                      placeholder="Enter your email"
                    />
                  </div>
                    <div className="form-group mb-0 text-center w-100 form-button ">
                    <Link to="/sendotp" className="text-white mt-2" >
                        Send OTP  
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendEmail;
