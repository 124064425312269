import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/betbalr.png'
import small_logo from '../../assets/images/betbalr - Copy.png'
import { MdDashboard } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";
import { MdAccessibility } from "react-icons/md";
import { FaTshirt } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBookmarksFill  } from "react-icons/bs";
import { IoIosTrophy  } from "react-icons/io";
import { HiNewspaper } from "react-icons/hi2";


function Sidebar({ isSidebarVisible }) {
  const navigate = useNavigate()
  const location = useLocation();
  const [openMenuId, setOpenMenuId] = useState('');

  const menuItems = [
    {
      id: 'dashboards',
      tab: 'dashboard',
      iconClass: <MdDashboard />,
      text: 'Dashboard',
      submenuItems: [
        { text: 'Dashboard', link: '/dashboard', tab: '/dashboard' },
      ]
    },
    {
      id: 'user-manager',
      tab: 'user',
      iconClass: <MdPerson />,
      text: 'User Manager',
      submenuItems: [
        { text: 'All User', link: '/user/all-user', tab: '/user' },
      ],
    },
    {
      id: 'team-manager',
      tab: 'team',
      iconClass: <MdPeopleAlt />,
      text: 'Team Manager',
      submenuItems: [
        { text: 'All Team', link: '/team/all-team', tab: '/team' },
      ],
    },
    {
      id: 'player-manager',
      tab: 'player',
      iconClass: <MdAccessibility />,
      text: 'Player Manager',
      submenuItems: [
        { text: 'All Player', link: '/player/all-player', tab: '/player' },
      ],
    },
    {
      id: 't-shirt-manager',
      tab: 'tshirt',
      iconClass: <FaTshirt/>,
      text: 'Tshirt Manager',
      submenuItems: [
        { text: 'All Tshirt', link: '/tshirt/all-tshirt', tab: '/tshirt' },
      ],
    },
    {
      id: 'match-manager',
      tab: 'match',
      iconClass: <BsBookmarksFill  />,
      text: 'Match Manager',
      submenuItems: [
        { text: 'All League', link: '/match/all-league', tab: '/match' },
      ],
    },
    {
      id: 'bet-manager',
      tab: 'bet',
      iconClass: <IoIosTrophy  />,
      text: 'Bet Manager',
      submenuItems: [
        { text: 'All Category', link: '/bets/all-category', tab: '/bets' },
        { text: 'Global Bets', link: '/bets/global-bets', tab: '/bets' },
      ],
    },
    {
      id: 'news-manager',
      tab: 'news',
      iconClass: <HiNewspaper   />,
      text: 'News Manager',
      submenuItems: [
        { text: 'All News', link: '/news/all-news', tab: '/news' },
      ],
    },
  ];
  
  useEffect(() => {
    const activeMenu = menuItems.find(item => {
      return (location.pathname.includes(item.submenuItems[0].tab))
    });
    
    setOpenMenuId(activeMenu.id);
  }, [location.pathname]);

  const toggleMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const handleLinkClick = (link) => {
    navigate(link, { replace: true });
  };

  return (
    <>
      <div className={`main-menu ${isSidebarVisible ? 'sidebar-enable' : ''}`}>
      <div className='logo-box justify-content-center'>
        <div className='logo-light'>
          <img src={logo} alt='logo' className='logo-lg' height={45} style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard')}/>
          <img src={small_logo} alt='small logo' className='logo-sm' height={45} style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard')}/>
        </div>
        <div className='logo-dark'>
          <img src={logo} alt='dark logo' className='logo-lg' height={60} style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard')}/>
          <img src={small_logo} alt='small logo' className='logo-sm' height={45} style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard')}/>
        </div>
      </div>

      {/* <hr className='m-0' /> */}

      <div data-simplebar style={{borderTop:'1px solid #474747' }}>
        <ul className='app-menu list-unstyled'>
          <li className='menu-title'>Menu</li>
          {menuItems.map((menuItem) => (
            <li key={menuItem.id} className='menu-item'>
              {!menuItem.submenuItems ? (
                <a
                  onClick={() => handleLinkClick(menuItem.submenuItems[0].link)}
                  className={`menu-link waves-effect waves-light 
                    ${location.pathname === menuItem.submenuItems[0].link ? 'sidebar-active' : ''}`}
                >
                  <span className='menu-icon'>{menuItem.iconClass}</span>
                  <span className='menu-text' style={{cursor:'pointer'}}>{menuItem.text}</span>
                </a>
              ) : (
                <>
                  <a
                    onClick={() => toggleMenu(menuItem.id)}
                    className='menu-link waves-effect waves-light'
                    aria-expanded={openMenuId === menuItem.id ? 'true' : 'false'}
                  >
                    <span className='menu-icon'>{menuItem.iconClass}</span>
                    <span className='menu-text'>{menuItem.text}</span>
                    <MdKeyboardArrowRight className='menu-arrow' />
                  </a>
                  <div className={`collapse ${openMenuId === menuItem.id ? 'show' : ''}`} id={`menu-${menuItem.id}`}>
                    <ul className='sub-menu'>
                      {menuItem.submenuItems.map((submenuItem, index) => (
                        <li key={index} className={`menu-item ${location.pathname === submenuItem.link ? 'active' : ''}`}>
                          <a
                            onClick={() => handleLinkClick(submenuItem.link)}
                            className='menu-link'
                            style={{ background: 'none' }}
                          >
                            <span className='menu-text' style={{ background: 'none',cursor:'pointer' }}>{submenuItem.text}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
    </>
  );
}

export default Sidebar;
