import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from "../../utils/Constant";
import config from '../../config/config';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";


function UpdateTeam() {
  const navigate  = useNavigate()
  const token = localStorage.getItem('token');
  const location = useLocation();
  const teamData = location.state;
  const teamID = teamData?.team_ID

  const [data, setData] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const [team_id, setTeam_id] = useState('');
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [code, setCode] = useState('');
  const [type, setType] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [color, setColor] = useState('');
  const [teamType, setTeamType] = useState('');

  useEffect(() => {
    const handleSubmit = () => {

      axios
        .get(
            `${config.appBaseUrl + _constantUtil.UPDATE_VIEW_TEAM_END_POINT}?team_id=${teamID}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          setData(result.data)
          setTeam_id(result.data.team_id)
          setName(result.data.name) 
          setShortName(result.data.short_name)
          setCode(result.data.code) 
          setProfileImage(result.data.profile)
          setType(result.data.type)
          setTeamType(result.data.team_type)
          setCountry(result.data.country)
          setCity(result.data.city)
          setColor(result.data.color)  
        })
        .catch((error) => {
          toast.error(error)
        });
    };
    handleSubmit();
  }, []);

  const updateTeam = async (event) => {
    event.preventDefault();
  
    try {
      const team = document.getElementById('upload');
      const data = new FormData();
      data.append('profile', team.files[_constantUtil.ZERO]);
      data.append('name', name);
      data.append('short_name', shortName);
      data.append('type', type);
      data.append('team_type', teamType);
      data.append('country', country);
      data.append('city', city);
      data.append('color', color);
      data.append('team_id', team_id);
  
      const response = await axios.patch(
        `${config.appBaseUrl + _constantUtil.UPDATE_TEAM_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      const result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch {
      toast.error('An error occurred');
    }
  };
  
  const handleImageChange = (event) => {
    const file = event.target.files[_constantUtil.ZERO];
    if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImage(event.target.result);
    };
    reader.readAsDataURL(file);
    }
  };

  return (
    <>
     <ToastContainer autoClose={2000} position='top-center' />
      <div className=''>
        <div className='row justify-content-center mx-4'>
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className='header-title mb-0 text-muted mt-4'>
              <Link onClick={() => navigate(-1)} className='text-muted'>
                All Team
              </Link>
              <span className="blr-player-view">
                <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 mx-2 '/>
              </span>
              Update Team
            </h4>
            <button
              type='button'
              className='btn text-white mt-4 d-flex'
              onClick={() => navigate(-1)}
            >
            <MdKeyboardArrowLeft className=' fs-4' />
              Back
            </button>
          </div>
          <div className='card mt-4'>
            <div className='card-body'>
              <div className='row flex-column flex-md-row p-0 d-flex align-items-center justify-content-center mb-4'>
                <div className='col-12 col-md-3 col-lg-4 d-flex flex-column align-items-center justify-content-center order-1 order-md-0 mb-3 mb-md-0'>
                  <img
                    src={profileImage}
                    alt='Profile'
                    className='img-thumbnail img-fluid'
                    style={{
                      borderRadius: '50%',
                      cursor: 'pointer',
                      objectFit: 'contain',
                      minHeight: '170px',
                      height: '170px',
                      maxWidth: '170px',
                      width: '170px',
                    }}
                  />
                  <div className='button-wrapper mt-4'>
                    <span className='label blr-bg-dull-sky rounded'><p className='mb-0 px-1'>Upload File</p></span>
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleImageChange}
                      className='upload-box form-control'
                      name='upload'
                      id='upload'
                      placeholder='Upload File'
                    />
                  </div>
                </div>
              </div>
              <form onSubmit={updateTeam}>
                <div className='row py-2'>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='name' className='form-label'>
                      Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      name='name'
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='short_name' className='form-label'>
                      Short Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='short_name'
                      name='shortname'
                      value={shortName}
                      onChange={(event) => {
                        setShortName(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='code' className='form-label'>
                      Code
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='code'
                      name='code'
                      value={code}
                      onChange={(event) => {
                        setCode(event.target.value);
                      }}
                      style={{ background: '#7e7e7e38' }}
                      disabled
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='type' className='form-label'>
                      Type
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='type'
                      name='type'
                      value={type}
                      onChange={(event) => {
                        setType(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='teamType' className='form-label'>
                      Team Type
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='teamType'
                      name='teamType'
                      value={teamType}
                      onChange={(event) => {
                        setTeamType(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='country' className='form-label'>
                      Country
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='country'
                      name='country'
                      value={country}
                      onChange={(event) => {
                        setCountry(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='city' className='form-label'>
                      City
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='city'
                      name='city'
                      value={city}
                      onChange={(event) => {
                        setCity(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='color' className='form-label'>
                      Color
                    </label>
                    <input
                      type='color'
                      className='form-control'
                      id='color'
                      name='color'
                      value={color}
                      onChange={(event) => {
                        setColor(event.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>
                  
                </div>
                <button type='submit' className='btn text-white mt-3' style={{ float: 'right' }}>
                  Save
                </button>
              </form>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default UpdateTeam;
