import React from 'react'

function Footer() {
  return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <div>2024 © <span className='blr-text-dull-sky'><a href="https://betbalr.com" target="_blank">BetBalr</a></span></div>
                </div>
                <div className="col-md-6">
                    <div className="d-none d-md-flex gap-4 align-item-center justify-content-md-end">
                        <p className="mb-0">Design & Develop by <a href="https://sdreatech.com" target="_blank" className='blr-text-dull-sky'>Sdreatech</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer
