import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../utils/Constant';
import config from '../../config/config';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

function UpdatePlayer() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  const location = useLocation();
  const playerData = location.state;
  const playerID = playerData?.player_ID; 

  const [data, setData] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const [player_id, setPlayer_id] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [country_dob, setCountry_dob] = useState('');
  const [gender, setGender] = useState('');
  const [nationality, setNationality] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [height, setHeight] = useState(_constantUtil.ZERO);
  const [weight, setWeight] = useState(_constantUtil.ZERO);
  const [position, setPosition] = useState('');
  const [foot, setFoot] = useState('');
  const [match_name, setMatch_name] = useState('');

  useEffect(() => { 
    const viewUser = async () => {
      try {
        const response = await axios.get(
          `${config.appBaseUrl + _constantUtil.UPDATE_VIEW_PLAYER_END_POINT}?player_id=${playerID}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const result = response.data.data;
        setData(result);
        setProfileImage(result.profile)
        setPlayer_id(result.player_id);
        setFirst_name(result.first_name);
        setLast_name(result.last_name);
        setCountry_dob(result.birth_country);
        setNationality(result.nationality);
        setBirthDay(result.birthdate);
        setGender(result.gender);
        setHeight(result.height);
        setWeight(result.weight);
        setPosition(result.position);
        setFoot(result.foot);
        setMatch_name(result.match_name);
      } 
      catch (error) {
        toast.error(error.message);
      }
    };
    viewUser()
  }, []);

  const updatePlayer = async event => {
    event.preventDefault();
  
    const data = new FormData();
    data.append('player_id', player_id);
    data.append('profile', document.getElementById('upload').files[_constantUtil.ZERO]);
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('gender', gender);
    data.append('nationality', nationality);
    data.append('birth_country', country_dob);
    data.append('height', height);
    data.append('weight', weight);
    data.append('position', position);
    data.append('foot', foot);
    data.append('match_name', match_name);
  
    try {
      const response = await axios.patch(
        `${config.appBaseUrl + _constantUtil.UPDATE_PLAYER_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      const result = response.data;
      (result.status) 
        ? toast.success(result.message) 
        : toast.error(result.message);
    } 
    catch (error) {
      toast.error(error.message);
    }
  };

  const handleImage = event => {
    const file = event.target.files[_constantUtil.ZERO];

    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className=''>
        <div className='row justify-content-center mx-4'>
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className='header-title mb-0 text-muted mt-4'>
              <Link onClick={() => navigate(-1)} className='text-muted'>
               All Player 
              </Link>
              <span className="blr-player-view">
                <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
              </span>{' '}
              Update Player
            </h4>
            <button
              type='button'
              className='btn text-white mt-4 d-flex'
              style={{ float: 'right' }}
              onClick={() => navigate(-1)}
            >
            <MdKeyboardArrowLeft className=' fs-4' />
            Back
            </button>
          </div >
          <div className='card mt-4'>
            <div className='card-body'>
              <div className='row flex-column flex-md-row p-0 d-flex align-items-center justify-content-center mb-4'>
                <div className='col-12 col-md-3 col-lg-4 d-flex flex-column align-items-center justify-content-center order-1 order-md-0 mb-3 mb-md-0'>
                  {/* image */}
                  <img
                    src={profileImage}
                    alt='Profile'
                    className='img-thumbnail img-fluid'
                    style={{
                      borderRadius: '50%',
                      cursor: 'pointer',
                      objectFit: 'contain',
                      minHeight: '170px',
                      height: '170px',
                      maxWidth: '170px',
                      width: '170px',
                    }}
                  />
                  <div className='button-wrapper mt-4'>
                    <span className='label blr-bg-dull-sky rounded'><p className='mb-0 px-1'>Upload File</p></span>
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleImage}
                      className='upload-box form-control'
                      name='upload'
                      id='upload'
                      placeholder='Upload File'
                    />
                  </div>
                </div>
              </div>
              <form onSubmit={updatePlayer}>
                <div className='row py-2'>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='first_name' className='form-label'>First Name</label>
                    <input
                      type='text'
                      className='form-control'
                      id='first_name'
                      name='first_name'
                      value={first_name}
                      onChange={event => setFirst_name(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='last_name' className='form-label'>Last Name</label>
                    <input
                      type='text'
                      className='form-control'
                      id='last_name'
                      name='last_name'
                      value={last_name}
                      onChange={event => setLast_name(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='country' className='form-label'>Country of Birth</label>
                    <input
                      type='text'
                      className='form-control'
                      id='country'
                      name='country'
                      value={country_dob}
                      onChange={event => setCountry_dob(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='gender' className='form-label'>Gender</label>
                    <select
                      className='form-control'
                      id='gender'
                      name='gender'
                      value={gender}
                      onChange={event => setGender(event.target.value)}
                    >
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                    </select>
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='nationality' className='form-label'>Nationality</label>
                    <input
                      type='text'
                      className='form-control'
                      id='nationality'
                      name='nationality'
                      value={nationality}
                      onChange={event => setNationality(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='dob' className='form-label'>Date of Birth</label>
                    <input
                      type='text'
                      className='form-control'
                      style={{ background: '#7e7e7e38' }}
                      id='dob'
                      name='dob'
                      value={birthDay}
                      onChange={event => setBirthDay(event.target.value)}
                      disabled
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='height' className='form-label'>Height</label>
                    <input
                      type='number'
                      className='form-control'
                      id='height'
                      name='height'
                      value={height}
                      onChange={event => setHeight(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='weight' className='form-label'>Weight</label>
                    <input
                      type='number'
                      className='form-control'
                      id='weight'
                      name='weight'
                      value={weight}
                      onChange={event => setWeight(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='position' className='form-label'>Position</label>
                    <input
                      type='text'
                      className='form-control'
                      id='position'
                      name='position'
                      value={position}
                      onChange={event => setPosition(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='foot' className='form-label'>Foot</label>
                    <input
                      type='text'
                      className='form-control'
                      id='foot'
                      name='foot'
                      value={foot}
                      onChange={event => setFoot(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-6'>
                    <label htmlFor='match_name' className='form-label'>Match Name</label>
                    <input
                      type='text'
                      className='form-control'
                      id='match_name'
                      name='match_name'
                      value={match_name}
                      onChange={event => setMatch_name(event.target.value)}
                      autoComplete='off'
                    />
                  </div>
                </div>
                <button type='submit' className='btn text-white' style={{ float: 'right' }}>
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePlayer;