import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom';
import {MdOutlineCircle, MdCreate , MdBlock, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdOutlineFileDownload} from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../../utils/Constant'
import config from '../../../config/config';
import axios from 'axios';
import Loader from '../../partials/Loader';
import { Tooltip } from 'react-tooltip'


function Leagues() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(_constantUtil.ONE);
  const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
  const [leagueID, setLeagueID] = useState();
  const [leagueStatus, setLeagueStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  const [showImportConfirmation, setShowImportConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);  

  const confirmationRef = useRef(_constantUtil.NULL);
  const allleague = async () => {
      try {
        const response = await axios.get(
          `${config.appBaseUrl + _constantUtil.ALL_LEAGUE_END_POINT}?page=${page}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
  
        const { records, total_page } = response.data.data;
        setData(records);
        setTotalPages(total_page);
      } 
      catch (error) {
        toast.error(error);
      }
    };    

  useEffect(() => {
    allleague()

    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
        setShowImportConfirmation(_constantUtil.BOOLEAN_FALSE)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };    
  }, [page]);

  const visibleLeague = async () => {
      setLoading(_constantUtil.BOOLEAN_TRUE)
      setDisableButton(_constantUtil.BOOLEAN_TRUE)
      try {
        const response = await axios.patch(
          `${config.appBaseUrl + _constantUtil.VISIBLE_LEAGUE_END_POINT}?competition_id=${leagueID}&status=${leagueStatus}`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
  
        const result = response.data;
  
        if (result.status) {
          const updatedData = data.map(league => {
            if (league._id === leagueID) {
              return { ...league, status: leagueStatus };
            }
            return league;
          });
          setData(updatedData);
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      } 
      catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(_constantUtil.BOOLEAN_FALSE);
        setDisableButton(_constantUtil.BOOLEAN_FALSE);
      } 
      setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };

  const showConfirmationModel = (params) => {
      const { id, status } = params;
      setLeagueID(id);
      setLeagueStatus(!status);
      setShowConfirmation(_constantUtil.BOOLEAN_TRUE);
  } 

  const importleague = async () => {
      setLoading(_constantUtil.BOOLEAN_TRUE)
      setDisableButton(_constantUtil.BOOLEAN_TRUE)
      try {
        const response = await axios.get(
          `${config.appBaseUrl + _constantUtil.IMPORT_LEAGUE_END_POINT}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
  
          const result = response.data;
          if (result.status) {
              toast.success(result.message)
              allleague();
          } else {
              toast.error(result.message);
          }
      } 
      catch (error) {
        toast.error(error);
      }
      finally {
          setLoading(_constantUtil.BOOLEAN_FALSE);
          setDisableButton(_constantUtil.BOOLEAN_FALSE);
      }
      setShowImportConfirmation(_constantUtil.BOOLEAN_FALSE)
  };    

  return (
    <>
        <ToastContainer autoClose={2000} position='top-center' />
        {showConfirmation && (
          <div>
            <div className='confirmation-overlay'></div>
            <div ref={confirmationRef} className='confirmation-dialog'>
              <p>Are you sure to update league status ?</p>
              <div className='d-flex justify-content-end '>
                <button onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                <button onClick={() => visibleLeague()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton}>{ !loading ? 'Confirm' : <Loader />}</button>
              </div>
            </div>
          </div>
        )}
        {showImportConfirmation && (
          <div>
            <div className='confirmation-overlay'></div>
            <div ref={confirmationRef} className='confirmation-dialog'>
              <p>Are you sure to import league ?</p>
              <div className='d-flex justify-content-end '>
                <button onClick={() => setShowImportConfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                <button onClick={() => importleague()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton}>{ !loading ? 'Confirm' : <Loader />}</button>
              </div>
            </div>
          </div>
        )}
        <div className='px-4 mt-4'>
            <div className='container-fluid p-0'>
                <div className='row'>
                    <h4 className='header-title pb-3 text-muted'>All League</h4>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>

                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className=''>
                                            <button
                                                type='submit'
                                                className='btn text-white d-flex'
                                                onClick={() => setShowImportConfirmation(_constantUtil.BOOLEAN_TRUE)}>
                                                <MdOutlineFileDownload className='fs-4 me-1' />
                                                Import
                                            </button>
                                        </div>
                                    </div>

                                    <div className='table-responsive'>
                                        <table className='table w-100 ' >
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    {/* <th>Profile</th> */}
                                                    <th>Name</th>
                                                    <th >Format & Country</th>
                                                    <th>Upcoming</th>
                                                    <th>Launched</th>
                                                    <th>Live</th>
                                                    <th>Completed</th>
                                                    <th>Abondoned</th>
                                                    <th >League Type</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((league, index) => (
                                                <tr key={league._id}>
                                                    <th scope='row'>{((page - _constantUtil.ONE) * 20) + (index + _constantUtil.ONE)} </th>
                                                    {/* <td> <img src={league.profile} alt="image" className='user-img'/> </td> */}
                                                    <td >{league.name} <br /> ( {league.known_name} - {league.code} )</td>
                                                    <td >{league.format} <br /> ( {league.country} )</td>
                                                    <td><FaRegCalendarAlt  
                                                           data-tooltip-id='upcomingmatch' data-tooltip-content="Match"
                                                          className=' blr-icon me-2 fs-5'
                                                          style={{ cursor: 'pointer',  }}
                                                          onClick={() => navigate('/match/all-upcoming', { 
                                                            state: {
                                                            leagueID: league.cid,
                                                          isLaunch :  false}})} 
                                                        />
                                                        <Tooltip id='upcomingmatch' place="bottom" effect="solid"/></td>
                                                    <td>
                                                      <span className='badge badge-secondary-lighten' style={{ cursor: 'pointer'}}  
                                                      data-tooltip-id='launchmatch' data-tooltip-content="Match"
                                                            onClick={() => navigate('/match/all-launch', { 
                                                            state: {
                                                            leagueID: league.cid,
                                                            isLaunch : true }})} >{league.upcoming} 
                                                      </span>
                                                      <Tooltip id='launchmatch' place="bottom" effect="solid"/>
                                                    </td>
                                                    <td>
                                                      <span className='badge badge-info-lighten' 
                                                        onClick={() => navigate('/match/live-match')}  style={{ cursor: 'pointer'}}>
                                                          {league.live}
                                                      </span>
                                                      </td>
                                                    <td>
                                                      <span className='badge badge-success-lighten'
                                                        onClick={() => navigate('/match/completed-match')} style={{ cursor: 'pointer'}}>
                                                        {league.completed}
                                                      </span>
                                                      </td>
                                                    <td>
                                                      <span className='badge badge-danger-lighten'>
                                                        {league.abandoned}
                                                      </span>
                                                      </td>
                                                    <td>{league.type} <br /> ( {league.competition_type} )</td>
                                                    <td>
                                                      {league.status ? (
                                                        <span className='badge badge-success-lighten'>
                                                          ACTIVE
                                                        </span>
                                                        ) : (
                                                        <span className='badge badge-danger-lighten'>
                                                          INACTIVE
                                                        </span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      <div className='d-flex align-items-center justify-content-center'>
                                                      <MdCreate
                                                        data-tooltip-id='updateleague' data-tooltip-content="Update League"
                                                        className=' blr-icon me-2 fs-5' style={{cursor: 'pointer', color:'gray'}}
                                                        onClick={() => navigate(`/match/update-league`, { 
                                                          state: {
                                                            leagueID: league.cid, 
                                                            profile: league.profile,
                                                            name: league.name,
                                                            known_name: league.known_name,
                                                            code: league.code,
                                                            order: league.order,
                                                            format: league.format,
                                                            country: league.country,
                                                            type: league.type,
                                                            competition_type: league.competition_type
                                                          }
                                                        })}
                                                      />
                                                      <Tooltip id='Viea League' place="bottom" effect="solid"/>
                                                        <FaEye 
                                                           data-tooltip-id='Viea League' data-tooltip-content="View League"
                                                          className=' blr-icon me-2 fs-5 blr-text-dull-sky'
                                                          style={{ cursor: 'pointer'}}
                                                          onClick={() => navigate(`/match/view-league`, { 
                                                            state: {
                                                              leagueID: league.cid, 
                                                              profile: league.profile,
                                                              name: league.name,
                                                              known_name: league.known_name,
                                                              code: league.code,
                                                              order: league.order,
                                                              format: league.format,
                                                              country: league.country,
                                                              type: league.type,
                                                              competition_type: league.competition_type
                                                            }
                                                          })} 
                                                        />
                                                        <Tooltip id='updateleague' place="bottom" effect="solid"/>
                                                        
                                                        {league.status ? 
                                                        <>
                                                          <MdBlock
                                                            data-tooltip-id='inactive' data-tooltip-content="Cancel Match"
                                                            className=' blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'red'}}   
                                                            onClick={() => showConfirmationModel({ id: league._id, status: league.status })}
                                                          />
                                                          <Tooltip id='inactive' place="bottom" effect="solid"/>
                                                          </>
                                                        : 
                                                        <>
                                                          <MdOutlineCircle
                                                            data-tooltip-id='active' data-tooltip-content="Active Match"
                                                            className=' blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'green'}}   
                                                            onClick={() => showConfirmationModel({ id: league._id, status: league.status })}
                                                          /> 
                                                          <Tooltip id='active' place="bottom" effect="solid"/>
                                                        </>
                                                        }
                                                      </div>  
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>    
                                    </div>

                                    <ReactPaginate
                                        className='pagination'
                                        onPageChange={page => { setPage(page.selected + _constantUtil.ONE) }}
                                        pageCount={totalPages}
                                        previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                                        nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={1}
                                        breakLabel={'..'}
                                        containerClassName={'pagination-container'}
                                        activeClassName={'pagination-active-page'}
                                    /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Leagues
