import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { ToastContainer } from 'react-toastify';

function ViewLeague() {
  const navigate = useNavigate()
  const location = useLocation();
  const leagueData = location.state;

  const profile =  leagueData?.profile ?? '';
  const name =  leagueData?.name ?? '';
  const known_name =  leagueData?.known_name ?? '';
  const code =  leagueData?.code ?? '';
  const order =  leagueData?.order ?? '';
  const format =  leagueData?.format ?? '';
  const country =  leagueData?.country ?? '';
  const type =  leagueData?.type ?? '';
  const competition_type =  leagueData?.competition_type ?? ''


  return (
    <>
      <ToastContainer autoClose={2000} position="top-center" />
      <div className="">
        <div className="row mx-4">
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className="header-title mb-0 text-muted mt-4">
              <Link onClick={() => navigate(-1)} className="text-muted">
                All League
              </Link>
              <span className="blr-player-view">
                <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
              </span>{" "}
              View League
            </h4>
            <button
              type='button'
              className='btn text-white mt-4 d-flex'
              onClick={() => navigate(-1)}
            >
              <MdKeyboardArrowLeft className='fs-4' />
              Back
            </button>
          </div>
          <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-center px-1 mt-4">
            <div className="blr-img-league py-4">
              <img
                src={profile}
                alt="Profile"
                className="img-fluid"
              />
              <h4 className="pt-3 text-center">{name}</h4>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9 my-4">
            <div className="responsive-table p-1" style={{border:'1px solid var(--bs-border-color)',borderRadius:'10px'}}>
              <table className='table w-100 nowrap mb-0'>
                <tbody>
                  <tr>
                    <th className='pb-3 w-25'>Known Name</th>
                    <td className='pb-3'>:</td>
                    <td className='pb-3'>{known_name}</td>
                  </tr>
                  <tr>
                    <th className='pb-3'>Code</th>
                    <td className='pb-3'>:</td>
                    <td className='pb-3'>{code}</td>
                  </tr>
                  <tr>
                    <th className='pb-3'>Order</th>
                    <td className='pb-3'>:</td>
                    <td className='pb-3'>{order}</td>
                  </tr>
                  <tr>
                    <th className='pb-3'>Format</th>
                    <td className='pb-3'>:</td>
                    <td className='pb-3'>{format}</td>
                  </tr>
                  <tr>
                    <th className='pb-3'>Country</th>
                    <td className='pb-3'>:</td>
                    <td className='pb-3'>{country}</td>
                  </tr>
                  <tr>
                    <th className='pb-3'>Type</th>
                    <td className='pb-3'>:</td>
                    <td className='pb-3'>{type}</td>
                  </tr>
                  <tr>
                    <th className='pb-3 border-0 text-nowrap'>Competition Type</th>
                    <td className='pb-3 border-0'>:</td>
                    <td className='pb-3 border-0'>{competition_type}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewLeague
