import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllNews from './../news/AllNews';
import AddNews from '../news/AddNews';
import ViewNews from '../news/ViewNews';
import UpdateNews from '../news/UpdateNews';

function NewsRoutes() {
  return (
    <Routes>
        <Route path="/all-news" element={<AllNews />} />
        <Route path="/add-news" element={<AddNews />} />
        <Route path="/view-news" element={<ViewNews />} />
        <Route path="/update-news" element={<UpdateNews />} />
    </Routes>
  )
}

export default NewsRoutes;
