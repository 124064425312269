import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../../utils/Constant';
import config from '../../../config/config';

function UpdateBets() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  const location = useLocation();
  const betsData = location.state;
  const eventName = betsData?.Bevent ?? ''

  const question =( {
      period_id : betsData?.BperiodID ?? '',
      type_id : betsData?.BtypeID ?? '', 
      betbalr_id : betsData?.BbetbalrID ?? '', 
  })
  const iD = betsData?.Bid ?? ''

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [results, setResults] = useState([])

  const [betname, setBetName] = useState(betsData?.Bname ?? '');
  const [image, setImage] = useState(betsData?.Bimage ?? '');
  const [subtitle, setSubtitle] = useState(betsData?.Bsubtitle ?? '');
  const [priority, setPriority] = useState(betsData?.Bpriority ?? _constantUtil.ZERO);
  const [type, setType] = useState(betsData?.Btype ?? '');
  const [label, setLabel] = useState(betsData?.Blabel ?? '');
  const [resultType, setResultType] = useState(betsData?.BresultType ?? _constantUtil.ZERO);
  const [result, setResult] = useState(betsData?.Bresult ?? '');
  const [optionA, setOptionA] = useState(betsData?.BoptionA ?? '');
  const [optionB, setOptionB] = useState(betsData?.BoptionB ?? '');
  const [question1, setQuestion1] = useState(betsData?.Bquestion ?? '');

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.appBaseUrl + _constantUtil.QUETION_DETAIL_END_POINT}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const records = response.data.data;
        setTypes(records.types);
        setResults(records.results);
        setCategories(records.categories);
      } catch (error) {
        toast.error('Failed to fetch data');
      }
    };
    fetchData();
  }, [token]);

  const handleCategoryChange = (e) => {
    const selectedCategoryName = e.target.value;
    const category = categories.find((cat) => cat.name === selectedCategoryName);
    setImage(category?.profile  ?? image );
    setSubtitle(category?.subtitle ?? '');
    setPriority(category?.priority ?? '');
    setBetName(category?.name ?? '');
  };

  const handleTypeChange = (e) => {
    const selectedTypeName = e.target.value;
    const type = types.find((type) => type?.label === selectedTypeName); 
    if (!type) { 
      setType('');
      setLabel('');
      setOptionA('');
      setOptionB('');
      return;
    }
    setType(type.type);
    setLabel(type.label)
    const tt = type?.type;
    setOptionA(tt === 1 ? 'yes' : tt === 2 ? 'odd' : tt === 3 ? 'team a' : '');
    setOptionB(tt === 1 ? 'no' : tt === 2 ? 'even' : tt === 3 ? 'team b' : '');
  };

  const handleResultChange = (e) => {
    const selectedResult = results.find((r) => r.result_str === e.target.value);
    if (!selectedResult) { 
      setResultType('');
      setResult('');
      return;
    }
    setResultType(selectedResult.result);
    setResult(selectedResult.result_str);
  };

  const UpdateQuestion = async (event) => {
    event.preventDefault();

    const data = {
      "betbalr_id": question.betbalr_id,
      "type_id": question.type_id,
      "period_id": question.period_id,
      "event": eventName,
      "question_id": iD,
      "name": betname,
      "subtitle": subtitle,
      "priority": priority,
      "profile": image,
      "question": question1,
      "type": type,
      "label": label,
      "result": resultType,
      "result_str": result,
    };

    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.UPDATE_QUESTION_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data;
      result.status ? toast.success(result.message) : toast.error(result.message);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className=''>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='row justify-content-center mx-4'>
        <div className='d-flex align-items-center justify-content-between p-0'>
          <h4 className='header-title mb-0 text-muted mt-4'>
            <Link onClick={() => navigate(-1)} className='text-muted'>
              All Bets
            </Link>
            <span className='blr-player-view'>
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 mx-2 ' />
            </span>
            Update Question
          </h4>
          <button
            type='button'
            className='btn text-white mt-4 d-flex'
            onClick={() => navigate(-1)}
          >
            <MdKeyboardArrowLeft className='fs-4' />
            Back
          </button>
        </div>
        <div className='card mt-4'>
          <div className='card-body'>
            <form onSubmit={UpdateQuestion}>
              <div className='row flex-column flex-md-row p-0 d-flex align-items-center justify-content-center my-2'>
                <div className='col-12 col-md-3 col-lg-4 d-flex flex-column align-items-center justify-content-center order-1 order-md-0 mb-3 mb-md-0'>
                  <img
                    src={image}
                    alt='Profile'
                    className='img-thumbnail img-fluid'
                    style={{
                      borderRadius: '50%',
                      cursor: 'pointer',
                      objectFit: 'contain',
                      minHeight: '170px',
                      height: '170px',
                      maxWidth: '170px',
                      width: '170px',
                    }}
                  />
                </div>
              </div>
              <div className='row py-2'>
                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='category' className='form-label'>
                    Category
                  </label>
                  <select
                    id='category'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleCategoryChange}
                    value={betname}
                  >
                    <option value=''>Select Category</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='subtitle' className='form-label'>
                    Subtitle
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='subtitle'
                    name='subtitle'
                    value={subtitle}
                    autoComplete='off'
                    disabled
                  />
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='priority' className='form-label'>
                    Priority
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='priority'
                    name='priority'
                    value={priority}
                    autoComplete='off'
                    disabled
                  />
                </div>  

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='typeofquestion' className='form-label'>
                    Type of Question
                  </label>
                  <input
                    id='typeofquestion'
                    className='form-control me-2'
                    value={eventName}
                    disabled
                  >
                  </input>
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='type' className='form-label'>
                    Question Type
                  </label>
                  <select
                    id='questiontype'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleTypeChange}
                    value={label}
                  >
                    <option value=''>Select Type</option>
                    {types.map((type) => (
                      <option key={type.type} value={type.label}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='result' className='form-label'>
                    Result Type
                  </label>
                  <select
                    id='result'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleResultChange}
                    value={result}
                  >
                    <option value=''>Select Result Type</option>
                    {results.map((result) => (
                      <option key={result.result} value={result.result_str} data-key={result.result}>
                        {result.result_str}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='question' className='form-label'>
                    Question
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='question'
                    name='question'
                    value={question1}
                    onChange={(e) => setQuestion1(e.target.value)}
                    autoComplete='off'
                  />
                </div>

               

                

                <div className='mb-2 col-12 col-md-3'>
                  <label htmlFor='optionOne' className='form-label'>
                    Option One
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='optionOne'
                    name='optionOne'
                    value={optionA}
                    autoComplete='off'
                    disabled
                  />
                </div>

                <div className='mb-2 col-12 col-md-3'>
                  <label htmlFor='optionTwo' className='form-label'>
                    Option Two
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='optionTwo'
                    name='optionTwo'
                    value={optionB}
                    autoComplete='off'
                    disabled
                  />
                </div>

              </div>
              <button type='submit' className='btn text-white mt-3' style={{ float: 'right' }}>
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateBets;
