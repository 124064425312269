import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import profile from '../../../assets/images/betbalr - Copy.png';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../../utils/Constant';
import config from '../../../config/config';

function AddBets() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');

  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questionselected, setQuestionselected] = useState([]);
  const [types, setTypes] = useState([]);
  // const [displays, setDisplays] = useState([]);
  const [results, setResults] = useState([]);
  const [profileImage, setProfileImage] = useState(profile);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedType, setSelectedType] = useState({});
  // const [selectedDisplay, setSelectedDisplay] = useState('');
  const [questionEvent, setQuestionEvent] = useState('');
  const [selectedResult, setSelectedResult] = useState('');
  const [question , setQuestion] =useState({});
  const [optionOne, setOptionOne] = useState('');
  const [optionTwo, setOptionTwo] = useState('');
  const [question1, setQuestion1] = useState('');
  // const [minute, setMinute] = useState(2)

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.appBaseUrl + _constantUtil.QUETION_DETAIL_END_POINT}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const records = response.data.data;
        setData(records);
        setCategories(records.categories);
        setTypes(records.types);
        // setDisplays(records.displays);
        setResults(records.results);
        setQuestionselected(records.questions)
      } catch (error) {
        toast.error('Failed to fetch data');
      }
    };
    fetchData();
  }, [questionselected]);

  const handleCategoryChange = (e) => {
    const selectedCategoryName = e.target.value;
    const category = categories.find((cat) => cat.name === selectedCategoryName);
    setSelectedCategory(category);
    setProfileImage(category?.profile ?? profile);
  };

  const handleTypeChange = (e) => {
    const selectedTypeName = e.target.value;
    const type = types.find((type) => (type?.label ?? '') === selectedTypeName);

      setSelectedType(type);
      const tt = type?.type;
      setOptionOne((tt === 1) ? 'yes' : (tt === 2) ? 'odd' : (tt === 3) ? 'team a' : '');
      setOptionTwo((tt === 1) ? 'no' : (tt === 2) ? 'even' : (tt === 3) ? 'team b' : '');
    
  };

  // const handleDisplayChange = (event) => {
  //   const selectedOption = event.target.options[event.target.selectedIndex];
  //   const key = selectedOption.getAttribute('data-key');
  //   const value = selectedOption.value;
  //   setSelectedDisplay({display: key, display_str: value})
  // }; 
  
  const handleQuestionChange = (event) => {
    const selectedQuestion = event.target.options[event.target.selectedIndex];
    const key = JSON.parse(selectedQuestion.getAttribute('data-key'));
    const value = selectedQuestion.value;
    setQuestion(key)
    setQuestionEvent(value)
  };  

  const handleResultChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const key = selectedOption.getAttribute('data-key');
    const value = selectedOption.value;
    setSelectedResult({result_type: key, result_str: value})
  };

  const addQuestion = async (event) => {
    event.preventDefault();

    const betID = !questionEvent ? '' : question.state.betbalrID;
    const typeID = !questionEvent ? '' : question.state.typeID;
    const periodID = !questionEvent ? '' : question.state.periodID;

    
    let data = ({
      // "betbalr_id": question.state.betbalrID,
      // "type_id": question.state.typeID,
      // "period_id": question.state.periodID,
      "betbalr_id":betID ,
      "type_id":typeID ,
      "period_id": periodID,
      "event": questionEvent,
      "name": selectedCategory?.name ,
      "subtitle": selectedCategory?.subtitle ,
      "priority": selectedCategory?.priority ,
      "profile": profileImage,
      "question": question1,
      "type": selectedType?.type,
      "label": selectedType?.label,
      // "display": selectedDisplay.display,
      // "display_str": selectedDisplay.display_str,
      // "expire_minute": minute,
      "result": selectedResult.result_type,
      "result_str": selectedResult.result_str ,
    });
    
    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.UPDATE_QUESTION_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      const result = response.data;
      if(result.status) { 
        toast.success(result.message)
        setSelectedCategory({});
        // setQuestionselected([])
        setQuestionEvent('')
        setSelectedType({});
        // setSelectedDisplay('');
        setSelectedResult({});
        setQuestion('');
        setQuestion1('');
        setOptionOne('');
        setOptionTwo('');
        // setMinute(2);
        setProfileImage(profile);
      } else {
        toast.error(result.message);
      }
    } 
    catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className=''>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='row justify-content-center mx-4'>
        <div className='d-flex align-items-center justify-content-between p-0'>
          <h4 className='header-title mb-0 text-muted mt-4'>
            <Link onClick={() => navigate(-1)} className='text-muted'>
              Global Bets
            </Link>
            <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 mx-2 ' />
            </span>
            Add Question
          </h4>
          <button
            type='button'
            className='btn text-white mt-4 d-flex'
            onClick={() => navigate(-1)}
          >
            <MdKeyboardArrowLeft className='fs-4' />
            Back
          </button>
        </div>
        <div className='card mt-4'>
          <div className='card-body'>
            <form onSubmit={addQuestion} >

              <div className='row flex-column flex-md-row p-0 d-flex align-items-center justify-content-center my-2'>
                <div className='col-12 col-md-3 col-lg-4 d-flex flex-column align-items-center justify-content-center order-1 order-md-0 mb-3 mb-md-0'>
                  <img
                    src={profileImage}
                    alt='Profile'
                    className='img-thumbnail img-fluid'
                    style={{
                      borderRadius: '50%',
                      cursor: 'pointer',
                      objectFit: 'contain',
                      minHeight: '170px',
                      height: '170px',
                      maxWidth: '170px',
                      width: '170px',
                    }}
                  />
                </div>
              </div>

              <div className='row py-2'>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='category' className='form-label'>
                    Category
                  </label>
                  <select
                    id='category'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleCategoryChange}
                    value={selectedCategory?.name ?? ''}
                  >
                    <option value=''>Select Category</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='priority' className='form-label'>
                    Subtitle
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='subtitle'
                    name='subtitle'
                    // value={selectedCategory.subtitle ?? ''}
                    placeholder={selectedCategory?.subtitle ?? ''}
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='priority' className='form-label'>
                    Priority
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='priority'
                    name='priority'
                    placeholder={selectedCategory?.priority ?? 0}
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='typeofquestion' className='form-label'>
                    Type of Question
                  </label>
                  <select
                    id='typeofquestion'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleQuestionChange}
                    value={questionEvent}
                  >
                    <option value=''>Select Question</option>
                    {questionselected.map((question) => (
                      <option key={question.betbalr_id} value={question.event} data-key={JSON.stringify({
                        state: {
                          betbalrID: question.betbalr_id,
                          typeID: question.type_id,
                          periodID: question.period_id,
                        },
                      })}>
                        {question.event}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='type' className='form-label'>
                    Question Type
                  </label>
                  <select
                    id='questiontype'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleTypeChange}
                    value={selectedType?.label ?? ''}
                  >
                    <option  value=''>Select Type</option>
                    {types.map((type) => (
                      <option key={type.type} value={type.label}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-4'>
                  <label htmlFor='result' className='form-label'>
                    Result Type
                  </label>
                  <select
                    id='result'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleResultChange}
                    value={selectedResult?.result_str ?? ''}
                  >
                    <option value=''>Select Result Type</option>
                    {results.map((result) => (
                      <option key={result.result } value={result.result_str } data-key={result.result }>
                        {result.result_str }
                      </option>
                    ))}
                  </select>
                </div>

                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='question' className='form-label'>
                    Question
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='question'
                    name='question'
                    value={question1}
                    onChange={(e)=>setQuestion1(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                
                <div className='mb-2 col-12 col-md-3'>
                  <label htmlFor='optionOne' className='form-label'>
                    Option One
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='optionOne'
                    name='optionOne'
                    placeholder={optionOne}
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='mb-2 col-12 col-md-3'>
                  <label htmlFor='optionTwo' className='form-label'>
                    Option Two
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='optionTwo'
                    name='optionTwo'
                    placeholder={optionTwo}
                    disabled
                    autoComplete='off'
                  />
                </div> 
                
                {/* <div className='mb-2 col-12 col-md-3'>
                  <label htmlFor='time' className='form-label'>
                    Time
                  </label>
                  <input type="number" value={minute} 
                  className='form-control me-2'
                  onChange={(e)=>setMinute(e.target.value)} 
                  step={2} min={2} max={90}/> 
                </div>

                <div className='mb-2 col-12 col-md-3'>
                  <label htmlFor='display' className='form-label'>
                    Display Type
                  </label>
                  <select
                    id='display'
                    className='form-select me-2'
                    style={{ backgroundColor: 'transparent' }}
                    onChange={handleDisplayChange}
                    // value={selectedDisplay}
                  >
                    <option value=''>Select Display Type</option>
                    {displays.map((display) => (
                      <option key={display.display_type} value={display.display_str} data-key={display.display_type}>
                        {display.display_str}
                      </option>
                    ))}
                  </select>
                </div> */}

              </div>
              <button type='submit' className='btn text-white mt-3' style={{ float: 'right' }}>
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBets;
