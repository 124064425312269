import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Loader from '../components/partials/Loader';
import logo from '../assets/images/betbalr.png'
import _constantUtil from '../utils/Constant';
import config from '../config/config';

function EmailVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const storedThemeMode = sessionStorage.getItem('themeMode');

  const email = location.state?.email || '';
  const password = location.state?.password || '';

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [resendTimer, setResendTimer] = useState(59);
  const [resendDisabled, setResendDisabled] = useState(_constantUtil.BOOLEAN_FALSE);

  useEffect(() => {
    let intervalId;
    if (resendTimer > 0) {
      intervalId = setInterval(() => {
        setResendTimer(prevTime => prevTime - _constantUtil.ONE);
      }, 1000);
      setResendDisabled(_constantUtil.BOOLEAN_TRUE);
    } else {
      setResendDisabled(_constantUtil.BOOLEAN_FALSE);
    }

    return () => clearInterval(intervalId);
  }, [resendTimer]);

  const handleChange = (event, index) => {
    const value = event.target.value;
    const newOtp = [...otp];

    if (/[0-9]/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp__digit_${index + 1}`).focus();
      } else if (!value && index > 0) {
        document.getElementById(`otp__digit_${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');
    const newOtp = [...otp];
    const digits = pasteData.split('').filter(char => /[0-9]/.test(char));

    digits.forEach((digit, i) => {
      if (index + i < newOtp.length) {
        newOtp[index + i] = digit;
      }
    });

    setOtp(newOtp);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const otpCode = otp.join(''); 
    setLoading(_constantUtil.BOOLEAN_TRUE);
    setDisableButton(_constantUtil.BOOLEAN_TRUE);
  
    const data = {
      email,
      password,
      otp: otpCode
    };
  
    try {
      const res = await axios.post(`${config.appBaseUrl + _constantUtil.VERIFY_OTP_END_POINT}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = res.data;
      if (result.status) {
        toast.success(result.message); 
        setTimeout(() => {
          navigate('/dashboard');
          setLoading(_constantUtil.BOOLEAN_FALSE);
          setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }, 2500);
        localStorage.setItem('token', result.data.token);
      } else {
        toast.error(result.message);
        setTimeout(() => {
          setLoading(_constantUtil.BOOLEAN_FALSE);
          setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }, 2500);
      }
    } catch (error) {
      toast.error(error.message); 
    }
  };

  const handleResendOTP = (event) => {
    event.preventDefault();
    setLoading(_constantUtil.BOOLEAN_TRUE);
    setDisableButton(_constantUtil.BOOLEAN_TRUE);
    setResendDisabled(_constantUtil.BOOLEAN_TRUE);
    setOtp(['', '', '', '', '', ''])
    setResendTimer(59);
    
    const data = {
      email ,
      password,
    };
  
    axios.post(`${config.appBaseUrl + _constantUtil.SEND_OTP_END_POINT}`, data, {
    }).then(res => {
      const result = res.data
      if (result.status) {
        toast.success(result.message);
        setLoading(_constantUtil.BOOLEAN_FALSE);
      } else {
        toast.error(result.message);
        setLoading(_constantUtil.BOOLEAN_FALSE);
        setDisableButton(_constantUtil.BOOLEAN_FALSE);
        setResendDisabled(_constantUtil.BOOLEAN_FALSE);
      }
    }).catch(error => {
      toast.error(error);
      setLoading(_constantUtil.BOOLEAN_FALSE);
      setDisableButton(_constantUtil.BOOLEAN_FALSE);
      setResendDisabled(_constantUtil.BOOLEAN_FALSE);
    });
  };
  
  return (
    <div className='authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5'>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-4 col-md-5'>
            <div className='card'>
              <div className='card-body p-4'>
                <div className='text-center w-75 mx-auto auth-logo mb-4'>
                  <a className='logo-dark'>
                    <span>
                      <img src={logo} alt='' height={45} />
                    </span>
                  </a>
                  <a className='logo-light'>
                    <span>
                      <img src={logo} alt='' height={45} />
                    </span>
                  </a>
                </div>
                <div className='form-group mb-3'>
                  <div>
                    <label className='form-label fs-4 mt-2 '>
                      Email Verification
                    </label>
                    <label
                      className='form-label '
                      style={{ color: '#118a44' }}
                    >
                      An otp has been sent to {email}
                    </label>
                  </div>
                  <div>
                    <label className='form-label '>
                      Please enter OTP to verify
                    </label>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='otp-container my-3'>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp__digit_${index}`}
                        type='text'
                        className={`otp__digit otp__digit_${index} ${storedThemeMode === 'dark' ? 'text-white' : 'text-dark'}`}
                        maxLength='1'
                        value={digit}
                        autoComplete='off'
                        onChange={(e) => handleChange(e, index)}
                        onPaste={(e) => handlePaste(e, index)}
                      />
                    ))}
                  </div>
                  <div className='text-primary resend-otp-email text-underline font-weight-medium ms-1'>
                    <div className='d-flex'>
                      <button onClick={handleResendOTP} type='button' style={{background:'transparent',border:'none'}} disabled={resendDisabled}>
                        Resend OTP
                      </button>
                      {resendTimer > _constantUtil.ZERO && (
                        <span> {resendTimer} seconds</span>
                      )}
                    </div>
                  </div>
                  <div className='form-group mb-0 '>
                    <button type='submit' className='text-white text-center w-100 form-button' disabled={disableButton}>
                      {!loading ? 'Verify OTP' : <Loader/>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailVerification;
