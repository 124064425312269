import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function OTPVerification() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleChange = (event, index) => {
    const value = event.target.value;
    const newOtp = [...otp];

    if (/[0-9]/.test(value) || event.keyCode === 8) {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp__digit_${index + 1}`).focus();
      } else if (event.keyCode === 8 && index > 0) {
        document.getElementById(`otp__digit_${index - 1}`).focus();
      }

      const isOtpComplete = newOtp.every((digit) => digit !== "");
    }
  };

  return (
    <div className="authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 mx-auto auth-logo mb-4">
                  <a className="logo-dark">
                    <span>
                      <img src="/images/logo-full.png" alt="" height={60} />
                    </span>
                  </a>
                  <a className="logo-light">
                    <span>
                      <img src="/images/logo-full.png" alt="" height={60} />
                    </span>
                  </a>
                </div>
                <div className="form-group mb-3">
                  <label className="form-label fs-2 ps-6 blr-label-padding">
                    OTP Verification
                  </label>
                  <div>
                    <label className="form-label" style={{ color: "#118a44" }}>
                      An otp has been sent to ********k876@gmail.com
                    </label>
                  </div>
                  <div>
                    <label className="form-label">
                      Please enter OTP to verify
                    </label>
                  </div>
                </div>

                <form onSubmit={handleChange}>
                  <div className="otp-container my-4">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp__digit_${index}`}
                        type="text"
                        className={`otp__digit otp__digit_${index}`}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                      />
                    ))}
                  </div>
                  <div className="form-group mb-0 text-center w-100 form-button">
                  <Link to="/dashboard" className="text-white" >
                    Confirm
                </Link>
                  </div>
                </form>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <p className="text-white-50">
                      Already have an account?{" "}
                      <Link className="text-white font-weight-medium ms-1" to="/">
                        Log In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
