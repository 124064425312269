import React from "react";
import { Link } from "react-router-dom";

function ForgotPassword() {
  return (
    <div className="authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 mx-auto auth-logo mb-4">
                  <a className="logo-dark" >
                    <span>
                      <img
                        src="/images/logo-full.png"
                        alt=""
                        height={60}
                      />
                    </span>
                  </a>
                  <a className="logo-light" >
                    <span>
                    <img
                        src="/images/logo-full.png"
                        alt=""
                        height={60}
                      />
                    </span>
                  </a>
                  <h2 className="text-muted mt-3">Send Email</h2>
                </div>
                <form >
                  <div className="form-group mb-3">
                    <label className="form-label" htmlFor="emailaddress">
                      Email address
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="emailaddress"
                      required=""
                      placeholder="Enter your email"
                    />
                  </div>
                  {/* <div className="form-group mb-3">
                    <div className="">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkbox-signin"
                        defaultChecked=""
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkbox-signin"
                      >
                        I accept <a href="#">Terms and Conditions</a>
                      </label>
                    </div>
                  </div> */}
                  <div className="form-group mb-0 text-center w-100 form-button ">
                  <Link to="/otp" className="text-white" >
                     Send OTP  
                      </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p className="text-white-50">
                  Already have an account ?{" "}
                  <Link
                    className="text-white font-weight-medium ms-1"
                    to="/"
                  >
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
