import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import tshirt_image from '../../assets/images/t-shirt-1.png';
import _constantUtil from '../../utils/Constant';
import config from '../../config/config';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { MdAccountBalanceWallet } from "react-icons/md";


function ViewUser() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  const storedThem = sessionStorage.getItem('themeMode');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('user_id');

  const [data, setData] = useState([]);
  const [team, setTeam] = useState([]);
  const [tshirt, setTshirt] = useState([]);
  const [players, setPlayers] = useState([]);
  const [showTeams, setShowTeams] = useState(_constantUtil.BOOLEAN_FALSE);
  const [showPlayers, setShowPlayers] = useState(_constantUtil.BOOLEAN_FALSE);
  const [dataFetched, setDataFetched] = useState(_constantUtil.BOOLEAN_FALSE);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.VIEW_USER_END_POINT}?user_id=${userId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const { data } = response.data;
      setData(data);
      setTeam(data.teams);
      setPlayers(data.players);
      setTshirt(data.tshirt);
      setDataFetched(_constantUtil.BOOLEAN_TRUE); 
    } catch (error) {
      toast.error(error.message);
    }
  };

  const confirmationRef = useRef(null);
  useEffect(() => {
    if (!dataFetched){
      fetchUserData();
    }

    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowTeams(_constantUtil.BOOLEAN_FALSE);
        setShowPlayers(_constantUtil.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [location.search, token, dataFetched]);

  return (
    <>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='row justify-content-center mx-4'>
        <div className='d-flex align-items-center justify-content-between p-0'>
          <h4 className='header-title mb-0 text-muted mt-4'>
            <Link onClick={()=>navigate(-1)} className='text-muted'>
              All User
            </Link>
            <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
            </span>{' '}
            View User
          </h4>
          <button
            type='submit'
            className='btn text-white mt-4 d-flex'
            onClick={() => navigate(-1)}
          >
            <MdKeyboardArrowLeft className=' fs-4' />
            Back
          </button>
        </div>

        <div className='row p-0 mt-4'>
         
          

          

          <div className='col-12 col-md-6 col-lg-3 ps-0 pe-md-3 pe-sm-0 pe-0'>
            <div className='card pt-3'>
              <div className='card-body d-flex justify-content-center'>
                <img
                  src={data.profile}
                  alt='Profile'
                  className='img-thumbnail blr-user-image'
                />
              </div>
              <div className='pt-1'>
                <hr />
              </div>
              <div className='d-flex flex-column align-items-center py-2 pb-4'>
                <h5>{`${data.first_name || ''} ${data.last_name || ''}`}</h5>
                <div>
                  <b>
                    <span className='text-success pointer'>
                      <FaUserCheck className='fs-4 mb-2'/>
                    </span>
                  </b>
                  <span>
                    <b style={{ fontSize: '23px', fontWeight: '600' }}> | </b>
                  </span>
                  <b>
                    <span className='text-success pointer'>
                      <MdAccountBalanceWallet className='fs-4 mb-2' />
                    </span>
                  </b>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-3 ps-0 pe-md-3 pe-sm-0 pe-0'>
            <div className='card blr-image pt-3'>
              <div className='card-body'>
                
                <div className='blr-tshirt-style text-center text-dark'>                  
                  <h4 className='view-h4'>{tshirt.name || 'John Doe'}</h4>
                  <h1 className='view-h4'>{tshirt.number || '00 '}</h1>
                </div>

                <div className='d-flex justify-content-center'>

                <img
                  src={tshirt.profile || tshirt_image}
                  alt='Profile'
                  className='view-img-thumbnail d-flex justify-content-center'
                />
                  </div>

              </div>
            </div>
          </div>

          <div className='col-12 col-md-12 col-lg-6 px-0'>
            <div className='card'>
              <div className='card-body'>
                <form>
                  <div className='row py-2'>
                    <div className='mb-2 col-12 col-md-4'>
                      <label htmlFor='BetsPlayed' className='form-label'>
                        Bets Played
                      </label>
                      <input
                        type='text'
                        className='form-control disabled-input-color'
                        id='BetsPlayed'
                        placeholder='21'
                        style={{background : "#7e7e7e38"}}
                        disabled
                      />
                    </div>

                    <div className='mb-2 col-12 col-md-4'>
                      <label htmlFor='MatchesPlayed' className='form-label'>
                        Matches Played
                      </label>
                      <input
                        type='text'
                        className='form-control disabled-input-color'
                        id='MatchesPlayed'
                        placeholder='3'
                        style={{background : "#7e7e7e38"}}
                        disabled
                      />
                    </div>

                    <div className='mb-2 col-12 col-md-4'>
                      <label htmlFor='BetsWon' className='form-label'>
                        Bets Won
                      </label>
                      <input
                        type='text'
                        className='form-control disabled-input-color'
                        id='BetsWon'
                        placeholder='14'
                        style={{background : "#7e7e7e38"}}
                        disabled
                      />
                    </div>
                  </div>
                </form>

                <div className='mt-4'>
                  <div className='table-responsive'>
                    <table className='table mt-3'>
                      <tbody className='blr-line-height-table '>
                        <tr>
                          <td className='fw-bold'>Name</td>
                          <td>{data.first_name}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>Phone</td>
                          <td>{data.phone ? (
                              <span className='badge badge-success-lighten' style={{fontSize:'13px'}}>{data.phone}</span>
                            ) : (
                              <span className='badge badge-info-lighten' style={{fontSize:'13px'}}>NA</span>
                            )}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>Email</td>
                          <td>{data.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
            </div>
          </div>

          
          <div className='card'>
            <div className='card-body'>
              <form>
                <div className='row py-2'>
                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='DOB' className='form-label'>
                      Birthdate
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='DOB'
                      placeholder={data.dob}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='Gender' className='form-label'>
                      Gender
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='Gender'
                      placeholder={data.gender === 1 ? 'Male' : '' || data.gender === 2 ? 'Female' : '' }
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='Username' className='form-label'>
                      Username
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='Username'
                      placeholder={data.username}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='Password' className='form-label'>
                      Password
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='Password'
                      placeholder={data.password}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='Address' className='form-label'>
                      Address
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='Address'
                      placeholder={data.address}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='City' className='form-label'>
                      City
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='City'
                      placeholder={data.city}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='Country' className='form-label'>
                      Country
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='Country'
                      placeholder={data.country}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='PostalCode' className='form-label'>
                      Postal Code
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='PostalCode'
                      placeholder={data.postcode}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='State' className='form-label'>
                      State
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='State'
                      placeholder={data.state}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              <form>
                <div className='row py-2 '>
                  <div className='mb-2 col-12 col-md-4 '>
                    <label htmlFor='Username' className='form-label'>
                       Balance
                    </label>
                    <input
                      type='text'
                      className='form-control disabled-input-color'
                      id='total balance'
                      placeholder={data.balance}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='inputPassword4' className='form-label'>
                      Winning
                    </label>
                    <input
                      type='password'
                      className='form-control disabled-input-color'
                      id='Winning'
                      placeholder={data.winning}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>

                  <div className='mb-2 col-12 col-md-4'>
                    <label htmlFor='inputEmail4' className='form-label'>
                      Total Winning
                    </label>
                    <input
                      type='email'
                      className='form-control disabled-input-color'
                      id='Total Winning'
                      placeholder={data.total_winning}
                      style={{background : "#7e7e7e38"}}
                      disabled
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='col-12 col-md-6 ps-0  '>
            <h4 className='header-title text-muted mb-3'>
              <Link className='text-muted'>
                Favorite Teams <span>( {team.length} )</span>
              </Link>
            </h4>
            <div className='card'>
              <div className='card-body d-flex align-items-center flex-wrap'>
                {team.slice(0, 5).map((item) => (
                  <img
                    src={item.profile}
                    alt=''
                    key={item._id}
                    className='team-player-img'
                    style={{
                      width: '10vw',
                      height: '10vw',
                      maxWidth: '50px',
                      maxHeight: '50px',
                      margin: '5px 15px',
                      padding: '4px',
                      borderRadius: '50%',
                      // background: '#1f1f31',
                      border: '1px solid #8080808a',
                    }}
                  />
                ))}
                {team.length >= 1 ?
                  <div
                    className='fs-5 view-all-link'
                    onClick={() => setShowTeams(_constantUtil.BOOLEAN_TRUE)}
                    style={{
                      width: 'auto',
                      margin: 'auto 15px',
                      cursor: 'pointer',
                    }}
                  >
                  <div className="d-flex justify-content-end">
                    <p className='mb-0 blr-text-dull-sky'>View All</p>
                  </div>
                  </div> : <div>No Teams</div>
                }
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 mb-3 px-0'>
            <h4 className='header-title text-muted mb-3'>
              <Link className='text-muted'>
                Favorite Players <span>( {players.length} )</span>
              </Link>
            </h4>
            <div className="card">
            <div className='d-flex'>
              <div className='card-body d-flex flex-wrap'>
                {players.slice(0, 5).map((item) => (
                    <img
                    src={item.profile}
                    alt=''
                    key={item._id}
                    className='team-player-img'
                    style={{
                      width: '10vw',
                      height: '10vw',
                      maxWidth: '50px',
                      maxHeight: '50px',
                      margin: '5px 15px',
                      padding: '4px',
                      borderRadius: '50%',
                      // background: '#1f1f31',
                      border: '1px solid #8080808a',
                    }}
                  />
                ))}
                {players.length >= 1 ?
                    <div className='view-all-link'
                      onClick={() => setShowPlayers(_constantUtil.BOOLEAN_TRUE)}
                      style={{
                        width: 'auto',
                        margin: 'auto 15px',
                        cursor: 'pointer',
                      }}
                    >
                  <div className="d-flex justify-content-end">
                    <p className='mb-0 blr-text-dull-sky'>View All</p>
                  </div>
                    </div>
                     : 
                     <div className='text-left'>No Players</div>
                  }
              </div>
            </div>
            </div>
          </div>        
          {showTeams && (
            <div>
              <div className='user-confirmation-overlay'></div>
              <div ref={confirmationRef} className={`${storedThem === 'dark' ? 'player-confirmation-dialog' : 'user-confirmation-dialog'}`}>
                <h4 className='text-muted mb-4'>Favorite Teams</h4>
                <hr className='my-3'/>
                <div className='d-flex flex-wrap fav-player-view'>
                  {team.map((team, index) => (
                    <React.Fragment key={team._id}>
                      {/* Check if it's the 6th item to start a new row */}
                      {index > 0 && index % 6 === 0 && <div className='w-100'></div>}
                      <div className='team-card blr-fav-player mb-3'>
                        <img src={team.profile} alt={team.name} className='team-image' />
                        <p className='team-name'>{team.name.slice(0,20) + (team.name.length > 20 ? '...' : '' )}</p>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className='d-flex justify-content-end'>
                  <button className='btn text-white mt-3' onClick={() => setShowTeams(false)}>Close</button>
                </div>
              </div>
            </div>
          )}
          {showPlayers && (
            <>
              <div className="user-confirmation-overlay"></div>
              <div ref={confirmationRef} className={`${storedThem === 'dark' ? 'player-confirmation-dialog' : 'user-confirmation-dialog'}`}>
                <h4 className="text-muted mb-0">Favorite Players</h4>
                <hr className='my-3'/>
                <div className="row fav-player-view">
                  {players.map((player) => (
                    <div key={player._id} className="col-6 col-md-5 col-lg-2 team-card">
                      <div className="blr-fav-player mb-3">
                        <img src={player.profile} alt={player.name} className="team-image" />
                        <p className="team-name">{player.first_name.slice(0,20) + (player.first_name.length > 20 ? '...' : '' )}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button
                  className='btn text-white mt-3'
                  onClick={() => setShowPlayers(false)}>Close</button>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
    </>
  );
}

export default ViewUser;
