import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdCreate, MdBlock, MdAdd, MdOutlineCircle } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../../utils/Constant'
import config from '../../../config/config';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import Loader from '../../partials/Loader';

function GlobalBets() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
    const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
    const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
    const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
    const [questionID, setQuestionID] = useState();
    const [questionStatus, setQuestionStatus] = useState(_constantUtil.BOOLEAN_TRUE);
    const [currentPage, setCurrentPage] = useState(_constantUtil.ZERO);
    const rowsPerPage = 20;

    const confirmationRef = useRef(_constantUtil.NULL);

    const allBets = async () => {
        try {
            const response = await axios.get(
                `${config.appBaseUrl + _constantUtil.ALL_QUETION_END_POINT}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const records = response.data.data;
            setData(records);
            setTotalPages(Math.ceil(records.length / rowsPerPage));
            setPaginatedData(records.slice(0, rowsPerPage));
        } catch (error) {
            toast.error(error);
        }
    };

    useEffect(() => {
        allBets();
    }, []);

    const handlePageChange = ({ selected }) => {
        const start = selected * rowsPerPage;
        const end = start + rowsPerPage;
        setPaginatedData(data.slice(start, end));
        setCurrentPage(selected);
    };

    const visibleQuestion = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);

        try {
            const response = await axios.patch(
                `${config.appBaseUrl + _constantUtil.VISIBLE_QUESTION_END_POINT}?question_id=${questionID}&status=${questionStatus}`,
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            const result = response.data;

            if (result.status) {
                const updatedData = data.map(bets => {
                    if (bets._id === questionID) {
                        return { ...bets, status: questionStatus };
                    }
                    return bets;
                });
                setData(updatedData);
                setPaginatedData(updatedData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage));
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
    };

    function showConfirmationModel(params) {
        const { id, status } = params;
        setQuestionID(id);
        setQuestionStatus(!status);
        setShowConfirmation(_constantUtil.BOOLEAN_TRUE);
    }

    return (
        <>
            <ToastContainer autoClose={2000} position='top-center' pauseOnHover={false} />
            {showConfirmation && (
                <div>
                    <div className='confirmation-overlay'></div>
                    <div ref={confirmationRef} className='confirmation-dialog'>
                        <p>Are you sure to update question status?</p>
                        <div className='d-flex justify-content-end'>
                            <button onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                            <button onClick={() => visibleQuestion()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton}>{!loading ? 'Confirm' : <Loader />}</button>
                        </div>
                    </div>
                </div>
            )}
            <div className='px-4 mt-4'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <h4 className='header-title pb-3 text-muted'>Global Bets</h4>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>

                                    <div className='d-flex justify-content-end align-items-center mb-2'>
                                        <div className=''>
                                            <button
                                                type='submit'
                                                onClick={() => navigate('/bets/add-bets')}
                                                className='btn text-white d-flex'>
                                                <MdAdd className='fs-4 me-1 mb-1 ' />
                                                Add
                                            </button>
                                        </div>
                                    </div>

                                    <div className='table-responsive'>
                                        <table id='scroll-horizontal-datatable' className='table w-100 nowrap'>
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Category</th>
                                                    <th>Question</th>
                                                    <th className="text-nowrap">Option One</th>
                                                    <th className="text-nowrap">Option Two</th>
                                                    <th>Result</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map((bets, index) => (
                                                    <tr key={bets._id}>
                                                        <th scope='row'>{(currentPage * rowsPerPage) + (index + 1)} </th>
                                                        <td>
                                                            <span className='badge badge-info-lighten'>
                                                                {bets.name}
                                                            </span>
                                                        </td>
                                                        <td  className="text-nowrap">{bets.question.slice(0, 25) + (bets.question.length > 25 ? '...' : '' )}</td>
                                                        <td>{bets.options[0].text}</td>
                                                        <td>{bets.options[1].text}</td>
                                                        <td>
                                                            <span className='badge badge-warning-lighten'>
                                                                {bets.result_str}
                                                            </span>
                                                        </td>
                                                        <td>{bets.status ? (
                                                            <span className='badge badge-success-lighten'>
                                                                ACTIVE
                                                            </span>
                                                        ) : (
                                                            <span className='badge badge-danger-lighten'>
                                                                INACTIVE
                                                            </span>
                                                        )}
                                                        </td>
                                                        <td>
                                                            <div className='d-flex align-items-center justify-content-center'>
                                                                <MdCreate data-tooltip-id='globalbets' data-tooltip-content="Update Bets"
                                                                    className=' blr-icon  me-2 fs-5' style={{cursor: 'pointer', color:'gray'}}
                                                                    onClick={() => navigate('/bets/update-bets', {
                                                                        state: {
                                                                            Bid: bets._id,
                                                                            Bname: bets.name,
                                                                            Bimage: bets.profile,
                                                                            Bquestion: bets.question,
                                                                            BbetbalrID: bets.betbalr_id,
                                                                            BtypeID: bets.type_id,
                                                                            BperiodID: bets.period_id,
                                                                            Bevent: bets.event,
                                                                            Bsubtitle: bets.subtitle,
                                                                            Bpriority: bets.priority,
                                                                            Btype: bets.type,
                                                                            Blabel: bets.label,
                                                                            BdisplayType: bets.display,
                                                                            Bdisplay: bets.display_str,
                                                                            BresultType: bets.result,
                                                                            Bresult: bets.result_str,
                                                                            BoptionA: bets.options[0].text,
                                                                            BoptionB: bets.options[1].text,
                                                                            BexpireMin: bets.expire_minute,
                                                                        }
                                                                    })}
                                                                />
                                                                <Tooltip border={false} id='globalbets' place="bottom" effect="solid" />

                                                                {bets.status ?
                                                                    <>
                                                                        <MdBlock data-tooltip-id='activeglobalbets' data-tooltip-content="Inactive Bets"
                                                                            className='blr-icon me-2 pointer user-status fs-5' style={{ cursor: 'pointer', color: 'red' }}
                                                                            onClick={() => showConfirmationModel({ id: bets._id, status: bets.status })} />
                                                                        <Tooltip border={false} id='activeglobalbets' place="bottom" effect="solid" />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <MdOutlineCircle
                                                                            data-tooltip-id='inactivebetsglobal' data-tooltip-content="Active Bets"
                                                                            className='blr-icon pointer user-status fs-5 me-2' style={{ cursor: 'pointer', color: 'green' }}
                                                                            onClick={() => showConfirmationModel({ id: bets._id, status: bets.status })}
                                                                        />
                                                                        <Tooltip id='inactivebetsglobal' place="bottom" effect="solid" />
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        previousLabel={<MdKeyboardArrowLeft className='fs-4' />}
                                        nextLabel={<MdKeyboardArrowRight className='fs-4' />}
                                        className='pagination'
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'pagination-active-page'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GlobalBets;
