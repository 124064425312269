import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

function ViewNews() {
  const location = useLocation();
  const navigate = useNavigate()
  const matchData = location.state;

    const file = matchData?.mfile ?? '';
    const profile = matchData?.mprofile ?? '';
    const title = matchData?.mtitle ?? '';
    const description = matchData?.mdesc ?? '';
    const matchname = matchData?.mname ?? '';
    const marticle = matchData?.mdescription ?? '';

  return (
    <>
      <div className="mx-4">
        <div className='d-flex align-items-center justify-content-between p-0'>
          <h4 className="header-title mb-0 text-muted mt-4">
            <Link onClick={() => navigate(-1)} className="text-muted">
              All News
            </Link>
            <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 ' />
            </span>
            View News
          </h4>
          <button
            type='button'
            className='btn text-white mt-4 d-flex'
            onClick={() => navigate(-1)}>
            <MdKeyboardArrowLeft className='fs-4' />
            Back
          </button>
        </div>
        <div className="my-4">
          <div className="shadow py-2 px-4 rounded" style={{ border: '1px solid var(--bs-border-color)' }}>
            <div className="d-flex d-flex-remove">
              <div className="py-2 pe-lg-4">
                {file === 1 && (
                  <>
                    <h4 className='border-bottom pb-3'>Image :</h4>
                    <img
                      src={profile}
                      alt="Profile"
                      className="rounded img-fluid my-2 me-2 blr-news-view"
                    />
                  </>
                )}
                {file === 2 && (
                  <>
                    <h4 className='border-bottom pb-3'>Video :</h4>
                    <video
                      alt="demo-video"
                      className="rounded my-2 me-2 blr-news-view"
                      controls>
                      <source src={profile} type="video/mp4" />
                    </video>
                  </>
                )}
              </div>
              <div className='mt-2'>
                <h4 className='border-bottom pb-3'>Details :</h4>
                <h4 className='border-bottom pb-3'>{title}</h4>
                <p className='my-3 border-bottom pb-3'>{description}</p>
                {marticle && <p className='my-3 border-bottom pb-0' dangerouslySetInnerHTML={{ __html: marticle }}></p>}
                <p className='align-items-end border-bottom pb-3'>
                  <span className='fw-bold'>Match Name : </span>
                  <span>{matchname}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewNews;
    