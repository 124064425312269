import React from 'react';
import { Route, Routes } from "react-router-dom";
import AllUser from '../user/AllUser';
import ViewUser from '../user/ViewUser';

function UserNestRoutes() {
  return (
    <Routes>
      <Route path="/all-user" element={<AllUser />} />
      <Route path="/view-user/" element={<ViewUser />} />
    </Routes>
  );
}

export default UserNestRoutes;
