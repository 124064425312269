import { useState, useRef, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import _constantUtil from "../../utils/Constant";
import config from "../../config/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowLeft,
} from "react-icons/md";
import axios from "axios";

function AddNews() {
  const navigate = useNavigate()
  const token = localStorage.getItem("token");
  const location = useLocation();
  const matchData = location.state;
  const mid = matchData?.match ?? [];

  const [profileImage, setProfileImage] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [wordCount, setWordCount] = useState(_constantUtil.ZERO);
  const [selectedBanner, setSelectedBanner] = useState(_constantUtil.ZERO);
  const [videoError, setVideoError] = useState("");
  
  const [videoURL, setVideoURL] = useState("");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [matchID, setMatchID] = useState(mid?.match_id ?? "");
  const [action, setAction] = useState(_constantUtil.NULL);
  const [errors, setErrors] = useState({});

  const imgError = '';

  const handlePaste = (e) => {
    e.preventDefault(); 
    const text = e.clipboardData.getData('text/plain');  
    document.execCommand('insertText', false, text);  
};

const editorConfig = useMemo(() => ({
  readonly: false,
  toolbarAdaptive: false, 
  events: {
      afterPaste: handlePaste 
  },
  disablePlugins: ['pasteHTML']
}), []);

  useEffect(() => {
    if (matchData && matchData.match_id) {
      setMatchID(matchData.match_id);
    }
  }, [matchData]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerChange = (type) => {
    setSelectedBanner((prevType) => (prevType === type ? 0 : type));
    setProfileImage("");
    setVideoError("");
    setVideoURL("");
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 50 * 1024 * 1024; // 50MB max size

    if (file && file.type.startsWith("video/")) {
      if (file.size <= maxSize) {
        setVideoURL(URL.createObjectURL(file));
        setVideoError(""); 
      } else {
        setVideoError("File size exceeds the maximum limit of 50MB.");
        setVideoURL("");
      }
    } else {
      setVideoError("Invalid file type. Please upload a video file.");
      setVideoURL("");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "shortDescription") {
      const words = value.split(/\s+/).filter((word) => word.length > 0);
      if (words.length <= 120) {
        setShortDescription(value);
        setWordCount(words.length);
      }
    } else if (name === "title") {
      setTitle(value);
    } else if (name === "matchID") {
      setMatchID(value);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const newErrors = {};
    if (!matchID) {
      newErrors.matchID = "Match Name is required";
    }
    if (!title) {
      newErrors.title = "Title is required";
    }
    if (!shortDescription) {
      newErrors.shortDescription = "Short Description is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();
    const newsImage = document.getElementById("news-upload");
    const newsVideo = document.getElementById("video-upload");

    if (selectedBanner === 1 && newsImage.files[0]) {
      formData.append("news_profile", newsImage.files[0]);
    } else if (selectedBanner === 2 && newsVideo.files[0]) {
      formData.append("news_profile", newsVideo.files[0]);
    }

    formData.append("upload_type", selectedBanner);
    formData.append("title", title);
    formData.append("message", shortDescription);
    formData.append("description", content);
    formData.append("match_id", matchID);
    formData.append("is_publish", action);

    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.UPDATE_NEWS_END_POINT}`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data;
      if (result.status) {
        toast.success("Success");
        setMatchID("");
        setTitle("");
        setShortDescription("");
        setProfileImage("");
        setVideoURL("");
        setContent("");
        setSelectedBanner(0);
      } else {
        toast.error("Failed to update news");
      }
    } catch (error) {
      toast.error("Error occurred during submission");
    }
  };

  return (
    <>
      <div className="">
        <ToastContainer autoClose={2000} position="top-center" />
        <div className="row mx-4">
          <div className="d-flex align-items-center justify-content-between p-0">
            <h4 className="header-title mb-0 text-muted mt-4">
              <Link onClick={() => navigate(-1)} className="text-muted">
                All News
              </Link>
              <span className="blr-player-view">
                <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2 " />
              </span>{" "}
              Add News
            </h4>
            <button
              type="button"
              className="btn text-white float-right mt-4 d-flex"
              onClick={() => navigate(-1)}
            >
              <MdKeyboardArrowLeft className="fs-4" />
              Back
            </button>
          </div>

          <div className="card mt-4">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Match Name :
                      </label>
                      <select
                        className="form-select mb-2"
                        name="matchID"
                        onChange={handleInputChange}
                        value={matchID}
                      >
                        <option value="">Select from match</option>
                        {mid.map((list, index) => (
                          <option key={index} value={list.match_id}>
                            {list.short_title}
                          </option>
                        ))}
                      </select>
                      {errors.matchID && (
                        <div className="text-danger">{errors.matchID}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Title :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={title}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      {errors.title && (
                        <div className="text-danger mt-1">{errors.title}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Message :
                      </label>
                      <textarea
                        value={shortDescription}
                        name="shortDescription"
                        onChange={handleInputChange}
                        style={{
                          background: "transparent",
                          maxHeight: "320px",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                        }}
                        rows="3"
                        className="form-control"
                      />
                      <span className="float-end mt-1">
                        ( {wordCount} / 120 words )
                      </span>
                      {errors.shortDescription && (
                        <div className="text-danger mt-1">
                          {errors.shortDescription}
                        </div>
                      )}
                    </div>

                    <div className="mb-3 pt-2">
                      <div className="flex-column">
                        <div className="d-flex">
                          <label className="form-label">Upload Media :</label>

                          <div className="form-check mb-2 blr-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="imageCheckbox"
                              checked={selectedBanner === 1}
                              onChange={() => handleBannerChange(1)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="imageCheckbox"
                            >
                              Image
                            </label>
                          </div>

                          <div className="form-check mb-2 blr-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="videoCheckbox"
                              checked={selectedBanner === 2}
                              onChange={() => handleBannerChange(2)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="videoCheckbox"
                            >
                              Video
                            </label>
                          </div>
                        </div>
                        <div className="pt-2">
                          {selectedBanner === 1 && (
                            <>
                              <div className="mb-3">
                                <input
                                  type="file"
                                  accept="image/jpeg, image/png"
                                  onChange={handleImageChange}
                                  className="form-control"
                                  name="upload"
                                  id="news-upload"
                                />
                                {imgError && (
                                  <div className="text-danger mt-2">
                                    {imgError}
                                  </div>
                                )}
                              </div>
                              {profileImage && (
                                <img
                                  src={profileImage}
                                  alt="Profile"
                                  className="img-thumbnail img-fluid my-2 me-2 news-image"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                    minHeight: "200px",
                                    height: "200px",
                                  }}
                                />
                              )}
                            </>
                          )}
                          {selectedBanner === 2 && (
                            <>
                              <div className="mb-3">
                                <input
                                  type="file"
                                  accept="video/*"
                                  onChange={handleVideoChange}
                                  className="form-control"
                                  name="videoUpload"
                                  id="video-upload"
                                />
                                {videoError && (
                                  <div className="text-danger">
                                    {videoError}
                                  </div>
                                )}
                              </div>
                              {videoURL && (
                                <div className="mt-3">
                                  <video className="news-image" controls>
                                    <source src={videoURL} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="article" className="form-label">
                        Description :
                      </label>
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={editorConfig}
                        tabIndex={1}
                        onBlur={(newContent) => setContent(newContent)}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary float-end"
                  onClick={() => setAction("false")}
                >
                  Save
                </button>
                <button
                  type="submit"
                  className="btn btn-primary float-end me-3"
                  onClick={() => setAction("true")}
                >
                  Save & Publish
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNews;
