import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState('light');

  useEffect(() => {
    const storedThemeMode = sessionStorage.getItem('themeMode');
    if (storedThemeMode) {
      setThemeMode(storedThemeMode);
    } else {
      setThemeMode('light');
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', themeMode);
  }, [themeMode]);

  const toggleTheme = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newThemeMode);
    sessionStorage.setItem('themeMode', newThemeMode);
  };

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
