import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import DashboardNestRoutes from '../nested/DashboardRoutes';
import UserNestRoutes from '../nested/UserRoutes';
import TeamNestRoutes from '../nested/TeamRoutes';
import PlayerNestRoutes from '../nested/PlayerRoutes';
import TshirtNestRoutes from '../nested/TshirtRoutes';
import { useLocation } from 'react-router-dom';
import _constantUtil from '../../utils/Constant';
import MatchRoutes from '../nested/MatchRoutes';
import BetsRoutes from '../nested/BetsRoutes';
import NewsRoutes from '../nested/NewsRoutes';

function Appmain() {
  const buttonRef = useRef(null);
  const htmlRef = useRef(document.documentElement);
  const [isBackdropVisible, setIsBackdropVisible] = useState(_constantUtil.BOOLEAN_FALSE);
  const [isSidebarVisible, setIsSidebarVisible] = useState(_constantUtil.BOOLEAN_FALSE);
  const location = useLocation();

  useEffect(() => {
    const handleButtonClick = () => {
      htmlRef.current.classList.toggle('sidebar-enable');
      setIsBackdropVisible(!isBackdropVisible);
    };
   
    const handleClickOutside = (event) => {
      if (
        window.innerWidth <= 1200 &&
        !buttonRef.current.contains(event.target) &&
        !htmlRef.current.classList.contains('main-menu') &&
        !event.target.closest('.main-menu')
      ) {
        htmlRef.current.classList.remove('sidebar-enable');
        setIsBackdropVisible(_constantUtil.BOOLEAN_FALSE);
      }
    };

    const buttonElement = buttonRef.current;
    if (buttonElement) {
      buttonElement.addEventListener('click', handleButtonClick);
    }
    
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      if (buttonElement) {
        buttonElement.removeEventListener('click', handleButtonClick);
      }
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isBackdropVisible]);
  
  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setIsSidebarVisible(_constantUtil.BOOLEAN_FALSE);
      htmlRef.current.classList.remove('sidebar-enable');
    }
    document.removeEventListener('click' , setIsSidebarVisible(_constantUtil.BOOLEAN_FALSE))
  }, [location.pathname]);

  useEffect(() => {
    const outClick = (event) => {
      if (isSidebarVisible && !htmlRef.current.contains(event.target)) {
        setIsSidebarVisible(_constantUtil.BOOLEAN_FALSE);
        htmlRef.current.classList.remove('sidebar-enable');
      }
    };
  
    document.addEventListener('click', outClick);
  
    return () => {
      document.removeEventListener('click', outClick);
    };
  }, [isSidebarVisible]);

  return (
    <>
      <div className='layout-wrapper'>
        <Sidebar isSidebarVisible={isSidebarVisible}/>
        <div className='page-content'>
          <Header buttonRef={buttonRef} />
          <DashboardNestRoutes />
          <UserNestRoutes />
          <TeamNestRoutes />
          <PlayerNestRoutes />
          <TshirtNestRoutes />
          <MatchRoutes/>
          <BetsRoutes/>
          <NewsRoutes/>
          <Footer />
        </div>
      </div>
      {isBackdropVisible &&  window.innerWidth < 1200 && (
        <div id='custom-backdrop' className='offcanvas-backdrop fade show'></div>
      )}
    </>
  );
}

export default Appmain;
