import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import config from '../../config/config';
import _constantUtil from '../../utils/Constant'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

function ViewPlayer() {
  const navigate = useNavigate()
  const token = localStorage.getItem("token");
  const location = useLocation();
  const playerData = location.state;
  const playerID = playerData?.player_ID;

  const [data, setData] = useState({
    profile: '',
    first_name: '',
    last_name: '',
    birth_country: '',
    gender: '',
    birthdate: '',
    nationality: '',
    height: '',
    weight: '',
    foot: '',
    position: ''
  });

  const viewPlayer = async () => {
    try {

      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.VIEW_PLAYER_END_POINT}?player_id=${playerID}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data;
      setData(result.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    viewPlayer();
  }, []);

  return (
    <>
      <ToastContainer autoClose={2000} position="top-center" />
      <div className="">
        <div className="row justify-content-center mx-4">
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className="header-title mb-0 text-muted mt-4">
              <Link onClick={() => navigate(-1)} className="text-muted">
                All Player 
              </Link>
              <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
              </span>{" "}
              View Player
            </h4>
            <button
              type='button'
              className='btn text-white mt-4 d-flex'
              onClick={() => navigate(-1)}
            >
            <MdKeyboardArrowLeft className=' fs-4' />
            Back
            </button>
          </div>
          <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-center mb-4 px-1 mt-4">
            <div className="card w-100">
              <div className="card-body d-flex flex-column align-items-center justify-content-between p-0">
                <div className="img-view d-flex align-items-center justify-content-center  py-2">
                <img
                  src={data?.profile}
                  alt="Profile"
                  className="img-thumbnail img-fluid"
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    objectFit: "cover",
                    minHeight: "170px",
                    height: "170px",
                    maxWidth: "170px",
                    width: "170px",
                  }}
                />
                </div>
                <span className="blr-team-name w-100">
                  <h5 className="my-4 text-center">{data?.first_name + " " + data?.last_name}</h5>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9 mt-4">
            <div className="row">
              <div className="col-12 col-md-6 mb-4 col-lg-6 px-1">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="mb-2">
                        <label htmlFor="country_birth" className="form-label">
                          Country of Birth
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="country_birth"
                          name="country_birth"
                          placeholder={data?.birth_country}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="gender" className="form-label">
                          Gender
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="gender"
                          name="gender"
                          placeholder={data?.gender}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="dob" className="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="dob"
                          name="dob"
                          placeholder={data?.birthdate}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="nationality" className="form-label">
                          Nationality
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="nationality"
                          name="nationality"
                          placeholder={data?.nationality}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4 col-lg-6 px-1">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="mb-2">
                        <label htmlFor="height" className="form-label">
                          Height
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="height"
                          name="height"
                          placeholder={data?.height}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="weight" className="form-label">
                          Weight
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="weight"
                          name="weight"
                          placeholder={data?.weight}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="foot" className="form-label">
                          Foot
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="foot"
                          name="foot"
                          placeholder={data?.foot}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="position" className="form-label">
                          Position
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="position"
                          name="position"
                          placeholder={data?.position}
                          style={{background:"#7e7e7e38"}}
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPlayer;
