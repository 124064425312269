import axios from 'axios';
import React, { useState } from 'react';
import profile from '../../assets/images/t-shirt-1.png';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../utils/Constant';
import config from '../../config/config';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

function AddTshirt() {
  const navigate  = useNavigate()
  const token = localStorage.getItem('token');

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [priority, setPriority] = useState(_constantUtil.ZERO);
  const [errors, setErrors] = useState({});
  const [profileImage, setProfileImage] = useState(profile);
  const [imgError, setImgError] = useState(_constantUtil.NULL);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);

  const handleImageChange = (event) => { 
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImage(event.target.result);
    };
    reader.readAsDataURL(file);

    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; 
    if (file.size > maxSize) {
      setImgError('File size maximum 2 MB.');
      return;
    }

    if (!validTypes.includes(file.type)) {
      setImgError('Only PNG and JPG files are allowed.');
      return;
    }

    const img = new Image();
    img.onload = () => {
      if (img.width !== 581 || img.height !== 707) {
        setImgError('Image must be exactly 581x707 pixels.');
      }
    };

    if (validTypes && file.size && (img.width !== 581 || img.height !== 707)){
      setImgError('')
    }

    img.src = URL.createObjectURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    switch (name) {
      case 'name':
        if (value.length <= 8) {
          setName(value);
        }
        break;
      case 'number':
        if (/^[1-9]\d?$/.test(value) || value === '') {
          setNumber(value);
        }
        break;
      default:
        break;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

 const numberFormatNoCurrency = (num) => {
    return num.toString(); 
  };

  const handlePriorityInputChange = (event) => {
    let inputPrice = event.target.value;
    inputPrice = inputPrice.replace(/\D/g, '');
    inputPrice = inputPrice.slice(0, 2); 
    const parsedPrice = parseInt(inputPrice) || 0; 
    const formatted = numberFormatNoCurrency(parsedPrice);
    setPriority(formatted); 
  };

  const validateInputs = () => {
    const newErrors = {};
    let isValid = true;
  
    if (!name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }
  
    if (!number) {
      newErrors.number = 'Number is required';
      isValid = false;
    } else if (number < 1 || number > 99) {
      newErrors.number = 'Number should be between 1 and 99';
      isValid = false;
    }
  
    if (priority <= 0) {
      newErrors.priority = 'Priority must be a positive number';
      isValid = false;
    }
  
    if (profileImage === profile) {
      setImgError('T-shirt image is required');
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const addTshirt = async (event) => {
    event.preventDefault();
  
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (imgError) {
      toast.error('Please fix the image error before submitting.');
      return;
    }

    setDisableButton(true)
  
    const data = new FormData();
    const tshirt = document.getElementById('upload');
    data.append('tshirt', tshirt.files[0]);
    data.append('name', name);
    data.append('number', number);
    data.append('priority', priority);
  
    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.SAVE_TSHIRT_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      const result = response.data;
      if (result.status) {
        toast.success(result.message);
        setName('');
        setNumber('');
        setPriority(_constantUtil.ZERO);
        setProfileImage(profile);
        setDisableButton(false)
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Error occurred while adding T-shirt.');
    }
    setDisableButton(false)
  };
  
  return (
    <>
      <div className=''>
        <ToastContainer autoClose={2000} position='top-center' />
        <div className='row justify-content-center mx-4'>
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className='header-title mb-0 text-muted mt-4'>
              <Link onClick={() => navigate(-1)} className='text-muted'>
                All Tshirt
              </Link>
              <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
              </span>{' '}
              Add Tshirt
            </h4>
            <button
              type='button'
              className='btn text-white float-right mt-4 d-flex'
              style={{ float: 'right' }}
              onClick={() => navigate(-1)}
            >
            <MdKeyboardArrowLeft className=' fs-4' />
              Back
            </button>
          </div>
          <div className='card mt-4'>
            <div className='card-body'>
              <div className='row p-0'>
                <div className='col-12 px-0'>
                  <form onSubmit={addTshirt}>
                    <div className='row py-2 blr-reverse'>
                      <div className="col-lg-8 col-md-7 col-12 mb-3">
                        <div className='mb-2 col-12'>
                          <label htmlFor='name' className='form-label'>
                            Name
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='name'
                            name='name'
                            placeholder='e.g. Ronaldo'
                            value={name}
                            onChange={handleInputChange}
                            autoComplete='off'
                          />
                          {errors.name && <div className='text-danger'>{errors.name}</div>}
                        </div>
  
                        <div className='mb-2 col-12'>
                          <label htmlFor='number' className='form-label'>
                            Number ( 0 not allowed )
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            id='number'
                            name='number'
                            placeholder='e.g. 10'
                            value={number}
                            onChange={handleInputChange}
                            autoComplete='off'
                          />
                          {errors.number && <div className='text-danger'>{errors.number}</div>}
                        </div>
  
                        <div className='mb-2 col-12'>
                          <label htmlFor='priority' className='form-label'>
                            Priority ( 0 not allowed )
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            id='priority'
                            name='priority'
                            placeholder='e.g., 1'
                            value={priority}
                            onChange={handlePriorityInputChange}
                            autoComplete='off'
                          />
                          {errors.priority && <div className='text-danger'>{errors.priority}</div>}
                        </div>
                      </div>
  
                      <div className="col-lg-4 col-md-5 col-12 text-center mb-3">
                        <div className='img-view d-flex  justify-content-center'>
                          <img
                            src={profileImage}
                            alt='Profile'
                            className='img-thumbnail img-fluid '
                            style={{
                              borderRadius: '50%',
                              cursor: 'pointer',
                              objectFit: 'contain',
                              minHeight: '130px',
                              height: '130px',
                              maxWidth: '130px',
                              width: '130px',
                            }}
                          />
                        </div>
                        <p>Image Supported files : png, jpg. Max 2 mb allowed   <br /> Recommended size: 581 x 707px. </p>
                        <div className='button-wrapper p2-4'>
                          <span className='label blr-bg-dull-sky rounded'><p className='mb-0 px-1'>Upload File</p></span>
                          <div className='d-flex justify-content-center'>
                          <input
                            type='file'
                            accept="image/jpeg, image/png"
                            onChange={handleImageChange}
                            className='upload-box form-control'
                            name='upload file'
                            id='upload'
                            placeholder='Upload File'
                          /> 
                          </div>
                        </div>
                        {imgError && <div className='text-danger mt-2'>{imgError}</div>}
                      </div>
                    </div>
                    <button type='submit' className='btn text-white' disabled={disableButton}>
                      Save
                    </button>
                    {errors.form && <div className='text-danger mt-2'>{errors.form}</div>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTshirt;
