const logLevel = {
    ERROR: 'error',
    INFO: 'info',
    DEBUG: 'debug'
};

const log = (level, message, additionalInfo = {}) => {
    const logEntry = {
        timestamp: new Date().toISOString(),
        level,
        message,
        ...additionalInfo,
    };

    console[level](logEntry);

};

export const Logger = {
    error: (message, additionalInfo) => log(logLevel.ERROR, message, additionalInfo),
    info: (message, additionalInfo) => log(logLevel.INFO, message, additionalInfo),
    debug: (message, additionalInfo) => log(logLevel.DEBUG, message, additionalInfo),
};
