import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config/config';
import _constantUtil from '../../../utils/Constant'
import axios from 'axios';

function UpdateLeague() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  const location = useLocation();
  const leagueData = location.state;
  const [leagueName, setLeagueName] = useState(leagueData?.name || '');
  const [leagueProfile, setLeagueProfile] = useState(leagueData?.profile || '');
  const [knownName, setKnownName] = useState(leagueData?.known_name || '');
  const [code, setCode] = useState(leagueData?.code || '');
  const [order, setOrder] = useState(leagueData?.order || '');
  const [format, setFormat] = useState(leagueData?.format || '');
  const [country, setCountry] = useState(leagueData?.country || '');
  const [type, setType] = useState(leagueData?.type || '');
  const [competitionType, setCompetitionType] = useState(leagueData?.competition_type || '');

  const handleImage = event => {
    const file = event.target.files[_constantUtil.ZERO];

    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        setLeagueProfile(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const updateLeague = async (event) => {
    event.preventDefault();
  
    
    const data = new FormData();
    const profile = document.getElementById('upload');
    
    data.append('competition_id', leagueData?.leagueID || '');
    data.append('profile', profile.files[_constantUtil.ZERO]);
    data.append('name', leagueName);
    data.append('known_name', knownName);
    data.append('code', code);
    data.append('order', order);
    data.append('country', country);
    data.append('format', format);
    data.append('type', type);
    data.append('competition_type', competitionType);

    try {
      const response = await axios.put(
        `${config.appBaseUrl + _constantUtil.UPDATE_LEAGUE_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      
      const result = response.data;
      (result.status)
        ? toast.success(result.message) 
        : toast.error(result.message);
    } 
    catch (error) {
      toast.error('League not update');
    }
  };

  return (
    <div className=''>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='row justify-content-center mx-4'>
        <div className='d-flex align-items-center justify-content-between p-0'>
          <h4 className='header-title mb-0 text-muted mt-4'>
            <Link onClick={() => navigate(-1)} className='text-muted'>
             All League
            </Link>
            <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 mx-2 '/>
            </span>
            Update League
          </h4>
          <button
            type='button'
            className='btn text-white mt-4 d-flex'
            onClick={() => navigate(-1)}
          >
          <MdKeyboardArrowLeft className=' fs-4' />
            Back
          </button>
        </div>
        <div className='card mt-4'>
          <div className='card-body'>
          <form onSubmit={updateLeague}>
            <div className='row flex-column flex-md-row p-0 d-flex align-items-center justify-content-center mb-4'>
              <div className='col-12 col-md-3 col-lg-4 d-flex flex-column align-items-center justify-content-center order-1 order-md-0 mb-3 mb-md-0'>
                <img
                  src={leagueProfile}
                  alt='Profile'
                  className='img-thumbnail img-fluid'
                  style={{
                    borderRadius: '50%',
                    cursor: 'pointer',
                    objectFit: 'contain',
                    minHeight: '170px',
                    height: '170px',
                    maxWidth: '170px',
                    width: '170px',
                  }}
                />
                <div className='button-wrapper mt-4'>
                  <span className='label blr-bg-dull-sky rounded'><p className='mb-0 px-1'>Upload File</p></span>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={handleImage}
                    className='upload-box form-control'
                    name='upload'
                    id='upload'
                    placeholder='Upload File'
                  />
                </div>
              </div>
            </div>
            
              <div className='row py-2'>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='name' className='form-label'>
                   League Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='leaguename'
                    name='league_name'
                    value={leagueName}
                    onChange={(e)=>setLeagueName(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='short_name' className='form-label'>
                    Known Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='knownname'
                    name='known_name'
                    value={knownName}
                    onChange={(e)=>setKnownName(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='short_name' className='form-label'>
                    Code
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='code'
                    name='code'
                    value={code}
                    onChange={(e)=>setCode(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='short_name' className='form-label'>
                    Order
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='order'
                    name='order'
                    value={order}
                    onChange={(e)=>setOrder(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='country' className='form-label'>
                    Country
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='country'
                    name='country'
                    value={country}
                    onChange={(e)=>setCountry(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='teamType' className='form-label'>
                    Format
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='format'
                    name='format'
                    value={format}
                    onChange={(e)=>setFormat(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='type' className='form-label'>
                    Type
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='type'
                    name='type'
                    value={type}
                    onChange={(e)=>setType(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <div className='mb-2 col-12 col-md-6'>
                  <label htmlFor='type' className='form-label'>
                    League Type
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='leaguetype'
                    name='league_type'
                    value={competitionType}
                    onChange={(e)=>setCompetitionType(e.target.value)}
                    autoComplete='off'
                  />
                </div>
              </div>
              <button type='submit' className='btn text-white mt-3' style={{ float: 'right' }}>
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateLeague
