import React from 'react'
import { Route, Routes } from 'react-router-dom'
import GlobalBets from '../bet/global/GlobalBets'
import AllCategory from '../bet/category/AllCategory'
import UpdateCategory from '../bet/category/UpdateCategory'
import AddBets from '../bet/global/AddBets'
import UpdateBets from '../bet/global/UpdateBets'

function BetsRoutes() {
  return (
    <Routes>
        <Route path="/all-category" element={<AllCategory />} />
        <Route path="/update-category" element={<UpdateCategory />} />

        <Route path="/global-bets" element={<GlobalBets />} />
        <Route path="/add-bets" element={<AddBets />} />
        <Route path="/update-bets" element={<UpdateBets />} />
    </Routes>
  )
}

export default BetsRoutes
