import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllLeague from '../match/league/AllLeague'
import AllUpcoming from '../match/upcoming/AllMatch';
import AllLaunch from '../match/launch/AllMatch';
import ViewLeague from '../match/league/ViewLeague'
import UpdateLeague from '../match/league/UpdateLeague'
import UpdateMatch from '../match/upcoming/UpdateMatch'
import UpcomingAllBets from '../match/upcoming/AllBets';
import LunchAllBets from '../match/launch/AllBets';
import ViewBets from '../match/launch/ViewBets';

function MatchRoutes() {
  return (
    <Routes>
        <Route path="/all-league" element={<AllLeague />} />
        <Route path="/view-league" element={<ViewLeague />} />
        <Route path="/update-league" element={<UpdateLeague />} />

        <Route path="/all-upcoming" element={<AllUpcoming />} />
        <Route path="/all-launch" element={<AllLaunch />} />
        <Route path="/view-bets" element={<ViewBets />} />
        <Route path="/update-match" element={<UpdateMatch />} />
        <Route path="/upcoming-bets" element={<UpcomingAllBets />} />
        <Route path="/launch-bets" element={<LunchAllBets />} />
    </Routes>
  )
}

export default MatchRoutes
