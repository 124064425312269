import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import _constantUtil from '../utils/Constant';
import { ToastContainer, toast } from 'react-toastify';
import logo from '../assets/images/betbalr.png';
import Loader from '../components/partials/Loader';
import config from '../config/config'
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [otpSent, setOtpSent] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [visibility, setVisibility] = useState(_constantUtil.BOOLEAN_FALSE);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (otpSent) {
      navigate('/emailverification', { state: { email, password } });
    }
  }, [otpSent, navigate, email, password]);

  function handleFormSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setDisableButton(true);
    const data = { email, password };
    axios.post(`${config.appBaseUrl + _constantUtil.SEND_OTP_END_POINT}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      const result = res.data;
      if (result.status) {
        toast.success(result.message);
        setTimeout(() => {
          setOtpSent(true);
          setLoading(_constantUtil.BOOLEAN_FALSE);
          setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }, 2500);
      } else {
        toast.error(result.message);
        setLoading(_constantUtil.BOOLEAN_FALSE);
        setDisableButton(_constantUtil.BOOLEAN_FALSE);
      }
    }).catch(error => {
      toast.error(error.message);
      setLoading(_constantUtil.BOOLEAN_FALSE);
      setDisableButton(_constantUtil.BOOLEAN_FALSE);
    });
  }

  return (
    <>
      <div className='authenticate-bg d-flex justify-content-center align-items-center min-vh-100 p-5'>
        <ToastContainer autoClose={2000} position='top-center' />
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-4 col-md-5'>
              <div className='card'>
                <div className='card-body p-4'>
                  <div className='text-center w-75 mx-auto auth-logo mb-4'>
                    <a className='logo-dark'>
                      <span>
                        <img src={logo} alt='logo' height={45} />
                      </span>
                    </a>
                    <a className='logo-light'>
                      <span>
                        <img src={logo} alt='logo' height={45} />
                      </span>
                    </a>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className='form-group mb-3'>
                      <label className='form-label' htmlFor='emailaddress'>Email address</label>
                      <input
                        className='form-control'
                        type='email'
                        id='emailaddress'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Enter your email'
                      />
                    </div>
                    <div className='form-group mb-3'>
                      <a className='text-muted float-end'><small /></a>
                      <label className='form-label' htmlFor='password'>Password</label>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type={!visibility ? "password" : "text"}
                          required
                          id='password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder='Enter your password'
                        />
                        <span className='input-group-text' style={{cursor: 'pointer'}} onClick={() => setVisibility(!visibility)}>
                          {
                            !visibility
                            ? <MdVisibility />
                            : <MdVisibilityOff />
                          }
                        </span>
                      </div>
                    </div>
                    <div className='form-group mb-0 text-center mt-5'>
                      <button type='submit' className='text-white w-100 form-button' disabled={disableButton}>
                        {!loading ? 'Log In' : <Loader />}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
