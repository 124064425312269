import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { MdCreate, MdBlock, MdOutlineCircle, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdAdd } from 'react-icons/md';
import { FaEye } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../config/config';
import _constantUtil from '../../utils/Constant';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import Loader from '../partials/Loader';

function AllNews() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [iD, setID] = useState();
  const [matchID, setMatchID] = useState();
  const [matchDetails, setMatchDetails] = useState([]);
  const [userStatus, setUserStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [currentPage, setCurrentPage] = useState(_constantUtil.ZERO);
  const rowsPerPage = 20;

  const confirmationRef = useRef(_constantUtil.NULL);

  const fetchNews = async () => {
    setLoading(_constantUtil.BOOLEAN_TRUE);
    try {
      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.ALL_NEWS_END_POINT}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const records = response.data.data;

      setData(records.news);
      setTotalPages(Math.ceil(records.news.length / rowsPerPage));
      setMatchDetails(records.match);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(_constantUtil.BOOLEAN_FALSE);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    fetchNews();

    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [rowsPerPage, currentPage]);

  const visibleNews = async () => {
    setLoading(_constantUtil.BOOLEAN_TRUE);
    setDisableButton(_constantUtil.BOOLEAN_TRUE);
    try {
      const response = await axios.patch(
        `${config.appBaseUrl}${_constantUtil.VISIBLE_NEWS_END_POINT}?_id=${iD}&match_id=${matchID}&status=${userStatus}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const result = response.data;
      if (result.status) {
        const updatedData = data.map(news => {
          if (news._id === iD) {
            return { ...news, status: userStatus };
          }
          return news;
        });
        setData(updatedData);
        toast.success(result.message);
        fetchNews();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(_constantUtil.BOOLEAN_FALSE);
      setDisableButton(_constantUtil.BOOLEAN_FALSE);
    }
    setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };

  const showConfirmationModel = (id, status, match_ID) => {
    setID(id);
    setUserStatus(!status);
    setMatchID(match_ID);
    setShowConfirmation(_constantUtil.BOOLEAN_TRUE);
  };

  return (
    <>
      <ToastContainer autoClose={2000} position='top-center' />
      {showConfirmation && (
        <div className='confirmation-overlay'>
          <div ref={confirmationRef} className='confirmation-dialog'>
            <p>Are you sure to update news status?</p>
            <div className='d-flex justify-content-end'>
              <button
                onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)}
                className='border-0 rounded px-3 bg-danger text-white p-2'
              >
                Cancel
              </button>
              <button
                onClick={visibleNews}
                className='border-0 rounded px-3 bg-success text-white p-2'
                disabled={disableButton}
              >
                {!loading ? 'Confirm' : <Loader />}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='px-4 mt-4'>
        <div className='container-fluid p-0'>
          <div className='row'>
            <h4 className='header-title pb-3 text-muted'>All News</h4>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-end align-items-center mb-2'>
                    <button
                      type='button'
                      className='btn text-white d-flex'
                      onClick={() => navigate('/news/add-news', {
                        state: {
                          match: matchDetails
                        }
                      })}
                    >
                      <MdAdd className='fs-4 me-1' />
                      Add
                    </button>
                  </div>

                  <div className='table-responsive'>
                    <table id='scroll-horizontal-datatable' className='table w-100 nowrap'>
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th className="text-nowrap">Match Name</th>
                          <th className="text-nowrap">Date & Time</th>
                          <th>Title</th>
                          <th>Message</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage).map((news, index) => (
                          <tr key={news._id}>
                            <th scope='row'>{(currentPage * rowsPerPage) + (index + 1)}</th>
                            <td>{news.short_title}</td>
                            <td className="text-nowrap">{news.local_date} <br /> {news.local_time}</td>
                            <td className="text-nowrap">{news.title.slice(0, 20) + (news.title.length > 20 ? '...' : '' )}</td>
                            <td className="text-nowrap">{news.message.slice(0, 25) + (news.message.length > 25 ? '...' : '' )}</td>
                            <td>
                              {news.is_publish ? (
                                <span className='badge badge-success-lighten'>ACTIVE</span>
                              ) : (
                                <span className='badge badge-danger-lighten'>INACTIVE</span>
                              )}
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-center'>
                                <MdCreate
                                  data-tooltip-id='updatenews'
                                  data-tooltip-content="Update News"
                                  className='blr-icon text-muted me-2 fs-5'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate('/news/update-news', {
                                      state: {
                                        Updateid: news._id,
                                        Updatetitle: news.title,
                                        Updatemessage: news.message,
                                        Updatedescription: news.description,
                                        UpdateuploadType: news.upload_type,
                                        Updateprofile: news.url,
                                        UpdatematchId: news.match_id,
                                        UpdatematchName: news.short_title,
                                      }
                                    });
                                  }}
                                />
                                <Tooltip id='updatenews' place="bottom" effect="solid" />

                                <FaEye
                                  data-tooltip-id='viewnews'
                                  data-tooltip-content="View News"
                                  className='blr-icon me-2 fs-5 text-muted'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate('/news/view-news', {
                                      state: {
                                        mid: news._id,
                                        mtitle: news.title,
                                        mdesc: news.message,
                                        mdescription: news.description,
                                        mname: news.short_title,
                                        mfile: news.upload_type,
                                        mprofile: news.url
                                      }
                                    });
                                  }}
                                />
                                <Tooltip id='viewnews' place="bottom" effect="solid" />

                                {news.is_publish ? (
                                  <>
                                    <MdBlock
                                      data-tooltip-id='inactive'
                                      data-tooltip-content="Inactive News"
                                      className='blr-icon me-2 pointer user-status fs-5'
                                      style={{ cursor: 'pointer', color: 'red' }}
                                      onClick={() => showConfirmationModel(news._id, news.is_publish, news.match_id)}
                                    />
                                    <Tooltip id='inactive' place="bottom" effect="solid" />
                                  </>
                                ) : (
                                  <>
                                    <MdOutlineCircle
                                      data-tooltip-id='active'
                                      data-tooltip-content="Active News"
                                      className='blr-icon me-2 pointer user-status fs-5'
                                      style={{ cursor: 'pointer', color: 'green' }}
                                      onClick={() => showConfirmationModel(news._id, news.is_publish, news.match_id)}
                                    />
                                    <Tooltip id='active' place="bottom" effect="solid" />
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <ReactPaginate
                    previousLabel={<MdKeyboardArrowLeft className='fs-4' />}
                    nextLabel={<MdKeyboardArrowRight className='fs-4' />}
                    className='pagination'
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'pagination-active-page'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllNews;
