import axios from 'axios';
import FormData from 'form-data';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import profile from '../../assets/images/t-shirt-1.png';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import _constantUtil from '../../utils/Constant';
import config from '../../config/config';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

function UpdateTshirt() {
  const navigate = useNavigate()
  const location = useLocation();
  const tshirtData = location.state;
  const token = localStorage.getItem('token');

  const [name, setName] = useState(tshirtData?.name || '');
  const [number, setNumber] = useState(tshirtData?.number || '');
  const [priority, setPriority] = useState(tshirtData?.priority || _constantUtil.ZERO);
  const [errors, setErrors] = useState({});
  const [profileImage, setProfileImage] = useState(tshirtData?.profile || profile);
  const [imgError, setImgError] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImage(event.target.result);
    };
    reader.readAsDataURL(file);
  
    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; 
    if (file.size > maxSize) {
      setImgError('File size maximum 2 MB.');
      return;
    }
  
    if (!validTypes.includes(file.type)) {
      setImgError('Only PNG and JPG files are allowed.');
      return;
    }
  
    const img = new Image();
    img.onload = () => {
      if (img.width !== 581 || img.height !== 707) {
        setImgError('Image must be exactly 581x707 pixels.');
      }
    };
  
    if (validTypes && file.size && (img.width !== 581 || img.height !== 707)){
      setImgError('')
    }
  
    img.src = URL.createObjectURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    switch (name) {
      case 'name':
        if (value.length <= 8) {
          setName(value);
        }
        break;
      case 'number':
        if (/^[1-9]\d?$/.test(value)) {
          setNumber(value);
        }else if (value === '') {
          setNumber('');
        }
        break;
      case 'priority':
        if (/^\d{0,2}$/.test(value)) {
          setPriority(value);
        }
        break;
      default:
        break;
    }  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const numberFormatNoCurrency = (num) => {
    return num.toString(); 
  };
  
  const handlePriceInputChange = (event) => {
    let inputPrice = event.target.value;
    inputPrice = inputPrice.replace(/\D/g, '');
    inputPrice = inputPrice.slice(0, 2); 
    const parsedPrice = parseInt(inputPrice) || 0; 
    const formatted = numberFormatNoCurrency(parsedPrice);
    setPriority(formatted); 
  };

  const validateInputs = () => {
    const newErrors = {};
    let isValid = _constantUtil.BOOLEAN_TRUE;
  
    if (!name.trim()) {
      newErrors.name = 'Name is required';
      isValid = _constantUtil.BOOLEAN_FALSE;
    }
  
    if (!number) {
      newErrors.number = 'Number is required';
      isValid = _constantUtil.BOOLEAN_FALSE;
    } else if (number < _constantUtil.ZERO || number > 99) {
      newErrors.number = 'Number should be between 1 and 99';
      isValid = _constantUtil.BOOLEAN_FALSE;
    }
  
    if (priority <= _constantUtil.ZERO) {
      newErrors.priority = 'Priority must be a positive number';
      isValid = _constantUtil.BOOLEAN_FALSE;
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const updateTshirt = async (event) => {
    event.preventDefault();
  
    if (!validateInputs()) {
      return;
    }
  
    if (imgError) {
      toast.error('Please fix the image error before submitting.');
      return;
    }

    const data = new FormData();
    const tshirt = document.getElementById('upload');

    data.append('tshirt_id', tshirtData?.tshirt_id || ''); 
    data.append('tshirt', tshirt.files[_constantUtil.ZERO]);
    data.append('name', name);
    data.append('number', number);
    data.append('priority', priority);
  
    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.UPDATE_TSHIRT_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      const result = response.data;
      (result.status) 
        ? toast.success(result.message) 
        : toast.error(result.message);
    } 
    catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className=''>
      <ToastContainer autoClose={2000} position='top-center' />
        <div className='row justify-content-center mx-4'>
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className='header-title pb-3 text-muted mt-4'>
              <Link onClick={() => navigate(-1)} className='text-muted'>
                All Tshirt
              </Link>
              <span className="blr-player-view">
              <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
              </span>{' '}
              Update Tshirt
            </h4>
            <button
              type='button'
              className='btn text-white float-right d-flex'
              style={{ float: 'right' }}
              onClick={() => navigate(-1)}
            >
            <MdKeyboardArrowLeft className=' fs-4' />
            Back
            </button>
          </div>
          <div className='card'>
            <div className='card-body'>
              <div className='row p-0'>
                <div className='col-12 px-0'>
                  <form onSubmit={updateTshirt}>
                    <div className='row py-2 blr-reverse'>
                      <div className="col-lg-8  col-md-7 col-12 mb-3">
                      <div className='mb-2 col-12'>
                        <label htmlFor='name' className='form-label'>
                          Name
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='name'
                          name='name'
                          placeholder='ex. Ronaldo'
                          value={name}
                          onChange={handleInputChange}
                          autoComplete='off'
                        />
                        {errors.name && <div className='text-danger'>{errors.name}</div>}
                      </div>

                      <div className='mb-2 col-12'>
                        <label htmlFor='number' className='form-label'>
                          Number
                        </label>
                        <input
                          type='number'
                          className='form-control'
                          id='number'
                          name='number'
                          placeholder='ex. 10'
                          value={number}
                          onChange={handleInputChange}
                          autoComplete='off'
                        />
                        {errors.number && <div className='text-danger'>{errors.number}</div>}
                      </div>

                      <div className='mb-2 col-12'>
                        <label htmlFor='priority' className='form-label'>
                          Priority
                        </label>
                        <input
                          type='number'
                          className='form-control'
                          id='priority'
                          name='priority'
                          placeholder='ex. 1'
                          value={priority}
                          onChange={handlePriceInputChange}
                          autoComplete='off'
                        />
                        {errors.priority && <div className='text-danger'>{errors.priority}</div>}
                      </div>
                      </div>
                      <div className="col-lg-4 col-md-5 col-12 mb-3 text-center">
                        <img
                          src={profileImage}
                          alt='Profile'
                          className='img-thumbnail img-fluid mt-4'
                          style={{
                            borderRadius: '50%',
                            cursor: 'pointer',
                            objectFit: 'cover',
                            minHeight: '130px',
                            height: '130px',
                            maxWidth: '130px',
                            width: '130px',
                          }}
                        />
                        <p>Image Supported files : png, jpg. Max 2 mb allowed<br /> Recommended size: 581 x 707px.</p>
                        <div className='button-wrapper pt-2'>
                          <span className='label blr-bg-dull-sky rounded'><p className='mb-0 px-1'>Upload File</p></span>
                         <div className="d-flex justify-content-center">
                          <input
                              type='file'
                              accept="image/jpeg, image/png"
                              onChange={handleImageChange}
                              className=' upload-box form-control'
                              name='upload' 
                              id='upload'
                              placeholder='Upload File'
                            />
                         </div>
                        </div>
                        {imgError && <div className='text-danger mt-2'>{imgError}</div>}
                      </div>
                    </div>
                    <button type='submit' className='btn text-white ' >
                      Save
                    </button>
                    {errors.form && <div className='text-danger mt-2'>{errors.form}</div>}
                  </form>
                  {/* form end*/}
                </div>
                <div className='col-12 col-md-3 col-lg-4 ps-3  pe-sm-0 pe-0 d-flex flex-column  align-items-center justify-content-center'>
                  {/* image */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UpdateTshirt;