import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import _constantUtil from '../../utils/Constant';
import Loader from '../partials/Loader';
import config from '../../config/config';
import { MdCreate } from "react-icons/md";
import { MdOutlineDesktopMac } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import { MdOutlineCircle } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Tooltip } from 'react-tooltip';


function AllPlayer() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const storedTheme = sessionStorage.getItem('themeMode')

  const [data, setData] = useState([]);
  const [page, setPage] = useState(_constantUtil.ONE);
  const [playerID, setPlayerID] = useState();
  const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
  const [playerStatus, setPlayerStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  const [showTeam, setShowTeam] = useState(_constantUtil.BOOLEAN_FALSE);
  const [selectedCards, setSelectedCards] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [search, setSearch] = useState('');
  const [option, setOption] = useState('first_name');
  
  
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.ALL_PLAYER_END_POINT}?page=${page}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const { records, total_page } = response.data.data;
      setData(records);
      setTotalPages(total_page);
    } 
    catch (error) {
      toast.error(error);
    }
  };

  const AllTeam = async () => {
    try {
      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.PLAYER_ALL_TEAM_END_POINT}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data.data;
      setAllTeam(result);
    } 
    catch (error) {
      toast.error(error);
    }
  };

  const searchPlayer = async function (value, page) {
    if (value.length > 2) { 
      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.SEARCH_PLAYER_END_POINT}?page=${page}&key=${option}&value=${value}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setData(response.data.data.records);
      setPage(page);
      setTotalPages(response.data.data.total_page);
    } else {
      fetchData();
      AllTeam();
    }
  }
  
  useEffect(()=>{
    const delayDebounce = setTimeout(() => {
      if (search.length > 2) {
        searchPlayer(search, page);
      } 
      else{
        fetchData();
        AllTeam();
      }
    }, 500); 

    return () => clearTimeout(delayDebounce);
  },[search, page])


  const confirmationRef = useRef(_constantUtil.NULL);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };    
  }, []);

  const visiblePlayer = async () => {
    setLoading(true)
    setDisableButton(true)
    try {
      const response = await axios.patch(
        `${config.appBaseUrl + _constantUtil.VISIBLE_PLAYER_END_POINT}?player_id=${playerID}&status=${playerStatus}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data;

      if (result.status) {
        const updatedData = data.map(player => {
          if (player.player_id === playerID) {
            return { ...player, status: playerStatus };
          }
          return player;
        });
        setData(updatedData);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } 
    catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setDisableButton(false);
    } 
    setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };

  const showConfirmationModel = (params) => {
    const { id, status } = params;
    setPlayerID(id);
    setPlayerStatus(!status);
    setShowConfirmation(_constantUtil.BOOLEAN_TRUE);
  } 

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  
  const importTeamPlayer = async (event) => {
    event.preventDefault();
    setLoading(true);
    setDisableButton(true);
  
    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.IMPORT_PLAYER_END_POINT}`,
        { teams: selectedCards },
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      const result = response.data;
      if (result.status) {
        toast.success(result.message)
        fetchData();
      } else {
        toast.error(result.message);
      }
      setShowTeam(_constantUtil.BOOLEAN_FALSE);
      setSelectedCards([]);
      setSelectAllChecked(false);
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    } finally {
      setLoading(false);
      setDisableButton(false);
    }
  };

  function cancelImport (){
    setSelectedCards([]);
    setSelectAllChecked(false);
    setShowTeam(false)
  }

  const toggleCardSelection = (team_id) => {
    let newSelectedCards;
    if (selectedCards.includes(team_id)) {
      newSelectedCards = selectedCards.filter(id => id !== team_id);
    } else {
      
      newSelectedCards = [...selectedCards, team_id];
    }  
    
    setSelectedCards(newSelectedCards);
    
    if (newSelectedCards.length === allTeam.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  };

  const toggleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedCards([]);
    } else {
      const allTeamIds = allTeam.map(team => team.team_id);
      setSelectedCards(allTeamIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };
  
  return (
    <>
    <ToastContainer autoClose={2000} position='top-center' />
      <div className='px-4 mt-4'>
        
        {showConfirmation && (
          <div>
            <div className='confirmation-overlay'></div>
            <div ref={confirmationRef} className='confirmation-dialog'>
              <p>Are you sure to update player status ?</p>
              <div className='d-flex justify-content-end '>
                <button onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                <button onClick={() => visiblePlayer()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton}>{ !loading ? 'Confirm' : <Loader />}</button>
              </div>
            </div>
          </div>
        )}

        {showTeam && (
          <div>
            <div className='team-confirmation-overlay'></div>
            <div ref={confirmationRef} className={`${storedTheme === 'dark' ? 'player-confirmation-dialog' : 'team-confirmation-dialog'}`}>
              <div className='d-flex justify-content-between mb-3'>
                <h4>Import Player</h4>
                <div className="float-right">
                  <input
                    type="checkbox"
                    id="select-all"
                    checked={selectAllChecked}
                    onChange={toggleSelectAll}
                    style={{cursor:'pointer'}}
                  />
                  <label htmlFor="select-all" className="ms-2">Select All</label>
                </div>
              </div>
              <div className="row scroll">
                {allTeam.map(team => (
                  <div key={team.team_id} className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="checkboxes__item mb-3">
                      <label className="checkbox style-h">
                        <input type="checkbox"
                          id={`team-${team.team_id}`}
                          onChange={() => toggleCardSelection(team.team_id)} 
                          checked={selectedCards.includes(team.team_id)}
                          />
                        <div className="checkbox__checkmark"></div>
                        <div className="checkbox__body fs-5"><h5 className='mb-0'>{team.name}</h5></div>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end mt-3'>
                <button onClick={cancelImport} className='border-0 rounded px-3 bg-danger text-white me-2 p-2'>Cancel</button>
                <button className='border-0 rounded px-3 bg-success text-white p-2' onClick={importTeamPlayer} disabled={disableButton}> {!loading ? 'Confirm' : <Loader />}</button>
              </div>
            </div>
          </div>        
        )}

        <div className='container-fluid p-0'>
          <div className='row'>
            <h4 className='header-title pb-3 text-muted'>All Player</h4>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className='d-flex'>
                      <select
                        className='form-select me-2'
                        style={{ width: '65%' }}
                        value={option}
                        onChange={(e) => setOption(e.target.value)}
                      >
                        <option value='first_name'>First Name</option>
                        <option value='last_name'>Last Name</option>
                        <option value='match_name'>Match Name</option>
                      </select>
                      <input
                        type='text'
                        className='form-control me-2'
                        id='search'
                        name='search'
                        placeholder='Search Player'
                        value={search}
                        onChange={(e) => {
                          setPage(1);
                          setSearch(e.target.value);
                          searchPlayer(e.target.value, 1);
                        }}
                        autoComplete='off'
                      />
                    </div>
                    <div className=' d-flex '>
                      <div className=''>
                        <button
                          type='submit'
                          className='d-flex btn text-white'
                          onClick={() => setShowTeam(true)}
                        >
                          <MdOutlineFileDownload className='fs-4 me-1' />
                          Import
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table
                      id='scroll-horizontal-datatable'
                      className='table w-100 nowrap'
                    >
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Profile</th>
                          <th>Full Name</th>
                          <th>Match Name</th>
                          <th>Gender</th>
                          <th>Nationality</th>
                          <th>Position</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((player, index) => (
                          <tr key={player.player_id}>
                            <th scope='row'>{((page - _constantUtil.ONE) * 20) + (index + _constantUtil.ONE)} </th>
                            <td> <img src={player.profile} alt="image" className='user-img'/> </td>
                            <td >
                              {(player.first_name + ' ' + player.last_name).slice(0, 20) + ((player.first_name + ' ' + player.last_name).length > 20 ? '...' : '' )}
                            </td>            
                            <td><span className='badge badge-info-lighten'>{player.match_name}</span></td>
                            <td>{player.gender}</td>
                            <td>{player.nationality}</td>
                            <td>{player.position}</td>
                            <td>
                              {player.status ? (
                                <span className='badge badge-success-lighten fs-6'>
                                   ACTIVE
                                </span>
                                ) : (
                                <span className='badge badge-primary-lighten fs-6'>
                                  INACTIVE
                                </span>
                              )}
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-center'>
                                <MdCreate
                                  data-tooltip-id='updateplayer' data-tooltip-content="Update Player"
                                  className=' blr-icon  me-2 fs-5' style={{cursor: 'pointer', color:'gray'}}
                                  onClick={() => navigate(`/player/edit-player`,{state:{player_ID: player.player_id}})}
                                />
                                <Tooltip id='updateplayer' place="bottom" effect="solid"/>
                                <MdOutlineDesktopMac
                                  data-tooltip-id='viewplayer' data-tooltip-content="View Player"
                                  className=' blr-icon  me-2 fs-5'
                                  style={{ cursor: 'pointer', color:'gray' }}
                                  onClick={() => navigate(`/player/view-player`,{state:{player_ID: player.player_id}})} 
                                />
                                <Tooltip id='viewplayer' place="bottom" effect="solid"/>
                                {player.status ? 
                                <>
                                  <MdBlock
                                    data-tooltip-id='inactive' data-tooltip-content="Inactive Player"
                                    className=' blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'red'}}   
                                    onClick={() => showConfirmationModel({ id: player.player_id, status: player.status })}
                                    />
                                  <Tooltip id='inactive' place="bottom" effect="solid"/>
                                </>
                                : 
                                <>
                                  <MdOutlineCircle
                                    data-tooltip-id='active' data-tooltip-content="Active Player"
                                    className=' blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'green'}}   
                                    onClick={() => showConfirmationModel({ id: player.player_id, status: player.status })}
                                    />
                                  <Tooltip id='active' place="bottom" effect="solid"/>
                                </>
                                }
                                </div>  
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ReactPaginate
                    className='pagination'
                    onPageChange={page => { setPage(page.selected + _constantUtil.ONE) }}
                    pageCount={totalPages}
                    previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                    nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                    breakLabel={'...'}
                    containerClassName={'pagination-container'}
                    activeClassName={'pagination-active-page'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllPlayer;
