const ALL_USER_END_POINT = '/user/all-user'

const VIEW_USER_END_POINT = '/user/view-user'

const ALL_PLAYER_END_POINT = '/player/all-player'

const VISIBLE_USER_END_POINT = '/user/visible-user'

const VISIBLE_TEAM_END_POINT = '/team/visible-team'

const SEND_OTP_END_POINT = '/admin/send-otp'

const VERIFY_OTP_END_POINT = '/admin/verify-otp'

const PLAYER_ALL_TEAM_END_POINT = '/player/all-team'

const VISIBLE_PLAYER_END_POINT = '/player/visible-player'

const IMPORT_PLAYER_END_POINT = '/player/import-player'

const UPDATE_VIEW_PLAYER_END_POINT = '/player/view-player'

const UPDATE_PLAYER_END_POINT = '/player/update-player'

const VIEW_PLAYER_END_POINT = '/player/view-player'

const TEAM_ALL_COUNTRY_END_POINT = '/team/all-country'

const ALL_TEAM_END_POINT = '/team/all-team'

const IMPORT_TEAM_END_POINT = '/team/import-team'

const UPDATE_VIEW_TEAM_END_POINT = '/team/view-team'

const UPDATE_TEAM_END_POINT = '/team/update-team'

const VIEW_TEAM_END_POINT = '/team/view-team'

const ALL_TSHIRT_END_POINT = '/tshirt/all-tshirt'

const VISIBLE_TSHIRT_END_POINT = '/tshirt/visible-tshirt'

const SAVE_TSHIRT_END_POINT = '/tshirt/save-tshirt'

const UPDATE_TSHIRT_END_POINT = '/tshirt/save-tshirt'

const SEARCH_TEAM_END_POINT = '/team/search-team'

const SEARCH_PLAYER_END_POINT = '/player/search-player'

const ALL_LEAGUE_END_POINT = '/competition/all-competition'

const UPDATE_LEAGUE_END_POINT = '/competition/update-competition'

const VISIBLE_LEAGUE_END_POINT = '/competition/visible-competition'

const IMPORT_LEAGUE_END_POINT = '/competition/import-competition'

const UPCOMING_MATCH_END_POINT = '/match/all-match'

const IMPORT_MATCH_END_POINT = '/match/import-match'

const VIEW_MATCH_END_POINT = '/match/view-match'

const LAUNCH_MATCH_END_POINT = '/match/launch-match'

const UPDATE_MATCH_END_POINT = '/match/save-match'

const UPDATE_NEWS_END_POINT = '/match/save-news'

const ALL_NEWS_END_POINT = '/match/all-news'

const VISIBLE_NEWS_END_POINT = '/match/visible-news'

const UPDATE_NEWS_DETAILS_END_POINT = '/match/update-news'

const ALL_CATEGORY_END_POINT = '/bet/all-category'

const VISIBLE_CATEGORY_END_POINT = '/bet/visible-category'

const ADD_CATEGORY_END_POINT = '/bet/save-category'

const UPDATE_CATEGORY_END_POINT = '/bet/save-category'

const ALL_QUETION_END_POINT = '/bet/all-question'

const QUETION_DETAIL_END_POINT = '/bet/question-detail'

const VISIBLE_QUESTION_END_POINT = '/bet/visible-question'

const UPDATE_QUESTION_END_POINT = '/bet/save-question'

const AVAILABLE_MATCH_END_POINT = '/match/available-match'

const VISIBLE_MATCH_END_POINT = '/match/visible-match'

const VISIBLE_BET_END_POINT = '/bet/visible-bet'

const IMPORT_BET_END_POINT = '/bet/import-bet'

const ALL_BET_END_POINT = '/bet/all-bet'

const UPDATE_BET_END_POINT = '/bet/update-bet'

const PUBLISH_BET_END_POINT = '/bet/publish-bet'

const ASSIGN_BET_END_POINT = '/match/assign-bet'

const PLACEMENT_BET_END_POINT = '/bet/all-placement'

const API_PREFIX = '/web-api'

const ENVIORNMENT = 'staging'

const ZERO = 0

const ONE = 1

const GET_REQUEST = 'get'

const POST_REQUEST = 'post'

const PATCH_REQUEST = 'patch'

const BOOLEAN_TRUE = true

const BOOLEAN_FALSE = false

const NULL = null

const SOCKET_DEVELOPMENT_URL = 'https://betbalr-socket.onrender.com'

const SOCKET_STAGING_URL = 'https://staging-socket.betbalr.com'

const COUNTRY_LIST = [
    {
        _id: '6xne7xe59eujck02uovziom7p',
        country: 'Afghanistan'
    },
    {
        _id: '7ye7ksf2tpqaauihjdiuqi6s5',
        country: 'Africa'
    },
    {
        _id: '17zsm8shejejls2on1ffil3op',
        country: 'Albania'
    },
    {
        _id: '1ayprcriqhb8iv10ch70clppu',
        country: 'Algeria'
    },
    {
        _id: '5xdsl5tinkocdk72ny1pgsj6x',
        country: 'American Samoa'
    },
    {
        _id: '6kxfttvbfkg4ywupx6g9zc16o',
        country: 'Andorra'
    },
    {
        _id: '3k53bkrjx5zsxchc6e3mcd834',
        country: 'Angola'
    },
    {
        _id: '5ptslcq1vbobftatllej94tkr',
        country: 'Antigua and Barbuda'
    },
    {
        _id: '2vovxa97k7v7ofa85dah2xktb',
        country: 'Argentina'
    },
    {
        _id: '687vx665s4d88stgnq72a84k6',
        country: 'Armenia'
    },
    {
        _id: '612w8wkfhgud56fa4a01fxw04',
        country: 'Aruba'
    },
    {
        _id: '7yck0z0f9rlpeyatanjc1ylzp',
        country: 'Asia'
    },
    {
        _id: 'u5ieemi69z72c3bgczyy68lo',
        country: 'Australia'
    },
    {
        _id: 'hcs1bd49qliqqity6o268jyt',
        country: 'Austria'
    },
    {
        _id: '52sufqqq7vid3xa6iljx6obcz',
        country: 'Azerbaijan'
    },
    {
        _id: '4j3eg4ch3tdp2uh7kblm8yvvj',
        country: 'Bahrain'
    },
    {
        _id: '7fehvaq75zecgbbo70z41b24o',
        country: 'Bangladesh'
    },
    {
        _id: '6s2kneg94h46sha1zdawibpj0',
        country: 'Barbados'
    },
    {
        _id: '2vn7xzv9g752y1h5ufjzr27i8',
        country: 'Belarus'
    },
    {
        _id: '4wbzjcclyefma1ourvjsu4q2j',
        country: 'Belgium'
    },
    {
        _id: '1o85e1k63qyysa7c6asegj7hn',
        country: 'Belize'
    },
    {
        _id: '3o5wco7tkqgfmys9exmt5fo2h',
        country: 'Benin'
    },
    {
        _id: '4x2e37ksohrzsxdkg0mgfe5ag',
        country: 'Bermuda'
    },
    {
        _id: '6370yfb19hhyuev2ibgmw9gb4',
        country: 'Bhutan'
    },
    {
        _id: '3dgy24s32p56l29s66imputcy',
        country: 'Bolivia'
    },
    {
        _id: '4fue8u54361o79fbiu9qwxjer',
        country: 'Bosnia and Herzegovina'
    },
    {
        _id: '4wpvgb16ehpymp85e16rbnufn',
        country: 'Botswana'
    },
    {
        _id: '2vufyvpoxd9lfl9f6vpp7tz6y',
        country: 'Brazil'
    },
    {
        _id: '1fk5l4hkqk12i7zske6mcqju6',
        country: 'England'
    },
    {
        _id: '7gww28djs405rfga69smki84o',
        country: 'France'
    },
    {
        _id: '36min0qztu8eydwvpv8t1is0m',
        country: 'Germany'
    },
    {
        _id: '49ih1pwv3ahshdf8uzrimi54c',
        country: 'Spain'
    },
    {
        _id: '25f2cmb2r8mk5rj92tzer6kvv',
        country: 'italy'
    }
]

module.exports = {
    SOCKET_STAGING_URL,
    SOCKET_DEVELOPMENT_URL,
    PLACEMENT_BET_END_POINT,
    ASSIGN_BET_END_POINT,
    PUBLISH_BET_END_POINT,
    VISIBLE_BET_END_POINT,
    UPDATE_BET_END_POINT,
    VISIBLE_MATCH_END_POINT,
    IMPORT_BET_END_POINT,
    ALL_BET_END_POINT,
    AVAILABLE_MATCH_END_POINT,
    COUNTRY_LIST,
    UPDATE_QUESTION_END_POINT,
    VISIBLE_QUESTION_END_POINT,
    QUETION_DETAIL_END_POINT,
    ALL_QUETION_END_POINT,
    UPDATE_CATEGORY_END_POINT,
    ADD_CATEGORY_END_POINT,
    VISIBLE_CATEGORY_END_POINT,
    ALL_CATEGORY_END_POINT,
    UPDATE_NEWS_DETAILS_END_POINT,
    VISIBLE_NEWS_END_POINT,
    ALL_NEWS_END_POINT,
    UPDATE_NEWS_END_POINT,
    UPDATE_MATCH_END_POINT,
    LAUNCH_MATCH_END_POINT,
    VIEW_MATCH_END_POINT,
    IMPORT_MATCH_END_POINT,
    UPCOMING_MATCH_END_POINT,
    UPDATE_LEAGUE_END_POINT,
    IMPORT_LEAGUE_END_POINT,
    VISIBLE_LEAGUE_END_POINT,
    ALL_LEAGUE_END_POINT,
    UPDATE_TSHIRT_END_POINT,
    SEARCH_PLAYER_END_POINT,
    SEARCH_TEAM_END_POINT,
    API_PREFIX,
    ENVIORNMENT,
    VIEW_USER_END_POINT,
    ALL_USER_END_POINT,
    SAVE_TSHIRT_END_POINT,
    VISIBLE_TSHIRT_END_POINT,
    ZERO,
    ONE,
    GET_REQUEST,
    POST_REQUEST,
    VISIBLE_USER_END_POINT,
    SEND_OTP_END_POINT,
    VERIFY_OTP_END_POINT,
    ALL_TSHIRT_END_POINT,
    PATCH_REQUEST,
    BOOLEAN_TRUE,
    BOOLEAN_FALSE,
    VISIBLE_TEAM_END_POINT,
    NULL,ALL_PLAYER_END_POINT,
    PLAYER_ALL_TEAM_END_POINT,
    VISIBLE_PLAYER_END_POINT,
    IMPORT_PLAYER_END_POINT,
    UPDATE_VIEW_PLAYER_END_POINT,
    UPDATE_PLAYER_END_POINT,
    UPDATE_TEAM_END_POINT,
    VIEW_PLAYER_END_POINT,
    TEAM_ALL_COUNTRY_END_POINT,
    ALL_TEAM_END_POINT,
    IMPORT_TEAM_END_POINT,
    VIEW_TEAM_END_POINT,
    UPDATE_VIEW_TEAM_END_POINT
}