import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdCreate, MdBlock, MdAdd ,MdOutlineCircle} from "react-icons/md";
import ReactPaginate from 'react-paginate';
import img from '../../../assets/images/circle_placeholder.png'
import { Tooltip } from 'react-tooltip';
import _constantUtil from '../../../utils/Constant';
import config from '../../../config/config';
import Loader from '../../partials/Loader';
import { useNavigate } from 'react-router-dom';

function BetsCategory() {
  const storedTheme = sessionStorage.getItem('themeMode');
  const token = localStorage.getItem('token');
  const navigate = useNavigate()

  const [data, setData] = useState([]);
  const [confirmation, setConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  const [profileImage, setProfileImage] = useState(img);
  const [categoryID, setCategoryID] = useState();
  const [categoryStatus, setCategoryStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [page, setPage] = useState(_constantUtil.ONE);
  const [totalPages, setTotalPages] = useState(_constantUtil.ONE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);
  const [priority, setPriority] = useState('');
  const [name, setName] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [errors, setErrors] = useState({});

  const confirmationRef = useRef(_constantUtil.NULL);
  const allCategory = async () => {
      try {
        const response = await axios.get(
          `${config.appBaseUrl + _constantUtil.ALL_CATEGORY_END_POINT}?page=${page}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
  
        const  { records, total_page }  = response.data.data;
        setData(records);
        setTotalPages(total_page);
      } 
      catch (error) {
        toast.error(error);
      }
  };    

  useEffect(() => {
    allCategory()
      const handleClickOutside = (event) => {
          if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
              setConfirmation(false);
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };    
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[_constantUtil.ZERO];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePriorityInputChange = (event) => {
    let inputPrice = event.target.value;
    inputPrice = inputPrice.replace(/\D/g, '');
    inputPrice = inputPrice.slice(0, 2); 
    setPriority(inputPrice); 
  };

  const visibleCategory = async () => {
    setLoading(_constantUtil.BOOLEAN_TRUE);
    setDisableButton(_constantUtil.BOOLEAN_TRUE);
    try {
      const response = await axios.patch(
        `${config.appBaseUrl + _constantUtil.VISIBLE_CATEGORY_END_POINT}?category_id=${categoryID}&status=${categoryStatus}`,
        {},
        {
          headers: {
            Authorization: token,
          }
        }
      );

      const result = response.data;

      if (result.status) {
        const updatedData = data.map(category => {
          if (category._id === categoryID) {
            return { ...category, status: categoryStatus };
          }
          return category;
        });
        setData(updatedData);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error);
    }finally {
      setLoading(_constantUtil.BOOLEAN_FALSE);
      setDisableButton(_constantUtil.BOOLEAN_FALSE);
    }
    setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };

  const showConfirmationModel = (params) => {
    const { id, status } = params;
    setCategoryID(id);
    setCategoryStatus(!status);
    setShowConfirmation(_constantUtil.BOOLEAN_TRUE);
  }

  const validateForm = () => {
    const newErrors = {};
    if (!profileImage.trim()) newErrors.image = 'Image is required';
    if (!priority.toString().trim()) newErrors.priority = 'Priority is required';
    if (!name.trim()) newErrors.name = 'Category name is required';
    if (!subtitle.trim()) newErrors.subtitle = 'Sub title is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addCategory = async (event) => {
    event.preventDefault();
  
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
  
    const data = new FormData();
    const image = document.getElementById('upload-modal');
    if(image){
      data.append('profile',image.files[_constantUtil.ZERO]);
    } else{
      data.append('profile',profileImage);
    }
    data.append('name', name);
    data.append('subtitle', subtitle);
    data.append('priority', priority);
  
    try {
      const response = await axios.post(
        `${config.appBaseUrl + _constantUtil.ADD_CATEGORY_END_POINT}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const result = response.data;
      if (result.status) {
        toast.success(result.message);
        setName('');
        setSubtitle('');
        setPriority('');
        allCategory()
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Error occurred while category');
    }
    setConfirmation(_constantUtil.BOOLEAN_FALSE)
  };

  return (
    <>
    <ToastContainer autoClose={2000} position='top-center' pauseOnHover='false' />
     {showConfirmation && (
        <div>
          <div className='confirmation-overlay'></div>
          <div ref={confirmationRef} className='confirmation-dialog'>
            <p>Are you sure to update tshirt status?</p>
            <div className='d-flex justify-content-end '>
              <button onClick={() => setShowConfirmation(_constantUtil.BOOLEAN_FALSE)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
              <button onClick={() => visibleCategory()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton}>{!loading ? 'Confirm' : <Loader />}</button>
            </div>
          </div>
        </div>
        )}
      {confirmation && (
        <div>
          <div className='team-confirmation-overlay'></div>
          <div ref={confirmationRef} className={`${storedTheme === 'dark' ? 'match-dark-confirmation-dialog' :  'match-light-confirmation-dialog'}`}>
              <form onSubmit={addCategory}>
                <div className=" text-center">
                <img
                  src={profileImage}
                  alt='Profile'
                  className='img-thumbnail img-fluid mt-4'
                  style={{
                    borderRadius: '50%',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    minHeight: '170px',
                    height: '170px',
                    maxWidth: '170px',
                    width: '170px',
                    backgroundColor: 'transparent'
                  }}
                />
                 {errors.image && <div className="text-danger">{errors.image}</div>}
                <div className='button-wrapper pt-2 mt-2'>
                  <span className='label blr-bg-dull-sky rounded'><p className='mb-0 px-1'>Upload File</p></span>
                  <div className="d-flex justify-content-center">
                  <input
                      type='file'
                      accept='image/*'
                      onChange={handleImageChange} 
                      className=' upload-box form-control'
                      name='upload' 
                      id='upload-modal'
                      placeholder='Upload File'
                    />
                  </div>
                </div>
              </div>
              <div className='mb-2'>
                <label htmlFor='priority' className='form-label fw-semibold'>
                Priority 
                </label>
                <input
                  type='text'
                  className='form-control'
                  style={{backgroundColor: 'transparent'}}
                  id='priority'
                  name='priority'
                  value={priority}
                  onChange={handlePriorityInputChange}
                  autoComplete='off'
                /> 
                {errors.name && <div className="text-danger">{errors.priority}</div>}
              </div>
              <div className="row">
              <div className='col-12 col-md-6 mb-2'>
                <label htmlFor='name' className='form-label fw-semibold'>
                    Name
                </label>
                <input
                  type='text'
                  className='form-control w-100'
                  style={{ backgroundColor: 'transparent' }}
                  id='name'
                  name='name'
                  value={name}
                  onChange={(e)=>{setName(e.target.value)}}
                  autoComplete='off'
                />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </div>
              <div className='col-12 col-md-6 mb-2'>
                <label htmlFor='title' className='form-label fw-semibold'>
                  Subtitle
                </label>
                <input
                  type='text'
                  className='form-control w-100'
                  style={{ backgroundColor: 'transparent' }}
                  id='title'
                  name='title'
                  value={subtitle}
                  onChange={(e)=>{setSubtitle(e.target.value)}}
                  autoComplete='off'
                />
                {errors.subtitle && <div className="text-danger">{errors.subtitle}</div>}
              </div>
            </div>

            <div className='d-flex justify-content-end mt-3'>
              <button onClick={()=>setConfirmation(false)} className='border-0 rounded px-3 bg-danger text-white me-2 p-2'>Cancel</button>
              <button type='submit' className='border-0 rounded px-3 bg-success text-white p-2' >Save</button>
            </div>
              </form>
          </div>
        </div>   
      )}  

       <div className='px-4 mt-4'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <h4 className='header-title pb-3 text-muted'>All Category</h4>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>

                                    <div className='d-flex justify-content-end align-items-center mb-2'>
                                        <div className=''>
                                            <button
                                                type='submit'
                                                onClick={() => setConfirmation(_constantUtil.BOOLEAN_TRUE)}
                                                className='btn text-white d-flex'>
                                                <MdAdd className='fs-4 me-1 mb-1 ' />
                                                Add
                                            </button>
                                        </div>
                                    </div>

                                    <div className='table-responsive'>
                                        <table id='scroll-horizontal-datatable' className='table w-100 nowrap'>
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Profile</th>
                                                    <th>Category</th>
                                                    <th>Subtitle</th>
                                                    <th>Priority</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {data.map((category, index)=>(
                                                <tr key={category._id}>
                                                   <th scope='row'>{((page - _constantUtil.ONE) * 20) + (index + _constantUtil.ONE)} </th>
                                                  <td><img src={category.profile} alt="image"  className='user-img'/></td>
                                                  <td>
                                                    <span className='badge badge-info-lighten'>
                                                           {category.name}
                                                    </span></td>
                                                  <td>{category.subtitle}</td>
                                                  <td>{category.priority}</td>
                                                  <td>{category.status ? (
                                                        <span className='badge badge-success-lighten'>
                                                          ACTIVE
                                                        </span>
                                                        ) : (
                                                        <span className='badge badge-danger-lighten'>
                                                          INACTIVE
                                                        </span>
                                                      )}
                                                    </td>
                                                  <td>
                                                      <div className='d-flex align-items-center justify-content-center'>
                                                          <MdCreate data-tooltip-id='betscategory' data-tooltip-content="Update Category"  className=' blr-icon  me-2 fs-5' style={{cursor: 'pointer', color:'gray'}}
                                                             onClick={() => navigate(`/bets/update-category`, { 
                                                              state: {
                                                                category_id: category._id, 
                                                                name: category.name,
                                                                priority: category.priority,
                                                                profile: category.profile,
                                                                sub_title: category.subtitle
                                                              }
                                                            })}
                                                              />
                                                              <Tooltip border={false} id='betscategory' place="bottom" effect="solid" />
                                                      {category.status ? 
                                                        <>
                                                          <MdBlock data-tooltip-id='activebetscategory' data-tooltip-content="Inactive Category" className=' blr-icon me-2 pointer user-status fs-5' style={{ cursor: 'pointer', color: 'red' }}
                                                             onClick={() => showConfirmationModel({ id: category._id, status: category.status })}
                                                              />
                                                              <Tooltip border={false} id='activebetscategory' place="bottom" effect="solid" />
                                                        </>
                                                          :
                                                            <>
                                                              <MdOutlineCircle
                                                                data-tooltip-id='inactivebetscategory' data-tooltip-content="Active Category"
                                                                className='blr-icon pointer user-status fs-5 me-2' style={{cursor: 'pointer', color:'green'}}  
                                                                onClick={() => showConfirmationModel({ id: category._id, status: category.status })} 
                                                                />
                                                                <Tooltip id='inactivebetscategory' place="bottom" effect="solid"/>
                                                            </>
                                                      }
                                                      </div>
                                                  </td>
                                              </tr>
                                              ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        className='pagination'
                                        onPageChange={page => { setPage(page.selected + _constantUtil.ONE) }}
                                        pageCount={totalPages}
                                        previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                                        nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={1}
                                        breakLabel={'..'}
                                        containerClassName={'pagination-container'}
                                        activeClassName={'pagination-active-page'}
                                    /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}

export default BetsCategory;
