import React from 'react';

function Dashboard() {
  const users = [
    { count: 20 , id:  1, status: 'Completed'  , about: 'Total Users'},
    { count: 4545 , id:  2, status: 'Registered' , about: 'PAN KYC'},
    { count: 57 , id:  3, status: 'Completed' , about: 'Aadhaar KYC'},
    { count: 68 , id:  4, status: 'Registered' , about: 'Pending Refund'},
    { count: 76456 , id:  5, status: 'Completed' , about: 'Active Users'},
    { count: 2334, id:  6, status: 'Registered' , about: 'Pending PAN'},
    { count: 13 , id:  7, status: 'Completed' , about: 'Pending Bank'},
    { count: 321 , id:  8, status: 'Registered' , about: 'Completed KYC'},
    { count: 531, id:  9, status: 'Registered' , about: 'In Review PAN'},
    { count: 31456 , id: 10, status: 'Completed' , about: 'In Review Bank'},
    { count: 776 , id: 11, status: 'Registered' , about: 'PAN KYC'},
    { count: 76876 , id: 12, status: 'Registered' , about: 'Bank KYC'},
    { count: 876 , id: 13, status: 'Completed' , about: 'Global Contest'},
    { count: 7876 , id: 14, status: 'Registered' , about: 'Total Teams'},
    { count: 54323 , id: 15, status: 'Registered' , about: 'Total Players'},
    { count: 132132 , id: 16, status: 'Completed' , about: 'Total Deposits'},
    { count: 9876 , id: 17, status: 'Registered' , about: 'Total Revenue'},
    { count: 6754 , id: 18, status: 'Completed' , about: 'Active Money'},
    { count: 645 , id: 19, status: 'Completed' , about: 'In Review Matches'},
    { count: 6564 , id: 20, status: 'Registered' , about: 'Completed Matches'},
    { count: 34231 , id: 21, status: 'Registered' , about: 'Launch Matches'},
    { count: 3123 , id: 22, status: 'Completed' , about: 'Live Matches'},
    { count: 4567897 , id: 23, status: 'Registered' , about: 'Abandoned Matches'},
    { count: 97979 , id: 24, status: 'Completed' , about: 'Total Withdrawals'},
    { count: 76456 , id: 25, status: 'Registered' , about: 'Total Coins'},
    { count: 546456 , id: 26, status: 'Completed' , about: 'Total Referral Cash'},
    { count: 6876, id: 27, status: 'Registered' , about: 'Total Daily Cash'},
    { count: 456 , id: 28, status: 'Completed' , about: 'Total Instant Cash'},
    { count: 767 , id: 29, status: 'Registered' , about: 'Total Available Coins'},
    { count: 6776 , id: 30, status: 'Completed' , about: 'Total TDS'},
    { count: 456 , id: 28, status: 'Completed' , about: 'Total Instant Cash'},
    { count: 767 , id: 29, status: 'Registered' , about: 'Total Available Coins'},
    { count: 6776 , id: 30, status: 'Completed' , about: 'Total TDS'},
    { count: 456 , id: 28, status: 'Completed' , about: 'Total Instant Cash'},
    { count: 767 , id: 29, status: 'Registered' , about: 'Total Available Coins'},
    { count: 6776 , id: 30, status: 'Completed' , about: 'Total TDS'},
    { count: 456 , id: 28, status: 'Completed' , about: 'Total Instant Cash'},
    { count: 767 , id: 29, status: 'Registered' , about: 'Total Available Coins'},
    { count: 6776 , id: 30, status: 'Completed' , about: 'Total TDS'},
  ];
  
  const badgeColors = [
    'badge-success-lighten', 
    'badge-danger-lighten', 
    'badge-warning-lighten',
    'badge-primary-lighten',
    'badge-info-lighten',
    'badge-secondary-lighten'
  ];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * badgeColors.length);
    return badgeColors[randomIndex];
  };
  
  return (
    <main className='px-4 mt-4'>
      <h4 className='header-title pb-3 text-muted'>Dashboard</h4>
      <div className='row '>
        {users.map((user, index) => (
          <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2' key={index}>
            <div className='card shadow'>
              <div className='card-body d-flex flex-column align-items-center text-center'>
                <p className='mb-2 fw-semibold'>{user.about}</p>
                <p className='mb-2 fw-semibold'>{user.status}</p>
                <p>
                  <span className={`badge mt-2 mb-0 ${getRandomColor()}`}>{user.count}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}
export default Dashboard;