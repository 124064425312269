import React from 'react';
import { dotWave, spiral } from 'ldrs';

function Loader() {
  dotWave.register();
  return (
    <div className="loader-container">
      <l-dot-wave
        size="30"
        speed="1"
        color="white"
      ></l-dot-wave>
    </div>
  );
}

export default Loader;

export function ButtonLoader() {
  spiral.register();
  return (
    <div className="loader-container">
      <l-spiral
        size="40"
        speed="0.9" 
        color="black" 
      ></l-spiral>
    </div>
  );
}
