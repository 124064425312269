import React from 'react';
import { Route, Routes } from "react-router-dom";
import AllTshirt from '../tshirt/AllTshirt';
import UpdateTshirt from '../tshirt/UpdateTshirt';
import AddTshirt from '../tshirt/AddTshirt';

function TshirtNestRoutes() {
  return (
    <Routes>
      <Route path="/all-tshirt" element={<AllTshirt />} />
      <Route path="/edit-tshirt" element={<UpdateTshirt />} />
      <Route path="/add-tshirt" element={<AddTshirt />} />
    </Routes>
  );
}

export default TshirtNestRoutes;
