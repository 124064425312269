import React, { useEffect, useRef, useState } from "react";
import _constantUtil from "../../../utils/Constant";
import Loader from "../../partials/Loader";
import {
    MdOutlineFileDownload,
    MdKeyboardArrowLeft,
    MdKeyboardDoubleArrowRight,
    MdCreate,
    MdOutlineDesktopMac,
    MdAdd,
    MdBlock,
    MdOutlineCircle,
} from "react-icons/md";
import { RiRefund2Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import config from "../../../config/config";
import axios from "axios";
import { io } from "socket.io-client";
import { Logger } from "../../../utils/Logger";

function Bets() {
    const location = useLocation();
    const navigate = useNavigate()
    const matchDataState = location.state;
    const matchID = matchDataState?.match_ID;

    const token = localStorage.getItem("token");
    const storedTheme = sessionStorage.getItem("themeMode");

    const [data, setData] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(
        _constantUtil.BOOLEAN_FALSE
    );
    const [showImportBets, setShowImportBets] = useState(
        _constantUtil.BOOLEAN_FALSE
    );
    const [selectedCards, setSelectedCards] = useState([]);
    const [allbetQuestions, setAllbetQuestions] = useState([]);
    const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
    const [disableButton, setDisableButton] = useState(
        _constantUtil.BOOLEAN_FALSE
    );
    const [selectAllChecked, setSelectAllChecked] = useState(_constantUtil.BOOLEAN_FALSE);

    const [factID, setFactID] = useState();
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(
        _constantUtil.BOOLEAN_FALSE
    );
    const [results, setResults] = useState({ result_str: "", result_type: 0 });
    const [selectedResult, setSelectedResult] = useState("");
    const [optionOne, setOptionOne] = useState("");
    const [optionTwo, setOptionTwo] = useState("");
    const [optionOneID, setOptionOneID] = useState("");
    const [optionTwoID, setOptionTwoID] = useState("");
    const [que, setQue] = useState("");
    const [isStatus, setIsStatus] = useState(_constantUtil.BOOLEAN_FALSE);
    const [showBets, setShowBets] = useState(_constantUtil.BOOLEAN_FALSE);
    const [showPublish, setShowPublish] = useState(_constantUtil.BOOLEAN_FALSE);
    const [showStop , setShowStop] = useState(_constantUtil.BOOLEAN_FALSE);
    const [messageList, setMessageList] = useState([]);
    const socketRef = useRef();
    
    //    Socket

     useEffect(() => {
         socketRef.current = io(config.socketBaseUrl, {
            transports: ['websocket'],
            query: {
                match_id: matchID,
                token,
                identification_id: 'web'
            }
         });

         socketRef.current.on('web-all-bet', (data) => {
            setMessageList(data.data)
         });
 
         socketRef.current.on('connect', () => {
            Logger.info('Socket.IO is connected.',{component : 'all-bet'})
         });

         socketRef.current.on('web-error-bet', (data) => {
            toast.error(data.message)
         });
 
         socketRef.current.on('disconnect', () => {
            Logger.info('Socket.IO is disconnected.',{component : 'all-bet'})
         });
 
     
         return () => {
            socketRef.current.disconnect();
            Logger.info('Socket.IO is disconnected on component unmount.',{component : 'all-bet'})
         };
     }, [config.socketBaseUrl]);

    const allQuestion = async () => {
        try {
            const response = await axios.get(
                `${config.appBaseUrl + _constantUtil.ALL_QUETION_END_POINT
                }?match_id=${matchID}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data.data;
            setAllbetQuestions(result);
            if (response) {
                setShowImportBets(true);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const confirmationRef = useRef(_constantUtil.NULL);
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (
                confirmationRef.current &&
                !confirmationRef.current.contains(event.target)
            ) {
                setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleSelectAll = () => {
        if (selectAllChecked) {
            setSelectedCards([]);
        } else {
            setSelectedCards(allbetQuestions);
        }
        setSelectAllChecked(!selectAllChecked);
    };

    function cancelImport() {
        setSelectedCards([]);
        setSelectAllChecked(_constantUtil.BOOLEAN_FALSE);
        setShowImportBets(_constantUtil.BOOLEAN_FALSE);
    }

    const toggleCardSelection = (question) => {
        const isSelected = selectedCards.some(
            (selected) => selected._id === question._id
        );

        const newSelectedCards = isSelected
            ? selectedCards.filter((selected) => selected._id !== question._id)
            : [...selectedCards, question];

        setSelectedCards(newSelectedCards);
        setSelectAllChecked(newSelectedCards.length === allbetQuestions.length);
    };

    const importBets = async (event) => {
        event.preventDefault();
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);

        const data = {
            match_id: matchID,
            questions: selectedCards.map((card) => {
                const { _id, ...rest } = card;
                return {
                    ...rest,
                };
            }),
        };

        try {
            const response = await axios.post(
                `${config.appBaseUrl + _constantUtil.IMPORT_BET_END_POINT}`,
                data,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;
            if (result.status) {
                toast.success(result.message);
                socketRef.current.emit('web-import-bet')
            } else {
                toast.error(result.message);
            }
            setShowImportBets(_constantUtil.BOOLEAN_FALSE);
            setSelectedCards([]);
            setSelectAllChecked(_constantUtil.BOOLEAN_FALSE);
        } catch (error) {
            toast.error(error.message || "An error occurred");
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
    };

    const showConfirmationModel = (params) => {
        const {  F_id, Two, One,  Question, One_ID, Two_ID, ResultSTR, ResultType, } = params;
        setFactID(F_id);
        setOptionOne(One);
        setOptionTwo(Two);
        setOptionOneID(One_ID);
        setOptionTwoID(Two_ID);
        setQue(Question);
        setResults({ result_str: ResultSTR, result_type: ResultType });
        setSelectedResult(ResultSTR);
        setShowUpdateConfirmation(_constantUtil.BOOLEAN_TRUE);
    };

    const handleResultChange = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const key = selectedOption.getAttribute("data-key");
        const value = selectedOption.value;

        setResults({ result_str: value, result_type: key });
        setSelectedResult(value);
    };

    const UpdateBets = async (event) => {
        event.preventDefault();

        let data = {
            _id: factID,
            question: que,
            result: results.result_type,
            result_str: results.result_str,
            options: [
                {
                    text: optionOne,
                    _id: optionOneID,
                },
                {
                    text: optionTwo,
                    _id: optionTwoID,
                },
            ],
        };

        try {
            const response = await axios.patch(
                `${config.appBaseUrl + _constantUtil.UPDATE_BET_END_POINT}`,
                data,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;
            if (result.status) {
                toast.success(result.message);
                socketRef.current.emit('web-import-bet')
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error);
        }
        setShowUpdateConfirmation(_constantUtil.BOOLEAN_FALSE);
    };

    const visibleBets = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);
        try {
            const response = await axios.patch(
                `${config.appBaseUrl + _constantUtil.VISIBLE_BET_END_POINT}?bet_id=${factID}&status=${isStatus}`,
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            const result = response.data;

            if (result.status) {
                const updatedData = data.map((visibleBets) => {
                    if (visibleBets.match_id === matchID) {
                        return { ...visibleBets, status: isStatus };
                    }
                    return visibleBets;
                });
                setData(updatedData);
                toast.success(result.message);
                socketRef.current.emit('web-import-bet')
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowBets(_constantUtil.BOOLEAN_FALSE);
    };

    const showBetsConfirmation = (params) => {
        const { id, status } = params;
        setFactID(id);
        setIsStatus(!status);
        setShowBets(_constantUtil.BOOLEAN_TRUE);
    };

    const publishBet = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);
        try {
            socketRef.current.emit('web-publish-bet',{bet_id: factID})
            toast.success('Bet published')
        } catch{
            toast.error('Bet not published');
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowPublish(_constantUtil.BOOLEAN_FALSE);
    };

    const betPublish = (params) => {
        const { id } = params;
        setFactID(id);
        setShowPublish(_constantUtil.BOOLEAN_TRUE);
    }; 
    
    const stopBet = async () => {
        setLoading(_constantUtil.BOOLEAN_TRUE);
        setDisableButton(_constantUtil.BOOLEAN_TRUE);
        try {
            socketRef.current.emit('web-stop-bet',{bet_id: factID})
            toast.success('Bet stop')
        } catch{
            toast.error('Bet not stop');
        } finally {
            setLoading(_constantUtil.BOOLEAN_FALSE);
            setDisableButton(_constantUtil.BOOLEAN_FALSE);
        }
        setShowStop(_constantUtil.BOOLEAN_FALSE);
    };

    const betStop = (params) => {
        const { id } = params;
        setFactID(id);
        setShowStop(_constantUtil.BOOLEAN_TRUE);
    };

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center" />

            {showBets && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to update Bet status ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() =>
                                    setShowBets(
                                        _constantUtil.BOOLEAN_FALSE
                                    )
                                }
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={visibleBets}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showConfirmation && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to Import Bets ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() =>
                                    setShowConfirmation(
                                        _constantUtil.BOOLEAN_FALSE
                                    )
                                }
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showPublish && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to Publish Bet ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() =>
                                    setShowPublish(
                                        _constantUtil.BOOLEAN_FALSE
                                    )
                                }
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={publishBet}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showImportBets && (
                <div>
                    <div className="team-confirmation-overlay"></div>
                    <div
                        ref={confirmationRef}
                        className={`${storedTheme === "dark"
                                ? "player-confirmation-dialog"
                                : "team-confirmation-dialog"
                            }`}
                    >
                        <div className="d-flex justify-content-between mb-3">
                            <h4>Import Bets</h4>
                            <div className="float-right">
                                <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                    style={{ cursor: "pointer" }}
                                />
                                <label htmlFor="select-all" className="ms-2">
                                    Select All
                                </label>
                            </div>
                        </div>
                        <div className="row scroll">
                            {allbetQuestions.map((question) => (
                                <div
                                    key={question._id}
                                    className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                >
                                    <div className="checkboxes__item mb-3">
                                        <label className="checkbox style-h">
                                            <input
                                                type="checkbox"
                                                id={`team-${question._id}`}
                                                onChange={() =>
                                                    toggleCardSelection(
                                                        question
                                                    )
                                                }
                                                checked={selectedCards.includes(
                                                    question
                                                )}
                                            />
                                            <div className="checkbox__checkmark"></div>
                                            <div className="checkbox__body fs-5 d-flex align-items-center justify-content-between import-bets-modal">
                                                <h5 className="mb-1">
                                                    {question.question}
                                                </h5>
                                                <span className="badge badge-danger-lighten fs-6 mt-1 me-3">
                                                    {question.label}
                                                </span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <button
                                onClick={cancelImport}
                                className="border-0 rounded px-3 bg-danger text-white me-2 p-2"
                            >
                                Cancel
                            </button>
                            <button
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                onClick={importBets}
                                disabled={disableButton}
                            >
                                {" "}
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showStop && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div ref={confirmationRef} className="confirmation-dialog">
                        <p>Are you sure to Stop Bet ?</p>
                        <div className="d-flex justify-content-end ">
                            <button
                                onClick={() =>
                                    setShowStop(
                                        _constantUtil.BOOLEAN_FALSE
                                    )
                                }
                                className="border-0 rounded px-3 bg-danger text-white p-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={stopBet}
                                className="border-0 rounded px-3 bg-success text-white p-2"
                                disabled={disableButton}
                            >
                                {!loading ? "Confirm" : <Loader />}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showUpdateConfirmation && (
                <div>
                    <div className="team-confirmation-overlay"></div>
                    <div
                        ref={confirmationRef}
                        className={`${storedTheme === "dark"
                                ? "match-dark-confirmation-dialog"
                                : "match-light-confirmation-dialog"
                            }`}
                    >
                        <form onSubmit={UpdateBets}>
                            <div className="row">
                                <div className="col-12 col-md-12 mb-2">
                                    <label
                                        htmlFor="question"
                                        className="form-label fw-semibold"
                                    >
                                        Question
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                        id="question"
                                        name="question"
                                        value={que}
                                        onChange={(e) => {
                                            setQue(e.target.value);
                                        }}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-12 col-md-12 mb-2">
                                    <label
                                        htmlFor="result"
                                        className="form-label"
                                    >
                                        Result Type
                                    </label>
                                    <select
                                        id="result"
                                        className="form-select me-2"
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                        onChange={handleResultChange}
                                        value={selectedResult}
                                    >
                                        <option data-key={1} value="instant">
                                            instant
                                        </option>
                                        <option
                                            data-key={2}
                                            value="after match"
                                        >
                                            after match
                                        </option>
                                        <option data-key={3} value="manual">
                                            manual
                                        </option>
                                    </select>
                                </div>

                                <div className="col-12 col-md-6 mb-2">
                                    <label
                                        htmlFor="optionone"
                                        className="form-label fw-semibold"
                                    >
                                        Option one
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control w-100"
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                        id="optionone"
                                        name="optionone"
                                        value={optionOne}
                                        onChange={(e) => {
                                            setOptionOne(e.target.value);
                                        }}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-12 col-md-6 mb-2">
                                    <label
                                        htmlFor="optiontwo"
                                        className="form-label fw-semibold"
                                    >
                                        Option two
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control w-100"
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                        id="optiontwo"
                                        name="optiontwo"
                                        value={optionTwo}
                                        onChange={(e) => {
                                            setOptionTwo(e.target.value);
                                        }}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-end mt-3">
                                <button
                                    onClick={() =>
                                        setShowUpdateConfirmation(false)
                                    }
                                    className="border-0 rounded px-3 bg-danger text-white me-2 p-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="border-0 rounded px-3 bg-success text-white p-2"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="px-4 ">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between ">
                            <h4 className="header-title pb-3 text-muted mt-4">
                                <Link
                                   onClick={() =>navigate(-2)}
                                    className="text-muted"
                                >
                                    All League
                                </Link>
                                <span className="blr-player-view">
                                    <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2 " />
                                </span>{" "}
                                <Link
                                    onClick={() => navigate(-1)}
                                    className="text-muted"
                                >
                                    Launched Match
                                </Link>
                                <span className="blr-player-view">
                                    <MdKeyboardDoubleArrowRight className="blr-text-pink mb-1 ms-2 " />
                                </span>{" "}
                                All Bets
                            </h4>
                            <div className="d-flex align-items-center  my-4">
                                <MdOutlineFileDownload
                                    data-tooltip-id="filedownload"
                                    data-tooltip-content="Import Bets"
                                    onClick={allQuestion}
                                    className="fs-4 me-2 b-1 blr-icon import-match-icon"
                                />
                                <Tooltip
                                    border={false}
                                    id="filedownload"
                                    place="bottom"
                                    effect="solid"
                                    style={{ zIndex: "1000000" }}
                                />
                                <button
                                    type="button"
                                    className="btn text-white d-flex"
                                    style={{ float: "right" }}
                                    onClick={() => navigate(-1)}
                                >
                                    <MdKeyboardArrowLeft className=" fs-4" />
                                    Back
                                </button>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className={`card bets-light-background`}>
                                <div className="card-body">
                                    {messageList.length > 0 ? (
                                        <>
                                            {messageList.map((fact, index) => (
                                                <div className="card mb-4"key={fact._id}>
                                                    <div className="card-body">
                                                        <div className="table-responsive"style={{overflowX:"auto", }}>
                                                            <table className="table w-100 mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>SN</th>
                                                                        <th>Category</th>
                                                                        <th className="text-nowrap">Question Type</th>
                                                                        <th>State</th>
                                                                        <th className="text-nowrap">Option Type</th>
                                                                        <th>Result</th>
                                                                        <th>Status</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td className="text-nowrap">{fact.name}</td>
                                                                        <td className="text-nowrap">{fact.event}</td>
                                                                        <td>
                                                                            {fact.state === 1 && <span className="badge badge-primary-lighten">{fact.state_str}</span> }
                                                                            {fact.state === 2 && <span className="badge badge-success-lighten">{fact.state_str}</span> }
                                                                            {fact.state === 3 && <span className="badge badge-info-lighten">{fact.state_str}</span> }
                                                                            {fact.state === 4 && <span className="badge badge-success-lighten">{fact.state_str}</span> }
                                                                            {fact.state === 5 && <span className="badge badge-secodary-lighten">{fact.state_str}</span> }
                                                                            {fact.state === 6 && <span className="badge badge-danger-lighten">{fact.state_str}</span> }
                                                                        </td>
                                                                        <td>{fact.label}</td>
                                                                        <td>
                                                                            <span className="badge badge-info-lighten fs-6">
                                                                                {fact.result_str}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {fact.status ? (
                                                                                <span className="badge badge-success-lighten fs-6">
                                                                                    ACTIVE
                                                                                </span>
                                                                            ) : (
                                                                                <span className="badge badge-danger-lighten fs-6">
                                                                                    INACTIVE
                                                                                </span>
                                                                            )}
                                                                        </td>

                                                                        <td>
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            {
                                                                                (!fact.is_publish) && (
                                                                                    <>
                                                                                        <MdCreate
                                                                                            data-tooltip-id="updatefact"
                                                                                            data-tooltip-content="Update Bet"
                                                                                            className=" blr-icon  me-2 fs-5"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                color: "gray",
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                showConfirmationModel(
                                                                                                    {
                                                                                                        F_id: fact._id,
                                                                                                        One: fact
                                                                                                            .options[0]
                                                                                                            .text,
                                                                                                        Two: fact
                                                                                                            .options[1]
                                                                                                            .text,
                                                                                                        Question:
                                                                                                            fact.question,
                                                                                                        One_ID: fact
                                                                                                            .options[0]
                                                                                                            ._id,
                                                                                                        Two_ID: fact
                                                                                                            .options[1]
                                                                                                            ._id,
                                                                                                        ResultType:
                                                                                                            fact.result,
                                                                                                        ResultSTR:
                                                                                                            fact.result_str,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <Tooltip
                                                                                            id="updatefact"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                (!fact.is_publish && fact.status) && (
                                                                                    <>
                                                                                        <button
                                                                                            type="button"
                                                                                            data-tooltip-id="publish"
                                                                                            data-tooltip-content="Publish Bet"
                                                                                            className="btn bg-primary text-white d-flex me-2 py-0 px-1 fs-6"
                                                                                            style={{ float: "right" }}
                                                                                            onClick={() => betPublish({ id: fact._id })}
                                                                                        >
                                                                                            PUBLISH
                                                                                        </button>
                                                                                        <Tooltip
                                                                                            id="publish"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                        />

                                                                                        <MdBlock
                                                                                            data-tooltip-id="inactive"
                                                                                            data-tooltip-content="Inactive Bet"
                                                                                            className=" blr-icon pointer user-status fs-5 me-2"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                color: "red",
                                                                                            }}
                                                                                            onClick={() => showBetsConfirmation({ id: fact._id, status: fact.status })}
                                                                                        />
                                                                                        <Tooltip
                                                                                            id="inactive"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                (!fact.is_publish && !fact.status) && (
                                                                                    <>
                                                                                        <MdOutlineCircle
                                                                                            data-tooltip-id="active"
                                                                                            data-tooltip-content="Active Bet"
                                                                                            className=" blr-icon pointer user-status fs-5 me-2"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                color: "green",
                                                                                            }}
                                                                                            onClick={() => showBetsConfirmation({ id: fact._id, status: fact.status })}
                                                                                        />
                                                                                        <Tooltip
                                                                                            id="active"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                        />
                                                                                        
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                (fact.is_publish && fact.status) && (
                                                                                    <>
                                                                                        <MdOutlineDesktopMac 
                                                                                        data-tooltip-id='viewbetsdetail' data-tooltip-content="View Bets"
                                                                                        className='blr-icon mdi mdi-desktop-mac me-2 fs-5 text-secondary'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() =>
                                                                                            navigate(`/match/view-bets`, {
                                                                                                state: {
                                                                                                    Betquestion: fact.question,
                                                                                                    Betlabel: fact.label,
                                                                                                    Betresult: fact.result_str,
                                                                                                    Betmatchid: fact.match_id,
                                                                                                    Betstate: fact.state_str,
                                                                                                    Bettype: fact.state,
                                                                                                    betID: fact._id
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        />
                                                                                        <Tooltip id='viewbetsdetail' place="bottom" effect="solid"/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                ([2, 3].includes(fact.state) && !fact.answer.id) && (
                                                                                    <>
                                                                                        <button
                                                                                            type="button"
                                                                                            data-tooltip-id='cancel' data-tooltip-content="Cancel Bet"
                                                                                            className="btn bg-danger text-white d-flex me-2 py-0 px-1 fs-6"
                                                                                            style={{ float: "right" }}
                                                                                            // onClick={() => betPublish({ id: fact._id })}
                                                                                        >
                                                                                            CANCEL
                                                                                        </button>
                                                                                        <Tooltip id='cancel' place="bottom" effect="solid"/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                (fact.state === 2 && !fact.answer.id) && (
                                                                                    <div className="">
                                                                                        <button
                                                                                            type="button"
                                                                                            data-tooltip-id='stop' data-tooltip-content="Stop Bet"
                                                                                            className="btn d-flex align-items-center text-white d-flex me-2 py-0 px-1 fs-6"
                                                                                            style={{ float: "right",background:'blue' }}
                                                                                            onClick={() => betStop({ id: fact._id })}
                                                                                        >
                                                                                            STOP
                                                                                        </button>
                                                                                        <Tooltip id='stop' place="bottom" effect="solid"/>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                (fact.state === 5 && !fact.answer.id) && (
                                                                                    <>
                                                                                    <RiRefund2Line
                                                                                            data-tooltip-id="refund"
                                                                                            data-tooltip-content="Refund"
                                                                                            className=" blr-icon pointer user-status fs-5 me-2" style={{
                                                                                                    cursor: "pointer",
                                                                                                    color: "gray",
                                                                                            }}/>
                                                                                            <Tooltip
                                                                                            id="refund"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                (fact.state === 3 && fact.answer.id) && (
                                                                                    <>
                                                                                        <button
                                                                                            type="button"
                                                                                            data-tooltip-id='complete' data-tooltip-content="Complete Bet"
                                                                                            className="btn bg-success text-white d-flex me-2 py-0 px-1 fs-6"
                                                                                            style={{ float: "right" }}
                                                                                            // onClick={() => betPublish({ id: fact._id })}
                                                                                        >
                                                                                            COMPLETE
                                                                                        </button>
                                                                                        <Tooltip
                                                                                            id="complete"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            </div>
                                                            <div className="mt-3">
                                                                <div className="mb-3">
                                                                    <strong> Question :{" "}</strong>
                                                                    <span>
                                                                        {fact.question}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="row d-flex align-items-center justify-content-center">
                                                                {/* option one */}
                                                                <div className="col-12 col-md-6 mb-2">
                                                                    <div className="card w-100 bets-responsive">
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center justify-content-around">
                                                                                <h5 className="text-center mb-3">
                                                                                    Option
                                                                                    ( {fact.options[0].text } )
                                                                                </h5>
                                                                            </div>

                                                                            <div className="d-flex align-items-center justify-content-around"
                                                                                style={{ border: "1px solid var(--bs-border-color)", borderBottom:'0px' }} >
                                                                                <h6 className="w-25 text-center m-0 p-2"  style={{ borderRight: "1px solid var(--bs-border-color)", }} >
                                                                                    Rate ( ROI )
                                                                                </h6>
                                                                                <h6 className="w-25 text-center m-0 p-2" style={{ borderRight: "1px solid var(--bs-border-color)", }} >
                                                                                    Count
                                                                                </h6>
                                                                                <h6  className="w-25 text-center m-0 p-2"  style={{ borderRight: "1px solid var(--bs-border-color)", }} >
                                                                                    Amount
                                                                                </h6>
                                                                                <h6 className="w-25 text-center m-0 p-2">
                                                                                    Percentage
                                                                                </h6>
                                                                            </div>

                                                                            <div className="d-flex align-items-center justify-content-around"
                                                                                style={{ border: "1px solid var(--bs-border-color)", }} >

                                                                                <div className="w-25 text-center m-0 p-2" style={{borderRight:"1px solid var(--bs-border-color)",}}>
                                                                                    <span className="badge badge-danger-lighten">{fact.options[0].rate}</span>
                                                                                </div>

                                                                                <div className="w-25 text-center m-0 p-2"
                                                                                    style={{borderRight:"1px solid var(--bs-border-color"}}
                                                                                    >
                                                                                    <span className="badge badge-primary-lighten"
                                                                                        style={{cursor:'pointer'}}
                                                                                        data-tooltip-id='viewbetsdetail' data-tooltip-content="View Bets"
                                                                                        onClick={()=>{
                                                                                            navigate('/match/view-bets', {
                                                                                                state: {
                                                                                                    Betquestion: fact.question,
                                                                                                    Betlabel: fact.label,
                                                                                                    Betresult: fact.result_str,
                                                                                                    Betmatchid: fact.match_id,
                                                                                                    Betstate: fact.state_str,
                                                                                                    betID: fact._id,
                                                                                                    bet_ID : fact.options[0]._id
                                                                                                }
                                                                                            })
                                                                                        }} >
                                                                                            {fact.options[0].count}
                                                                                        </span>
                                                                                        <Tooltip id='viewbetsdetail' place="bottom" effect="solid"/>
                                                                                </div>

                                                                                <div className="w-25 text-center m-0 p-2" style={{borderRight:"1px solid var(--bs-border-color)",}}>
                                                                                    <span className="badge badge-info-lighten">{fact.options[0].amount}</span>
                                                                                </div>
                                                                                <div className="w-25 text-center m-0 p-2">
                                                                                    <span className="badge badge-warning-lighten">{fact.options[0].percentage}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                {/* option two */}
                                                                <div className="col-12 col-md-6 mb-2">
                                                                    <div className="card w-100 bets-responsive">
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center justify-content-around">
                                                                                <h5 className="text-center mb-3">
                                                                                    Option
                                                                                    ( {fact.options[1].text} )
                                                                                </h5>
                                                                            </div>
                                                                            <div className="d-flex align-items-center justify-content-around"
                                                                                style={{border: "1px solid var(--bs-border-color)",borderBottom:'0px'}}>

                                                                                <h6 className="w-25 text-center m-0 p-2" style={{borderRight:"1px solid var(--bs-border-color)",}}>
                                                                                    Rate(ROI)
                                                                                </h6>
                                                                                <h6 className="w-25 text-center m-0 p-2" style={{ borderRight:"1px solid var(--bs-border-color)",}}>
                                                                                    Count
                                                                                </h6>
                                                                                <h6 className="w-25 text-center m-0 p-2"style={{borderRight:"1px solid var(--bs-border-color)", }}>
                                                                                    Amount
                                                                                </h6>
                                                                                <h6 className="w-25 text-center m-0 p-2">
                                                                                    Percentage
                                                                                </h6>
                                                                                
                                                                            </div>
                                                                            <div className="d-flex align-items-center justify-content-around"style={{border: "1px solid var(--bs-border-color)",}}>
                                                                                <div className="w-25 text-center m-0 p-2" style={{ borderRight:"1px solid var(--bs-border-color)",}}>
                                                                                    <span className="badge badge-danger-lighten">{fact.options[1].rate}</span>
                                                                                </div>
                                                                                
                                                                                <div className="w-25 text-center m-0 p-2"
                                                                                    style={{borderRight:"1px solid var(--bs-border-color)"}}
                                                                                >
                                                                                    <span 
                                                                                        data-tooltip-id='viewbetsdetail' data-tooltip-content="View Bets"
                                                                                        style={{cursor:'pointer' }}
                                                                                        className="badge badge-primary-lighten"
                                                                                        onClick={()=>{
                                                                                            navigate('/match/view-bets', {
                                                                                                state: {
                                                                                                    Betquestion: fact.question,
                                                                                                    Betlabel: fact.label,
                                                                                                    Betresult: fact.result_str,
                                                                                                    Betmatchid: fact.match_id,
                                                                                                    Betstate: fact.state_str,
                                                                                                    betID: fact._id,
                                                                                                    bet_ID : fact.options[1]._id
                                                                                                }
                                                                                            })
                                                                                        }}>
                                                                                            {fact.options[1].count}
                                                                                        </span>
                                                                                        <Tooltip id='viewbetsdetail' place="bottom" effect="solid"/>
                                                                                </div>

                                                                                <div className="w-25 text-center m-0 p-2"style={{borderRight:"1px solid var(--bs-border-color)",}}>
                                                                                    <span className="badge badge-info-lighten">{fact.options[1].amount}</span>
                                                                                </div>
                                                                                <div className="w-25 text-center m-0 p-2">
                                                                                    <span className="badge badge-warning-lighten">{fact.options[1].percentage}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    type="button"
                                                    className="btn text-white"
                                                    onClick={allQuestion}
                                                >
                                                    <MdAdd className="fs-4 me-1" />
                                                    Import
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Bets;