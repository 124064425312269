import React from 'react';
import { Route, Routes } from "react-router-dom";
import AllTeam from '../team/AllTeam';
import UpdateTeam from '../team/UpdateTeam';
import ViewTeam from '../team/ViewTeam';

function TeamNestRoutes() {
  return (
    <Routes>
      <Route path="/all-team" element={<AllTeam />} />
      <Route path="/edit-team" element={<UpdateTeam />} />
      <Route path="/view-team" element={<ViewTeam />} />
    </Routes>
  );
}

export default TeamNestRoutes;
