import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import img from '../../../assets/images/circle_placeholder.png'
import _constantUtil from '../../../utils/Constant';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config/config';
import { MdKeyboardDoubleArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import axios from 'axios';

function UpdateCategory() {
    const navigate = useNavigate()
    const location = useLocation();
    const categoryData = location.state;
    const token = localStorage.getItem('token');

    const categoryID = categoryData?.category_id ?? '';
    const [name, setName] = useState(categoryData?.name ?? '');
    const [priority, setPriority] = useState(categoryData?.priority ?? '');
    const [subtitle, setSubtitle] = useState(categoryData?.sub_title ?? '');
    const [profileImage, setProfileImage] = useState(categoryData?.profile ?? img);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(reader.result);
            };
            reader.onerror = (error) => {
                console.error("Error reading file:", error);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePriorityInputChange = (event) => {
        let inputPrice = event.target.value;
        inputPrice = inputPrice.replace(/\D/g, '');
        inputPrice = inputPrice.slice(0, 2); 
        setPriority(inputPrice); 
      };

    const updateCategory = async (event) => {
        event.preventDefault();
    
        const data = new FormData();
        const img = document.getElementById('upload');
        data.append('category_id', categoryID);
        data.append('profile', img.files[_constantUtil.ZERO]);
        data.append('name', name);
        data.append('subtitle', subtitle);
        data.append('priority', priority);
      
        try {
          const response = await axios.post(
            `${config.appBaseUrl + _constantUtil.UPDATE_CATEGORY_END_POINT}`,
            data,
            {
              headers: {
                Authorization: token,
              },
            }
          );
      
          const result = response.data;
          (result.status) 
            ? toast.success(result.message) 
            : toast.error(result.message);
        } 
        catch (error) {
          toast.error(error);
        }
      };

    return (
        <>
            <div>
                <ToastContainer autoClose={2000} position='top-center' />
                <div className='row justify-content-center mx-4'>
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <h4 className='header-title pb-3 text-muted mt-4'>
                            <Link onClick={() => navigate(-1)} className='text-muted'>
                                All Category
                            </Link>
                            <span className="blr-player-view">
                                <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2' />
                            </span>{' '}
                            Update Category
                        </h4>
                        <button
                            type='button'
                            className='btn text-white float-right d-flex'
                            style={{ float: 'right' }}
                            onClick={() => navigate(-1)}
                        >
                            <MdKeyboardArrowLeft className='fs-4' />
                            Back
                        </button>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row p-0'>
                                <form onSubmit={updateCategory}>
                                    <div className='col-12 mb-3 text-center'>
                                        <img
                                            src={profileImage}
                                            alt='Profile'
                                            className='img-thumbnail img-fluid'
                                            style={{
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                objectFit: 'cover',
                                                width: '100%',
                                                maxWidth: '170px',
                                                height: '170px',
                                            }}
                                        />
                                        <div className='button-wrapper pt-2 mt-2'>
                                            <span className='label blr-bg-dull-sky rounded'>
                                                <p className='mb-0 px-1'>Upload File</p>
                                            </span>
                                            <div className="d-flex justify-content-center">
                                                <input
                                                    type='file'
                                                    accept="image/jpeg, image/png"
                                                    onChange={handleImageChange}
                                                    className='upload-box form-control'
                                                    name='upload'
                                                    id='upload'
                                                    placeholder='Upload File'
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='mb-2'>
                                            <label htmlFor='priority' className='form-label'>
                                                Priority
                                            </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                id='priority'
                                                name='priority'
                                                value={priority}
                                                onChange={handlePriorityInputChange}
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-lg-6 '>
                                            <div className='mb-2'>
                                                <label htmlFor='name' className='form-label'>
                                                    Name
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='name'
                                                    name='name'
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    autoComplete='off'
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className='mb-2'>
                                                <label htmlFor='subtitle' className='form-label'>
                                                    Subtitle
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='subtitle'
                                                    name='subtitle'
                                                    value={subtitle}
                                                    onChange={(e) => setSubtitle(e.target.value)}
                                                    autoComplete='off'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' className='btn text-white float-end mt-2' >
                                        Save
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateCategory;
