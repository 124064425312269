import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import _constantUtil, { ONE } from '../../utils/Constant';
import config from '../../config/config';
import Loader from '../partials/Loader';
import { MdCreate,MdBlock,MdOutlineCircle,MdAdd,MdKeyboardArrowLeft,MdKeyboardArrowRight } from "react-icons/md"; 
import { Tooltip } from 'react-tooltip'


function AllTshirt() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const [data, setData] = useState([]);
  const [page, setPage] = useState(_constantUtil.ONE);
  const [totalPages, setTotalPages] = useState(_constantUtil.ZERO);
  const [tshirtID, setTshirtID] = useState();
  const [tshirtStatus, setTshirtStatus] = useState(_constantUtil.BOOLEAN_TRUE);
  const [showConfirmation, setShowConfirmation] = useState(_constantUtil.BOOLEAN_FALSE);
  
  const [loading, setLoading] = useState(_constantUtil.BOOLEAN_FALSE);
  const [disableButton, setDisableButton] = useState(_constantUtil.BOOLEAN_FALSE);

  const confirmationRef = useRef(_constantUtil.NULL);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.ALL_TSHIRT_END_POINT}?page=${page}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setData(response.data.data.records);
      setTotalPages(response.data.data.total_page);
    } catch (error) {
      toast.error(error);
    }
  };
  
  const visibleTshirt = async () => {
    setLoading(true);
    setDisableButton(true);
    try {
      const response = await axios.patch(
        `${config.appBaseUrl + _constantUtil.VISIBLE_TSHIRT_END_POINT}?tshirt_id=${tshirtID}&status=${tshirtStatus}`,
        {},
        {
          headers: {
            Authorization: token,
          }
        }
      );

      const result = response.data;

      if (result.status) {
        const updatedData = data.map(tshirt => {
          if (tshirt._id === tshirtID) {
            return { ...tshirt, status: tshirtStatus };
          }
          return tshirt;
        });
        setData(updatedData);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error);
    }finally {
      setLoading(false);
      setDisableButton(false);
    }
    setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
  };
  
  useEffect(() => {
    fetchData(); 

    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setShowConfirmation(_constantUtil.BOOLEAN_FALSE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => { 
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [page]);
  
  const showConfirmationModel = (params) => {
    const { id, status } = params;
    setTshirtID(id);
    setTshirtStatus(!status);
    setShowConfirmation(true);
  }
  
  return (
    <>
      <ToastContainer autoClose={2000} position='top-center' />
      <div className='px-4 mt-4'>
        {showConfirmation && (
          <div>
            <div className='confirmation-overlay'></div>
            <div ref={confirmationRef} className='confirmation-dialog'>
              <p>Are you sure to update tshirt status?</p>
              <div className='d-flex justify-content-end '>
                <button onClick={() => setShowConfirmation(false)} className='border-0 rounded px-3 bg-danger text-white p-2'>Cancel</button>
                <button onClick={() => visibleTshirt()} className='border-0 rounded px-3 bg-success text-white p-2' disabled={disableButton}>{!loading ? 'Confirm' : <Loader />}</button>
              </div>
            </div>
          </div>
        )}
        <div className='container-fluid p-0'>
          <div className='row'>
            <h4 className='header-title pb-3 text-muted'>All Tshirt</h4>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row d-flex justify-content-end  mb-2'>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        className='btn blr-bg-dull-sky text-white'
                        onClick={() => navigate(`/tshirt/add-tshirt`)}
                      >
                        <MdAdd className='fs-4 me-1 mb-1' />
                        Add
                      </button>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table
                      id='scroll-horizontal-datatable'
                      className='table w-100 nowrap'
                    >
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Profile </th>
                          <th>Priority</th>
                          <th>Number</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((tshirt, index) => (
                          <tr key={index}>
                            <th scope='row'>{((page - _constantUtil.ONE) * 20) + (index + ONE)}</th>
                            <td><img src={tshirt.profile} alt='img' style={{width:'30px'}} /></td>
                            <td>{tshirt.priority}</td>
                            <td><span className='badge badge-info-lighten'>{tshirt.number}</span></td>
                            <td>{tshirt.name}</td>
                            <td>
                              {tshirt.status ? (
                                <span className='badge badge-success-lighten'>ACTIVE</span>
                              ) : (
                                <span className='badge badge-danger-lighten'>INACTIVE</span>
                              )}
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-center'>
                              <MdCreate
                                 data-tooltip-id='updatetshirt' data-tooltip-content="Update Tshirt"
                                className=' blr-icon  me-2 fs-5 ' style={{cursor: 'pointer', color:'gray'}}
                                onClick={() => navigate(`/tshirt/edit-tshirt`, { 
                                  state: {
                                    tshirt_id: tshirt._id, 
                                    number: tshirt.number,
                                    name: tshirt.name,
                                    priority: tshirt.priority,
                                    profile: tshirt.profile
                                  }
                                })}
                              />
                              <Tooltip id='updatetshirt' place="bottom" effect="solid"/>
                              {tshirt.status ? 
                              <>
                                <MdBlock
                                  data-tooltip-id='inactive' data-tooltip-content="Inactive Tshirt"
                                  className=' blr-icon me-2 pointer user-status fs-5' style={{cursor: 'pointer', color:'red'}}   
                                  onClick={() => showConfirmationModel({ id: tshirt._id, status: tshirt.status })}
                                />
                                <Tooltip id='inactive' place="bottom" effect="solid"/>
                              </>
                              : 
                              <>
                                <MdOutlineCircle
                                  data-tooltip-id='active' data-tooltip-content="Active Tshirt"
                                  className=' blr-icon me-2 pointer user-status fs-5' style={{cursor: 'pointer', color:'green'}}   
                                  onClick={() => showConfirmationModel({ id: tshirt._id, status: tshirt.status })}
                                />
                                <Tooltip id='active' place="bottom" effect="solid"/>
                              </>
                              }
                              </div>
                            </td> 
                          </tr>
                        ))}
                      </tbody>
                    </table>                    
                  </div>
                  <ReactPaginate
                    className='pagination'
                    onPageChange={(selectedPage) => { setPage(selectedPage.selected + _constantUtil.ONE) }}
                    pageCount={totalPages}
                    previousLabel={<MdKeyboardArrowLeft className='fs-4'/>}
                    nextLabel={<MdKeyboardArrowRight className='fs-4'/>}
                    breakLabel={'...'}
                    containerClassName={'pagination-container'}
                    activeClassName={'pagination-active-page'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AllTshirt;
