import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import config from '../../config/config';
import _constantUtil from '../../utils/Constant'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";


function ViewTeam() {
  const navigate = useNavigate()
  const location = useLocation();
  const token = localStorage.getItem('token');

  const teamData = location.state;
  const teamID = teamData?.team_ID

  const [data, setData] = useState({
    profile: '',
    name: '',
    short_name: '',
    code: '',
    team_type: '',
    type: '',
    city: '',
    country: '',
    color: ''
  });

  const viewTeam = async () => {
    try {

      const response = await axios.get(
        `${config.appBaseUrl + _constantUtil.VIEW_TEAM_END_POINT}?team_id=${teamID}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const result = response.data;
      setData(result.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    viewTeam();
  }, []);

  return (
    <>
      <ToastContainer autoClose={2000} position='top-center' />
      <div>
        <div className='row justify-content-center mx-4'>
          <div className='d-flex align-items-center justify-content-between p-0'>
            <h4 className='header-title mb-0 text-muted mt-4'>
              <Link onClick={() => navigate(-1)} className='text-muted'>
               All Team 
              </Link>
              <span className="blr-player-view">
                <MdKeyboardDoubleArrowRight className='blr-text-pink mb-1 ms-2 '/>
              </span>{' '}
              View Team
            </h4>
            <button
              type='button'
              className='btn text-white mt-4 d-flex'
              onClick={() => navigate(-1)}
            >
            <MdKeyboardArrowLeft className=' fs-4' />
              Back
            </button>
          </div>
          <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-center mb-4 px-1 pt-4'>
            <div className='card w-100'>
              <div className='card-body d-flex flex-column align-items-center justify-content-between p-0'>
                <div className='img-view d-flex align-items-center justify-content-between py-2'>
                  <img
                    src={data?.profile}
                    alt='Profile'
                    className='img-thumbnail img-fluid'
                    style={{
                      borderRadius: '50%',
                      cursor: 'pointer',
                      objectFit: 'contain',
                      minHeight: '170px',
                      height: '170px',
                      maxWidth: '170px',
                      width: '170px',
                      // margin: '25%'
                    }}
                  />
                </div>
                <span className='w-100 blr-team-name'>
                  <h5 className='my-4 text-center'>{data?.name}</h5>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9 pt-4">
            <div className="row">
              <div className='col-12 col-md-6 mb-4 col-lg-6 px-1'>
                <div className='card'>
                  <div className='card-body'>
                    <form>

                      <div className='mb-2'>
                        <label htmlFor='country_birth' className='form-label'>
                          Short Name
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='short_birth'
                          name='short_birth'
                          placeholder={data?.short_name}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>

                      <div className='mb-2'>
                        <label htmlFor='gender' className='form-label'>
                          Code
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='code'
                          name='code'
                          placeholder={data?.code}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>

                      <div className='mb-2'>
                        <label htmlFor='dob' className='form-label'>
                          Team Type
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='teamtype'
                          name='teamtype'
                          placeholder={data?.team_type}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>
                      <div className='mb-2'>
                        <label htmlFor='dob' className='form-label'>
                          Type
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='type'
                          name='type'
                          placeholder={data?.type}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>

                    </form>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 mb-4 col-lg-6 px-1'>
                <div className='card'>
                  <div className='card-body'>
                    <form>
                      <div className='mb-2'>
                        <label htmlFor='height' className='form-label'>
                          City
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='city'
                          name='city'
                          placeholder={data?.city}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>

                      <div className='mb-2'>
                        <label htmlFor='nationality' className='form-label'>
                          Country
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='country'
                          name='country'
                          placeholder={data?.country}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>

                      <div className='mb-2'>
                        <label htmlFor='weight' className='form-label'>
                          Color
                        </label>
                        <input
                          type='color'
                          className='form-control'
                          id='color'
                          name='color'  
                          value={data?.color}
                          style={{background : "#7e7e7e38"}}
                          disabled
                        />
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewTeam;
